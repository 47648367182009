import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import { Box, Stack } from "@mui/material";

import useCustomRoute from "../../hooks/useCustomRoute";
import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";

import { isPositiveInt } from "../../lib/utils";
import {
  setSpacePictures,
  getSpacePictures,
  removeSpacePictures,
} from "../../lib/amplify";

import { getListing } from "../../store/selectors/listingSelector";
import { setGlobalError } from "../../store/slices/appSlice";
import {
  useCreateSpaceMutation,
  useUpdateSpaceMutation,
  usePublishSpaceMutation,
  useUnPublishSpaceMutation,
  useDeleteSpaceMutation,
  useGetSpaceMutation,
} from "../../store/api/spaces";
import {
  setActiveStep,
  setActiveListing,
  setNewListing,
  setStatus,
} from "../../store/slices/listingSlice";
import { popupState } from "../../store/states";

import Loading from "../../components/Loading";
import TabPanel from "../../components/TabPanel";
import Popup from "../../components/Popup";
import ListingType from "./ListingType";
import ListingLocation from "./ListingLocation";
import ListingDetails1 from "./ListingDetails1";
import ListingDetails2 from "./ListingDetails2";
import ListingImages from "./ListingImages";
import ListingRate from "./ListingRate";
import ListingVerify from "./ListingVerify";

import {
  CreateListingHeader,
  CreateListingFooter,
  BorderLinearProgress,
  CreateListingTitle,
  CreateListingSubtitle,
} from "../../styles/createListing";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

import logo from "../../images/logo.png";

const verifiedIdentity = true;

const CreateListing = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [user] = useGetAuthenticatedUser();
  const [createSpace, { isLoading: createLoading }] = useCreateSpaceMutation();
  const [updateSpace, { isLoading: updateLoading }] = useUpdateSpaceMutation();
  const [deleteSpace, { isLoading: deleteLoading }] = useDeleteSpaceMutation();
  const [publishSpace, { isLoading: pubLoading }] = usePublishSpaceMutation();
  const [unPublishSpace, { isLoading: upLoad }] = useUnPublishSpaceMutation();
  const [getSpace, { isLoading: getLoading }] = useGetSpaceMutation();

  const [showPopup, setShowPopup] = useState(false);
  const [popup, setPopup] = useState(popupState);
  const [stepsLength, setStepsLength] = useState(7);

  const savedListing = useSelector(getListing);
  const handleClick = useCustomRoute({ path: "/listings" });
  const id = searchParams.get("id");

  const methods = useForm({
    defaultValues: {
      width: savedListing.width,
      length: savedListing.length,
      description: savedListing.description,
      phone_number: user.phone_number,
    },
  });

  const getRelativeStepLength = () => {
    return (savedListing.activeStep / (stepsLength - 1)) * 100;
  };

  const validateStep = () => {
    if (savedListing.activeStep === 0) {
      // Listing Type
      if (!savedListing.listing_type) {
        return dispatch(setGlobalError("Please choose a space type"));
      }
    } else if (savedListing.activeStep === 1) {
      // Listing Location
      if (
        !savedListing.lng ||
        !savedListing.lat ||
        !savedListing.location ||
        !savedListing.address
      ) {
        return dispatch(setGlobalError("Please enter an address"));
      }
    } else if (savedListing.activeStep === 2) {
      // Listing Size and Features
      if (
        !isPositiveInt(savedListing.width) ||
        !isPositiveInt(savedListing.length)
      ) {
        return dispatch(setGlobalError("Please enter a positive width and length"));
      }
    } else if (savedListing.activeStep === 3) {
      // Listing Access and Description
      if (
        !savedListing.accessFrequency ||
        !savedListing.accessHours ||
        !savedListing.appointmentRequired
      ) {
        return dispatch(setGlobalError("Please fill out all fields"));
      }
      if (!savedListing.description) {
        return dispatch(setGlobalError("Please ensure you have a description"));
      }
    } else if (savedListing.activeStep === 4) {
      // Listing Images
      if (savedListing.images.length === 0) {
        return dispatch(setGlobalError("Please upload at least one image"));
      }
    } else if (savedListing.activeStep === 5) {
      // User Rate
      if (!isPositiveInt(savedListing.rate)) {
        return dispatch(setGlobalError("Please enter a valid rate"));
      }
    }
  }

  const handleNext = () => {
    // Validation
    const res = validateStep();
    if (res == null) {
      dispatch(setActiveStep(savedListing.activeStep + 1));
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(savedListing.activeStep - 1));
  };

  const handleCreateOrUpdateSpace = async () => {
    try {
      if (savedListing.status != null) {
        let { images, _id, activeStep, ...body } = savedListing;
        if (savedListing.status === "Published") activeStep = 0;

        await updateSpace({
          id: _id,
          body: Object.assign({}, body, { activeStep: activeStep }),
        }).unwrap();
        // Upload images that aren't in s3 yet (key == null)
        await setSpacePictures(
          _id,
          images.filter((image) => image.key == null)
        );
      } else {
        let id = await createSpace(savedListing).unwrap();
        await setSpacePictures(id, savedListing.images);
      }
    } catch (err) {
      return dispatch(setGlobalError());
    }

    handleClick(true);
  };

  const handlePublish = async () => {
    if (
      savedListing.status !== "Published" &&
      savedListing.status !== "Reported"
    ) {
      if (savedListing.images.length === 0) {
        return dispatch(
          setGlobalError(
            "Please ensure you have an image uploaded for this space"
          )
        );
      }
      await publishSpace(id).unwrap();
    }
  };

  const handleSubmit = async () => {
    // Validate last step
    if (!user.phone_number_verified) {
      return dispatch(
        setGlobalError(
          "Please verify your phone number in the account section before publishing"
        )
      );
    } else if (!verifiedIdentity) {
      return dispatch(setGlobalError("Please verify your identity"));
    }
    await handleCreateOrUpdateSpace();
    await handlePublish();
  };

  const handleExit = () => {
    const func = async () => {
      const res = validateStep();
      if (res) return;
      await handleCreateOrUpdateSpace();
      handleClick(true);
    };
    setPopup({
      confirmText: "Continue",
      closeText: "Save & Exit",
      confirmColour: Colors.primary,
      onClose: func,
      title: "Are you sure you want to leave?",
      msg:
        "Don't worry we'll save your listing for now and you " +
        "can come back to it when you're ready.",
    });
    setShowPopup(true);
  };

  const handleDelete = () => {
    const func = () => {
      removeSpacePictures(id);
      deleteSpace(id).unwrap();
      handleClick(true);
    };
    setPopup({
      confirmText: "Delete",
      confirmColour: "red",
      onConfirm: func,
      msg:
        "This action cannot be undone, once you delete " +
        "this listing you won't be able to come back to it.",
    });
    setShowPopup(true);
  };

  const handlePublishPopup = () => {
    const func = () => {
      handlePublish();
      dispatch(setStatus("Published"));
    };
    setPopup({
      confirmText: "Publish",
      confirmColour: "green",
      onConfirm: func,
      title: "Publish your listing and start making cash",
      msg:
        "Once you publish your space will been seen by all Spaced " +
        "Out renters. Don't worry you can always unpublish or edit this space.",
    });
    setShowPopup(true);
  };

  const handleUnpublishPopup = () => {
    const func = () => {
      unPublishSpace(id).unwrap();
      dispatch(setStatus("Unpublished"));
    };
    setPopup({
      confirmText: "Unpublish",
      confirmColour: Colors.primary,
      onConfirm: func,
      msg:
        "This will take down your space from our search page" +
        ", preventing your space from being rented.",
    });
    setShowPopup(true);
  };

  useEffect(() => {
    const fetchSpace = async () => {
      if (id) {
        const space = await getSpace(id).unwrap();
        const images = await getSpacePictures({
          listingId: id,
          firstOnly: false,
        });
        dispatch(
          setActiveListing(Object.assign({}, { images: images }, space))
        );
      } else {
        dispatch(setNewListing());
      }
    };
    fetchSpace();
  }, [getSpace, dispatch, id]);

  useEffect(() => {
    if (user.phone_number_verified && verifiedIdentity) {
      if (savedListing.activeStep === 6) dispatch(setActiveStep(5));
      setStepsLength(6);
    }
    methods.reset({
      width: savedListing.width,
      length: savedListing.length,
      description: savedListing.description,
      phone_number: user.phone_number,
    });
  }, [
    dispatch,
    user.phone_number,
    user.phone_number_verified,
    methods,
    savedListing.width,
    savedListing.length,
    savedListing.description,
    savedListing.activeStep
  ]);

  return (
    <Box height={1000}>
      {createLoading ||
      getLoading || 
      updateLoading ||
      pubLoading ||
      upLoad ||
      deleteLoading ||
      (id && savedListing.listing_type == null) ? (
        <Loading />
      ) : (
        <FormProvider {...methods}>
          <form style={{ width: "100%" }}>
            <Popup show={showPopup} setShow={setShowPopup} {...popup} />
            <CreateListingHeader direction="row">
              <Box
                component="img"
                alt="logo"
                src={logo}
                style={{ width: "225px" }}
              />
              <Stack direction="row" ml="auto" spacing={2}>
                {savedListing.status != null && (
                  <CustomButton
                    onClick={handleDelete}
                    c="white"
                    bg="red"
                    sx={{ mt: 0 }}
                  >
                    Delete
                  </CustomButton>
                )}
                {savedListing.status === "Unpublished" &&
                  user.phone_number_verified &&
                  verifiedIdentity && (
                    <CustomButton
                      onClick={handlePublishPopup}
                      c="white"
                      bg="green"
                      sx={{ mt: 0 }}
                    >
                      Publish
                    </CustomButton>
                  )}
                {savedListing.status === "Published" && (
                  <CustomButton
                    onClick={handleUnpublishPopup}
                    c="white"
                    bg={Colors.primary}
                    sx={{ mt: 0 }}
                  >
                    Unpublish
                  </CustomButton>
                )}
                {(savedListing.activeStep !== 0 ||
                  savedListing.status != null) && (
                  <CustomButton
                    onClick={handleExit}
                    c="black"
                    bg="white"
                    sx={{ mt: 0 }}
                  >
                    Save & Exit
                  </CustomButton>
                )}
              </Stack>
            </CreateListingHeader>
            <TabPanel value={savedListing.activeStep} index={0}>
              <CreateListingTitle>
                Let's start with what kind of space you own
              </CreateListingTitle>
              <ListingType />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={1}>
              <CreateListingTitle>
                Where exactly is your space located?
              </CreateListingTitle>
              <ListingLocation />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={2}>
              <CreateListingTitle>
                Enter some quick details about your space
              </CreateListingTitle>
              <ListingDetails1 />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={3}>
              <CreateListingTitle>
                Enter some quick details about your space
              </CreateListingTitle>
              <ListingDetails2 />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={4}>
              <CreateListingTitle sx={{ paddingBottom: "25px" }}>
                Let's choose some photos for your space
              </CreateListingTitle>
              {savedListing.images.length !== 0 && (
                <CreateListingSubtitle>
                  The images will appear in this order to a renter with the
                  first one being the display image.
                </CreateListingSubtitle>
              )}
              <ListingImages />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={5}>
              <CreateListingTitle sx={{ paddingBottom: "25px" }}>
                Let's choose how much money your space will make
              </CreateListingTitle>
              <ListingRate />
            </TabPanel>
            <TabPanel value={savedListing.activeStep} index={6}>
              <CreateListingTitle sx={{ paddingBottom: "25px" }}>
                Verify a couple things regarding your account before listing
              </CreateListingTitle>
              <ListingVerify
                phone_number_verified={user.phone_number_verified}
              />
            </TabPanel>
            <BorderLinearProgress
              variant="determinate"
              value={getRelativeStepLength()}
            />
            <CreateListingFooter>
              <CustomButton
                c="black"
                bg="white"
                width="200px"
                disabled={savedListing.activeStep === 0}
                onClick={handleBack}
                sx={{ mt: 0 }}
              >
                Back
              </CustomButton>
              <Box sx={{ flex: "1 1 auto" }} />
              {savedListing.activeStep === stepsLength - 1 ? (
                <CustomButton
                  onClick={handleSubmit}
                  c="white"
                  bg={Colors.secondary}
                  sx={{ mt: 0 }}
                >
                  {savedListing.status === "Published"
                    ? "Save your listing"
                    : "Publish your listing"}
                </CustomButton>
              ) : (
                <CustomButton
                  onClick={handleNext}
                  c="white"
                  bg={Colors.secondary}
                  sx={{ mt: 0 }}
                >
                  Next
                </CustomButton>
              )}
            </CreateListingFooter>
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

export default CreateListing;
