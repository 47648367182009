import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const PopupTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "bold",
  fontSize: "30px",
}));

export const PopupText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "25px",
}));
