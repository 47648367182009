import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Slider } from "@mui/material";
import { Colors } from "../theme";

export const BlueBackground = styled(Box)(() => ({
  backgroundColor: Colors.secondary,
}));

export const SearchAppBarButton = styled(Button)(({ clicked }) => ({
  backgroundColor: clicked === "true" ? Colors.primary : "",
  color: "white",
  borderRadius: "25px",
  fontFamily: "Raleway",
  width: "130px",
  fontSize: "20px",
  height: "50px",
  "&:hover": {
    backgroundColor: clicked === "true" ? Colors.primary : "",
  },
  textTransform: "none",
}));

export const FiltersButton = styled(Button)(() => ({
  backgroundColor: "white",
  color: Colors.black,
  borderRadius: "25px",
  fontFamily: "Raleway",
  width: "130px",
  fontSize: "20px",
  height: "50px",
  "&:hover": {
    backgroundColor: "white",
  },
  textTransform: "none",
}));

export const FiltersTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "25px",
  textAlign: "left",
}));

export const FiltersResultButton = styled(Button)(({ bc, c, f }) => ({
  backgroundColor: bc,
  color: c,
  borderRadius: "25px",
  float: f,
  fontFamily: "Raleway",
  border: "2px solid black",
  width: "150px",
  fontSize: "18px",
  padding: "5px 5px 5px 5px",
  "&:hover": {
    backgroundColor: bc,
    filter: "drop-shadow(1px 1px 1px #222)",
  },
  textTransform: "none",
}));

export const CustomSlider = styled(Slider)({
  color: Colors.primary,
  height: 6,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 25,
    width: 25,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.15,
    fontSize: 18,
    background: "unset",
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: Colors.primary,
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const SizeTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 500,
  fontSize: "18px",
  textAlign: "center",
}));
