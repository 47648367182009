import { styled } from "@mui/material/styles";
import { Box, ImageListItemBar } from "@mui/material";

export const StorageContainer = styled(Box)(() => ({
  marginTop: "75px",
  alignItems: "center",
}));

export const StorageImageTitle = styled(ImageListItemBar)(() => ({
  fontFamily: "Raleway",
  "& .MuiImageListItemBar-title": {
    fontSize: "16px",
  },
}));
