import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Tabs, Box } from "@mui/material";

import TabPanel from "./components/TabPanel";

import { tabs } from "./data/index";
import { StyledTab } from "./styles/dashboard";

const TabsLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [,setTab] = useState(tabs.map((t) => t.path).indexOf(location.pathname));

  const handleTabChange = (event, index) => {
    const path = tabs[index].path;
    setTab(index);
    navigate(path);
  };
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        minHeight: 1000,
      }}
    >
      <Tabs
        value={tabs.map((t) => t.path).indexOf(location.pathname)}
        onChange={handleTabChange}
        orientation="vertical"
        centered
        sx={{ borderRight: 1, borderColor: "divider", p: 2 }}
      >
        {tabs.map((tab, i) => (
          <StyledTab
            icon={tab.icon}
            key={tab.path}
            iconPosition="start"
            label={tab.title}
          />
        ))}
      </Tabs>
      <TabPanel>
        <Outlet />
      </TabPanel>
    </Box>
  );
};

export default TabsLayout;
