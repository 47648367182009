import { configureStore } from "@reduxjs/toolkit";

import { backendApi } from './api/backendApi';
import { externalApi } from './api/externalApi';

import listingReducer from "./slices/listingSlice";
import searchReducer from "./slices/searchSlice";
import appReducer from "./slices/appSlice";


const store = configureStore({
  reducer: {
    listing: listingReducer,
    search: searchReducer,
    app: appReducer,
    [externalApi.reducerPath]: externalApi.reducer,
    [backendApi.reducerPath]: backendApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(externalApi.middleware).concat(backendApi.middleware),
});

export default store;
