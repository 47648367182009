import React from "react";
import { Menu, MenuItem, Typography } from "@mui/material";

const Dropdown = ({
  options,
  anchorEl,
  setAnchorEl,
  pointed,
  handleClick,
  width,
  positionContent,
}) => {
  const isAccountMenu = options[0].component;
  const open = Boolean(anchorEl);

  const handleMenuItemClick = (item) => {
    setAnchorEl(null);
    handleClick(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 3,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          borderRadius: pointed ? "0px" : "20px",
        },
      }}
    >
      {options.map((option, i) => (
        <MenuItem
          sx={{
            width: width,
            justifyContent: positionContent,
          }}
          key={i}
          onClick={() => handleMenuItemClick(option)}
        >
          {isAccountMenu && option.component}
          <Typography
            sx={{ fontFamily: "Raleway", fontSize: "20px", paddingLeft: "10px" }}
          >
            {option.title}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default Dropdown;
