import React from "react";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

const Loading = ({ height = "100%" }) => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={height}
      display="flex"
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
