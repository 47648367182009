import React from "react";

import { Stack, Divider } from "@mui/material";

import { BookCard } from "../../styles/book";
import {
  OverviewListingImage,
  OverviewPricingContent,
  OverviewPricingSubtitle,
  OverviewListingDetails,
  OverviewListingTitle,
} from "../../styles/book/overview";

const Overview = ({
  images,
  name,
  location,
  dimension,
  serviceFee,
  tax,
  total,
}) => {
  return (
    <BookCard>
      <Stack spacing={3}>
      <Stack direction="row" spacing={4} alignItems="center">
        <OverviewListingImage src={images[0]} alt="" />
        <Stack spacing={1}>
          <OverviewListingTitle>{name}</OverviewListingTitle>
          <OverviewListingDetails>
            {`${dimension[0]} x ${dimension[1]} | ${location}`}
          </OverviewListingDetails>
        </Stack>
      </Stack>
      <Divider width="100%" color="grey" />
      <Stack spacing={3} width="100%">
        <Stack direction="row">
            <OverviewPricingSubtitle>Service Fee</OverviewPricingSubtitle>
          <OverviewPricingContent>${serviceFee.toFixed(2)}</OverviewPricingContent>
        </Stack>
        <Stack direction="row">
            <OverviewPricingSubtitle>Taxes</OverviewPricingSubtitle>
          <OverviewPricingContent>${tax.toFixed(2)}</OverviewPricingContent>
        </Stack>
        <Divider width="100%" color="grey" />
        <Stack direction="row" width="100%">
          <OverviewPricingSubtitle>Weekly Total</OverviewPricingSubtitle>
          <OverviewPricingContent>${total.toFixed(2)}</OverviewPricingContent>
        </Stack>
      </Stack>
      </Stack>
    </BookCard>
  );
};

export default Overview;
