import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Grid, Box } from "@mui/material";

import ListingOptionCard from "../../components/ListingOptionCard";
import FormTextInput from "../../components/FormTextInput";

import { getListing } from "../../store/selectors/listingSelector";
import { setListingDimension, setListingFeature } from "../../store/slices/listingSlice";

import { ListingDetailsSubtitle } from "../../styles/createListing/listingDetails";

import { features } from "../../data";

const ListingDetails1 = () => {
  const dispatch = useDispatch();
  const savedListing = useSelector(getListing);

  const handleOptionClick = (title) => {
    dispatch(setListingFeature(title));
  };

  const handleWidthChange = (e) => {
    dispatch(setListingDimension({ width: e.target.value }));
  }

  const handleLengthChange = (e) => {
    dispatch(setListingDimension({ length: e.target.value }));
  }

  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <ListingDetailsSubtitle sx={{ pt: 2 }}>Size</ListingDetailsSubtitle>
        <Stack
          direction="row"
          spacing={20}
          width="100%"
          justifyContent="center"
        >
          <FormTextInput
            placeholder="Enter a width"
            name="width"
            value={savedListing.width}
            onChange={handleWidthChange}
            endInput="ft"
          />
          <FormTextInput
            placeholder="Enter a length"
            name="length"
            value={savedListing.length}
            onChange={handleLengthChange}
            endInput="ft"
          />
        </Stack>
      </Stack>
      <Stack width="100%" spacing={3}>
        <ListingDetailsSubtitle sx={{ pt: 2 }}>Features</ListingDetailsSubtitle>
        <Box display="flex" justifyContent="center" width="100%">
          <Grid container rowSpacing={3} columnSpacing={1}>
            {features.map((feature) => (
              <Grid item xs={4} key={feature.title}>
                <ListingOptionCard
                  key={feature.title}
                  option={feature}
                  handleOptionClick={handleOptionClick}
                  clicked={savedListing.features
                    .includes(feature.title)}
                  width={300}
                  height={125}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ListingDetails1;
