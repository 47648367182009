import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/system";
import { Stack, Divider, Box, Grid, Avatar, IconButton } from "@mui/material";

import { MAPS_API_KEY } from "../../lib/constants";
import { getSpacePictures, getProfilePicture } from "../../lib/amplify";
import { useGetSpaceMutation } from "../../store/api/spaces";
import { useGetUserMutation } from "../../store/api/users";

import ImageCarousel from "./ImageCarousel";
import PriceCard from "./PriceCard";
import Secure from "./Secure";
import Access from "./Access";
import ListingOptionCard from "../../components/ListingOptionCard";
import Loading from "../../components/Loading";

import {
  SpaceName,
  SpaceDimension,
  SpaceSection,
  SpaceLocation,
  SpaceDescription,
} from "../../styles/space";
import { features } from "../../data";
import none from "../../images/traffic-signal.png";

const Space = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [listing, setListing] = useState(undefined);
  const [getSpace, { isLoading }] = useGetSpaceMutation();
  const [getUser] = useGetUserMutation();

  useEffect(() => {
    const fetchSpace = async () => {
      if (id) {
        const space = await getSpace(id).unwrap();
        if (space) {
          const user = await getUser({ id: space.auth_id }).unwrap();
          if (user) {
            const profilePicture = await getProfilePicture(user.identity_id);
            const spacePictures = await getSpacePictures({
              listingId: id,
              id: user.identity_id,
              firstOnly: false,
            });
            setListing({
              images: spacePictures,
              host_profile_picture: profilePicture,
              host_name: user.first_name,
              ...space,
            });
          }
        }
      }
    };
    fetchSpace();
  }, [getSpace, getUser, id]);
  return (
    <Container maxWidth="xl">
      {(isLoading || listing == null) && <Loading height="500px" />}
      {listing && !isLoading && (
        <Stack direction="row">
          <Stack spacing={3} width="65%" marginRight="10px">
            <ImageCarousel images={listing.images.map((image) => image.path)} />
            <Stack direction="row" alignItems="center">
              <Stack spacing={1}>
                <SpaceName>{listing.listing_type}</SpaceName>
                <SpaceDimension>
                  {listing.width} x {listing.length}
                  <SpaceLocation component="span">{"•"}</SpaceLocation>
                  <SpaceLocation component="span">
                    {listing.location}
                  </SpaceLocation>
                </SpaceDimension>
              </Stack>
              <Stack direction="row" marginLeft="auto" alignItems="center">
                <SpaceDescription sx={{ paddingRight: "15px" }}>Hosted by {listing.host_name} </SpaceDescription>
                <IconButton
                  onClick={() => navigate(`/profile?id=${listing.auth_id}`)}
                >
                  <Avatar
                    sx={{
                      width: 75,
                      height: 75,
                      bgcolor: "primary.main",
                      fontSize: "30px",
                    }}
                    src={listing.host_profile_picture}
                  >
                    {listing.host_name[0]}
                  </Avatar>
                </IconButton>
              </Stack>
            </Stack>
            <Divider width="100%" color="grey" />
            <Stack spacing={1}>
              <SpaceSection>Description</SpaceSection>
              <Box minHeight="60px" width="100%">
                <SpaceDescription>{listing.description}</SpaceDescription>
              </Box>
            </Stack>

            <Divider width="100%" color="grey" />
            <SpaceSection>Access</SpaceSection>
            <Access {...listing} />
            <Divider width="100%" color="grey" />
            <Stack spacing={0.1}>
              <SpaceSection>Features</SpaceSection>
              <Grid container rowSpacing={3} columnSpacing={1}>
                {features.map(
                  (option) =>
                    listing.features.includes(option.title) && (
                      <Grid item xs={3} key={option.title}>
                        <ListingOptionCard
                          disabled
                          option={option}
                          width={150}
                          height={135}
                        />
                      </Grid>
                    )
                )}
                {listing.features.length === 0 && (
                  <Grid item xs={3}>
                    <ListingOptionCard
                      disabled
                      option={{ title: "None", img: none }}
                      height={150}
                      width={150}
                    />
                  </Grid>
                )}
              </Grid>
            </Stack>
            <Divider width="100%" color="grey" />
            <SpaceSection>Location</SpaceSection>
            <img
              width="95%"
              style={{ paddingBottom: "30px" }}
              src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/url-https%3A%2F%2Fdocs.mapbox.com%2Fapi%2Fimg%2Fcustom-marker.png(${listing.lat},${listing.lng})/${listing.lat},${listing.lng},12/850x325?access_token=${MAPS_API_KEY}`}
              alt=""
            ></img>
          </Stack>
          <Stack spacing={3} width="35%" marginTop="40px">
            <PriceCard {...listing} />
            <Secure fontSize="18px" iconSize={25} paddingLeft="40px" />
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default Space;
