import React from "react";
import Carousel from "react-material-ui-carousel";

const ImageCarousel = ({ images }) => {
  return (
    <Carousel
      navButtonsAlwaysVisible
      autoPlay={false}
      sx={{ height: "450px", width: "100%", pt: 5 }}
    >
      {images.map((image, index) => (
        <img
          src={image}
          alt=""
          key={index}
          style={{
            height: "400px",
            width: "100%",
            objectFit: "cover",
            display: "inline-flex",
            borderRadius: "25px",
          }}
        />
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
