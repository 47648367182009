import search from "../images/search.png";
import connect from "../images/connect.png";
import reserve from "../images/reserve.png";
import closet from "../images/closet.png";
import room from "../images/room.png";
import loft from "../images/loft.png";
import parking from "../images/parking.png";
import basement from "../images/basement.png";
import list from "../images/list.png";
import payment from "../images/payment.png";
import insurance from "../images/insurance.png";
import guarenteed_payment from "../images/guarenteed_payment.png";
import customer_support from "../images/customer_support.png";
import verified from "../images/verified.png";
import attic_filters from "../images/joist.png";
import bedroom_filters from "../images/bedroom.png";
import shed_filters from "../images/shed.png";
import garage_filters from "../images/garage.png";
import carport_filters from "../images/carport.png";
import parking_filters from "../images/parking_filters.png";
import basement_filters from "../images/basement_filters.png";
import closet_filters from "../images/closet_filters.png";
import warehouse_filters from "../images/warehouse.png";
import driveway_filters from "../images/driveway.png";
import self_storage_unit_filters from "../images/self_storage_unit.png";
import street_parking_filters from "../images/street_parking.png";
import other from "../images/other.png";
import no_smoke from "../images/no-smoke.png";
import smoke_detector from "../images/smoke-detector.png";
import climate_controlled from "../images/thermometer.png";
import private_entrance from "../images/door.png";
import locked_area from "../images/padlock.png";
import security_camera from "../images/cctv-camera.png";
import boat from "../images/boat_storage.png";
import business from "../images/business_storage.png";
import car from "../images/car_storage.png";
import self from "../images/self_storage.png";
import rv from "../images/rv_storage.png";
import { PointsText, BoldedPointsText } from "../styles/host/point";
import {
  Logout,
  AccountCircle,
  House,
  Tour,
  GridView,
  BookOnline,
  AccountBalance,
} from "@mui/icons-material";

export const homeCards = [
  {
    title: "Search",
    description: "Find local storage and parking spaces nearby.",
    icon: search,
  },
  {
    title: "Connect",
    description: "Speak with verified hosts and find your ideal space.",
    icon: connect,
  },
  {
    title: "Reserve",
    description: "Confirm your reservation. Swift, simple, and secure.",
    icon: reserve,
  },
];

export const hostCards = [
  {
    title: "List",
    description:
      "List your space for free! Enter in details and start listing within minutes",
    icon: list,
  },
  {
    title: "Connect",
    description:
      "Guests connect to inquire about your listing and book your space.",
    icon: connect,
  },
  {
    title: "Payment",
    description:
      "Automated payments deposited directly into your bank account.",
    icon: payment,
  },
];

export const hostPoints = [
  {
    image: insurance,
    description: (
      <PointsText>
        Free $10,000 personal liability protection for each host
      </PointsText>
    ),
  },
  {
    image: verified,
    description: (
      <PointsText>
        ID verified guests. Plus the option to screen your renters
      </PointsText>
    ),
  },
  {
    image: customer_support,
    description: (
      <PointsText>
        Talk with our team anytime{" "}
        <BoldedPointsText component="span">in Toronto</BoldedPointsText> for
        help and support
      </PointsText>
    ),
  },
  {
    image: guarenteed_payment,
    description: (
      <PointsText>
        Guarenteed payments while space is occupied. On-time and secure.
      </PointsText>
    ),
  },
];

export const faq = [
  {
    question: "Does it cost me money to host my space?",
    answer: (
      <>
        It's 100% <b>free</b>! There are no upfront or hidden costs with
        hosting. Start hosting in minutes and making a passive cash flow in
        days!{" "}
      </>
    ),
  },
  {
    question: "How can I ensure my space is right for hosting?",
    answer:
      "Every space is right for hosting! As long as you have an closed and clean area you can start a listing today." +
      " You are less likely to have to worry about damages and don't have to worry about setting up a bed and breakfast with Spaced Out!",
  },
  {
    question: "What happens if a renter misses a payment?",
    answer:
      "With automated payments at the start of each rental week, you can't miss a renter's payment! " +
      "However if there is a case in which the host doesn't recieve payment, Spaced Out will gladly cover that week's cost.",
  },
  {
    question: "How does Spaced Out make money off hosts?",
    answer: (
      <>
        You set the price, we take a small processing fee for sending out
        payments. See our <a href="/privacy">privacy policy</a> for more
        details.
      </>
    ),
  },
  {
    question: "As a renter, what happens if someone steals my stuff?",
    answer: (
      <>
        Unfortunately we are not responsible for stolen items. We try to ensure
        our community is as safe as possible through identity verification and
        secure payment processing. If your items are stolen we encourage you to
        reach out to our <a href="/contact">support team</a> if authorities need
        to be involved.
      </>
    ),
  },
];

export const storageTypesItemData = [
  {
    img: rv,
    title: "RV Storage",
    path: "?is_parking_type=true&min_size=15",
    rows: 4,
    cols: 2,
  },

  {
    img: self,
    title: "Self Storage Unit",
    path: "?is_parking_type=false&listing_type=Self%20Storage",
    rows: 2,
    cols: 2,
  },
  {
    img: business,
    title: "Business Storage",
    rows: 2,
    cols: 3,
  },
  {
    img: car,
    title: "Car Storage",
    path: "?is_parking_type=true",
    rows: 2,
    cols: 3,
  },
  {
    img: boat,
    title: "Boat Storage",
    path: "?is_parking_type=true&min_size=15",
    rows: 2,
    cols: 2,
  },
];

export const features = [
  { title: "Climate controlled", img: climate_controlled },
  { title: "Smoke detectors", img: smoke_detector },
  { title: "Smoke free", img: no_smoke },
  { title: "Private entrance", img: private_entrance },
  { title: "Locked area", img: locked_area },
  { title: "Security camera", img: security_camera },
];

export const accessAppointmentList = [
  "No appointment required",
  "Appointment required before each visit",
];

export const accessFrequencyList = [
  "Daily",
  "Weekly",
  "Monthly",
  "Infrequently",
];

export const accessHoursList = ["Daytime hours", "Evening hours", "Anytime"];

export const statusMappedValues = [
  "Draft",
  "Reported",
  "Unpublished",
  "Published",
  "Rented",
];

export const tabs = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <GridView sx={{ fontSize: 25 }} />,
  },
  {
    title: "Listings",
    path: "/listings",
    icon: <Tour sx={{ fontSize: 25 }} />,
  },
  {
    title: "Reservations",
    path: "/reservations",
    icon: <House sx={{ fontSize: 25 }} />,
  },
  {
    title: "Requests",
    path: "/requests",
    icon: <BookOnline sx={{ fontSize: 25 }} />,
  },
  {
    title: "Account",
    path: "/account",
    icon: <AccountCircle sx={{ fontSize: 25 }} />,
  },
  {
    title: "Bank",
    path: "/bank",
    icon: <AccountBalance sx={{ fontSize: 25 }} />,
  },
];

export const otherOption = [{ title: "Other", img: other }];

const storageOptionsWithoutOther = [
  { title: "Attic", img: attic_filters },
  { title: "Basement", img: basement_filters },
  { title: "Bedroom", img: bedroom_filters },
  { title: "Closet", img: closet_filters },
  { title: "Self Storage", img: self_storage_unit_filters },
  { title: "Shed", img: shed_filters },
  { title: "Warehouse", img: warehouse_filters },
];

const parkingOptionsWithoutOther = [
  { title: "Carport", img: carport_filters },
  { title: "Driveway", img: driveway_filters },
  { title: "Garage", img: garage_filters },
  { title: "Parking Spot", img: parking_filters },
  { title: "Unpaved Lot", img: driveway_filters },
  { title: "Street Parking", img: street_parking_filters },
];

export const storageOptions = storageOptionsWithoutOther.concat(otherOption);
export const parkingOptions = parkingOptionsWithoutOther.concat(otherOption);

export const allOptions = storageOptionsWithoutOther
  .concat(parkingOptionsWithoutOther)
  .concat(otherOption);

export const menuDropdown = [
  { title: "Contact Us", path: "/contact" },
  { title: "FAQ", path: "/host", position: 1225 },
  { title: "About", path: "/",  position: 3500 }
];

export const lengthOfRentDropdown = [
  { title: "One week" },
  { title: "Two weeks" },
  { title: "One month" },
  { title: "Couple of months" },
  { title: "Around a year" },
  { title: "Over a year" },
  { title: "Undetermined" },
];

export const accountDropdown = [
  {
    title: "Dashboard",
    path: "/dashboard",
    component: <GridView color="primary" sx={{ fontSize: 30 }} />,
  },
  {
    title: "Listings",
    path: "/listings",
    component: <Tour color="primary" sx={{ fontSize: 30 }} />,
  },
  {
    title: "Reservations",
    path: "/reservations",
    component: <House color="primary" sx={{ fontSize: 30 }} />,
  },
  {
    title: "Account",
    path: "/account",
    component: <AccountCircle color="primary" sx={{ fontSize: 30 }} />,
  },
  {
    title: "Logout",
    component: <Logout color="primary" sx={{ fontSize: 30 }} />,
  },
];

export const spaces = [
  { img: closet, title: "Closet", price: 106 },
  { img: room, title: "Spare Room", price: 232 },
  { img: loft, title: "Loft", price: 390 },
  { img: parking, title: "Parking Spot", price: 280 },
  { img: basement, title: "Basement", price: 598 },
];

export const footerMenu = [
  {
    title: "Legal",
    submenu: ["Terms", "Safety policy", "Privacy policy", "Cookie policy"],
  },
  {
    title: "Help",
    submenu: ["Contact", "FAQ"],
  },
];
