import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MAPS_API_KEY } from "../../lib/constants";

export const externalApi = createApi({
  reducerPath: "externalApi",
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://',
  }),
  endpoints: (builder) => ({
    geocodeAddress: builder.mutation({
      query(address) {
        return {
          url: `api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?access_token=${MAPS_API_KEY}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGeocodeAddressMutation } = externalApi;
