import React from "react";
import { Box, Grid, Stack, Divider } from "@mui/material";

import CustomTable from "../../components/CustomTable";

import {
  BankAmount,
  BankButton,
  BankTitle,
  BankSubtitle,
  BankSecondaryText,
  BankCardNumber,
} from "../../styles/dashboard/bank";
import { YScrollBox, CustomCard } from "../../styles/global";


import { bank } from "../../data/fake/information";

const Bank = () => {
  const {
    balance,
    last4DigitsCardNumber,
    depositHistory,
    paymentHistory,
    paypal,
  } = bank;
  return (
    <YScrollBox height="100%" width="100%">
      <Grid container pl={{ lg: "30%", xl: "5%" }}>
        <Grid item sm={12} xl={6}>
          <Stack height="1200px" spacing={5}>
            <Box width="450px" height="300px" padding="20px">
              <BankTitle>Account Balance</BankTitle>
              <BankSecondaryText>
                This amount will get deposited to your account below on the
                first of every month
              </BankSecondaryText>
              <BankAmount>${balance}</BankAmount>
            </Box>
            <Stack spacing={7} width="450px" height="300px" padding="20px">
              <Box>
                <BankTitle>Deposit Information</BankTitle>
                <BankSecondaryText>
                  Add your paypal to get paid. Unfortunately if you don't have
                  paypal you will have to message our team to accomodate your
                  deposits
                </BankSecondaryText>
              </Box>
              <BankCardNumber>
                {paypal ? paypal : "Looks like you don't have paypal added yet"}
              </BankCardNumber>
              <Box width="100%" textAlign="center">
                {paypal ? (
                  <BankButton>Change Paypal</BankButton>
                ) : (
                  <BankButton>Add Paypal</BankButton>
                )}
              </Box>
            </Stack>
            <Stack spacing={7} width="450px" height="300px" padding="20px">
              <Box>
                <BankTitle>Payment Information</BankTitle>
                <BankSecondaryText>
                  Don't worry this is only used once you rent a space
                </BankSecondaryText>
              </Box>
              <Box>
                <BankSubtitle>Card</BankSubtitle>
                <BankCardNumber>
                  {last4DigitsCardNumber
                    ? `xxxx xxxx xxxx ${last4DigitsCardNumber}`
                    : "Looks like you don't have a card added yet"}
                </BankCardNumber>
              </Box>
              <Box width="100%" textAlign="center">
                {last4DigitsCardNumber ? (
                  <BankButton>Change Card</BankButton>
                ) : (
                  <BankButton>Add a Card</BankButton>
                )}
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={12} xl={6}>
          <Stack spacing={9}>
            <CustomCard height="525px" width="575px">
              <BankTitle padding="20px">Deposit History</BankTitle>
              <Divider width="100%" color="black" />
              <CustomTable rows={depositHistory} history />
            </CustomCard>
            <CustomCard height="525px" width="575px">
              <BankTitle padding="20px">Payment History</BankTitle>
              <Divider width="100%" color="black" />
              <CustomTable rows={paymentHistory} history />
            </CustomCard>
          </Stack>
        </Grid>
      </Grid>
    </YScrollBox>
  );
};

export default Bank;
