import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Box, Grid } from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";

import ListingDrawer from "./ListingDrawer";
import NoItems from "../../components/NoItems";
import Loading from "../../components/Loading";

import { useGetUserSpacesQuery } from "../../store/api/spaces";
import { getSpacePictures } from '../../lib/amplify';

import {
  ListingEdit,
  ListingTitle,
  ListingImage,
  ListingStatus,
  ListingDescription,
  ListingSubtitle,
} from "../../styles/dashboard/listing";
import { CustomCard, YScrollBox } from "../../styles/global";
import { Colors } from "../../styles/theme";
import { statusMappedValues } from '../../data/index';
import noImage from "../../images/no_image.png";


const EditableListing = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const { data, error, isLoading } = useGetUserSpacesQuery();

  const getColorOfStatus = (status) => {
    switch (status) {
      case "Published":
        return "green";
      case "Rented":
        return Colors.yellow;
      case "Draft":
        return "gray";
      case "Reported":
        return "red";
      default:
        return Colors.secondary;
    }
  };

  const handleEdit = async (_id) => {
    navigate(`/create?id=${_id}`);
  };

  const handleFinish = async (_id) => {
    navigate(`/create?id=${_id}`);
  };

  useEffect(() => {
    const getFirstImages = async () => {
      const firstImages = [];
      for (const space of data) {
        const images = await getSpacePictures({ listingId: space._id, firstOnly: true });
        firstImages.push(images[0]);
      }
      setImages(firstImages);
    }
    if (data?.length > 0) {
      getFirstImages();
    }
  }, [data])

  return (
    <>
      {(error || (data && data.length === 0)) && !isLoading && (
        <NoItems
          text="Looks like you have no listings..."
          buttonText="List now"
          path="/create"
        />
      )}
      {isLoading && <Loading />}
      {data && images.length > 0 && !isLoading && (
        <YScrollBox height="100%">
          <Grid container spacing={{ xl: 5, sm: 2 }}>
            {data.map((listing, i) => {
              const {
                _id,
                listing_type,
                status,
                rate,
                width,
                length,
                address,
                description,
              } = listing;
              let image = images[i]?.path;
              if (image == null) image = noImage;
              const mappedStatus = statusMappedValues[status];
              return (
                <Grid item xl={4} sm={6} key={i}>
                  <CustomCard width={350} height={310}>
                    <Stack>
                      <Stack
                        direction="row"
                        sx={{ padding: "10px" }}
                        alignItems="center"
                      >
                        <Stack sx={{ padding: "5px" }} spacing={0.5}>
                          <ListingTitle>{`${width} x ${length} ${listing_type}`}</ListingTitle>
                          <ListingStatus c={getColorOfStatus(mappedStatus)} disabled>
                            {mappedStatus}
                          </ListingStatus>
                        </Stack>
                        {mappedStatus === "Rented" && (
                          <ListingEdit
                            startIcon={<Visibility fontSize="large" />}
                            onClick={() => setDrawer(true)}
                          >
                            View
                          </ListingEdit>
                        )}
                        {mappedStatus === "Draft" && (
                          <ListingEdit
                            startIcon={<Edit fontSize="large" />}
                            onClick={() => handleFinish(_id)}
                          >
                            Finish
                          </ListingEdit>
                        )}
                        <ListingDrawer
                          open={drawer}
                          setOpen={setDrawer}
                          {...listing}
                        />
                        {mappedStatus !== "Draft" && mappedStatus !== "Rented" && (
                          <ListingEdit
                            startIcon={<Edit fontSize="large" />}
                            onClick={() => handleEdit(_id)}
                          >
                            Edit
                          </ListingEdit>
                        )}
                      </Stack>
                        <ListingImage 
                          src={image}
                          onClick={() => navigate(`/space/${_id}`)} 
                          style={{ cursor: "pointer" }} 
                        />
                        <Box
                          sx={{
                            pl: 1,
                            pt: 1,
                            whiteSpace: "nowrap",
                            overflow: "auto",
                          }}
                        >
                          <ListingSubtitle>
                            Rate:{" "}
                            <ListingDescription component="span">
                              ${rate} per week
                            </ListingDescription>
                          </ListingSubtitle>
                          <ListingSubtitle>
                            Address:{" "}
                            <ListingDescription component="span">
                              {address}
                            </ListingDescription>
                          </ListingSubtitle>
                          <ListingSubtitle>
                            Description:{" "}
                            <ListingDescription component="span">
                              {description}
                            </ListingDescription>
                          </ListingSubtitle>
                        </Box>
                    </Stack>
                  </CustomCard>
                </Grid>
              );
            })}
          </Grid>
        </YScrollBox>
      )}
    </>
  );
};

export default EditableListing;
