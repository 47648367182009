import React from "react";
import { Stack, Typography } from "@mui/material";
import { LockPersonOutlined, CheckOutlined, AttachMoneyOutlined } from "@mui/icons-material";

const Secure = ({ fontSize, iconSize, ...other }) => {
  return (
    <Stack spacing={2} {...other} width="90%">
      <Stack direction="row" spacing={2} alignItems="center">
        <CheckOutlined sx={{ fontSize: iconSize }} />
        <Typography
          sx={{ fontFamily: "Raleway", fontSize: fontSize, fontWeight: 500 }}
        >
            Identity verified hosts to ensure a safe community within Spaced Out
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <LockPersonOutlined sx={{ fontSize: iconSize }} />
        <Typography
          sx={{ fontFamily: "Raleway", fontSize: fontSize, fontWeight: 500 }}
        >
          Secure online payments using Stripe with 256-bit SSL encryption
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <AttachMoneyOutlined sx={{ fontSize: iconSize }} />
        <Typography
          sx={{ fontFamily: "Raleway", fontSize: fontSize, fontWeight: 500 }}
        >
          Up to 50% cheaper storage and parking options
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Secure;
