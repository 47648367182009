import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Slide, Paper, Typography, Stack } from "@mui/material";
import { CheckCircleOutline, ErrorOutlined } from "@mui/icons-material";

import { getSubmitState } from "../store/selectors/appSelector";
import { hideSubmitState } from "../store/slices/appSlice";

const SubmitSlideIn = () => {
  const submitState = useSelector(getSubmitState);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      if (submitState.show) {
        dispatch(hideSubmitState());
      }
    }, 4500);
  }, [dispatch, submitState.show]);
  return (
    <>
      {submitState.show && (
        <Box
          sx={{
            width: 450,
            height: 150,
            position: "fixed",
            top: "10rem",
            right: 0,
            zIndex: 999,
          }}
        >
          <Slide
            direction="left"
            in={submitState.show}
            timeout={500}
            mountOnEnter
            unmountOnExit
          >
            <Paper
              sx={{
                m: 1,
                p: 4,
                borderRadius: "20px",
                border: submitState.success
                  ? "2px solid green"
                  : "2px solid red",
                backgroundColor: "white",
              }}
              elevation={4}
            >
              <Stack direction="row" spacing={3} alignItems="center">
                {submitState.success ? (
                  <CheckCircleOutline sx={{ color: "green", fontSize: 25 }} />
                ) : (
                  <ErrorOutlined sx={{ color: "red", fontSize: 25 }} />
                )}
                <Typography sx={{ fontSize: "18px" }}>
                  {submitState.msg}
                </Typography>
              </Stack>
            </Paper>
          </Slide>
        </Box>
      )}
    </>
  );
};

export default SubmitSlideIn;
