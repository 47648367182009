import React from "react";

import { Stack } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import { SignInTitle, SignInUnderlinedButton } from "../../styles/signIn";

const RegisterSuccess = ({ handleSwitchScreen }) => {
  return (
    <Stack
      spacing={3}
      sx={{ mt: 1 }}
      display="flex"
      width="100%"
      alignItems="center"
    >
      <SignInTitle sx={{ fontSize: "25px" }}>
        Created account! A verification link was sent to your email. Please verify before logging in.
      </SignInTitle>
      <CheckCircle sx={{ fontSize: "70px", color: "green" }} />
      <SignInUnderlinedButton onClick={(e) => handleSwitchScreen(e, "login")}>
        Go back to login
      </SignInUnderlinedButton>
    </Stack>
  );
};

export default RegisterSuccess;