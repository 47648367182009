import ryan_loft from "./images/ryans_loft.jpg";
import amir_park from "./images/amir_parking.jpg";
import adam_bed from "./images/adams_bedroom.jpg";
import zoya_bas from "./images/zoyas_basement.jpg";

export const metricData = {
  clicks: 0,
  revenue: 0,
  averageRent: "0 w",
  incomeData: [
    {
      name: "September",
      Income: 0,
    },
    {
      name: "October",
      Income: 0,
    },
    {
      name: "November",
      Income: 0,
    },
    {
      name: "December",
      Income: 0,
    },
    {
      name: "January",
      Income: 0,
    },
    {
      name: "February",
      Income: 0,
    },
  ],
};

export const bank = {
  balance: 0,
  depositHistory: [
    // {
    //   name: "John's Parking Spot",
    //   date: "03/02/2023",
    //   amount: 62,
    // },
  ],
  paymentHistory: [
    // {
    //   name: "John's Parking Spot",
    //   date: "03/02/2023",
    //   amount: 62,
    // },
  ],
};

export const spaceRentedDetails = {
  id: 1,
  renterName: "Adam",
  renterNumber: "416-028-5218",
  renterId: 5,
  moveInDate: "03/07/2023",
  approxLengthOfRent: "One month",
  itemsToStore: "Car, Chair, Boat, Pillows, Boxes",
  amountReceivedToDate: 503,
  nextPayDate: "03/20/2023",
};

export const reservations = [
  // {
  //   id: 1,
  //   image: amir_park,
  //   name: "Ryan's Loft",
  //   listingId: 2,
  //   status: "Upcoming",
  //   moveInDate: "03/07/2023",
  //   approxLengthOfRent: "One month",
  //   hostId: 2,
  //   hostName: "Ryan",
  //   hostNumber: "416-028-5218",
  //   itemsToStore: "Car, Chair, Boat, Pillows, Boxes",
  //   amountPaidToDate: 184,
  //   nextPayDate: "03/20/2023",
  // },
];

export const requests = [
  // {
  //   id: 1,
  //   listingId: 2,
  //   type: "Loft",
  //   location: "Mississauga, ON",
  //   requesterId: 4,
  //   requesterName: "John A.",
  //   moveInDate: "03/07/2023",
  //   message:
  //     "Hi I would like to rent your space. I am going to store a car, chair, and ",
  //   approxLengthOfRent: "One month",
  //   itemsToStore: "Car, Chair, Boat, Pillows, Boxes",
  //   requesterNumber: "4160295029",
  // },
];

export const listings = [
  {
    id: 1,
    images: [ryan_loft, amir_park, zoya_bas],
    rate: 15,
    hostName: "Ryan",
    name: "Ryan's Loft",
    type: "Loft",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    superHost: false,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 45.4215,
    lng: -75.6972,
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [],
    accessHours: "Daytime hours",
    accessFrequency: "Anytime",
    appointmentRequired: false,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 2,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 18,
    name: "Amir's Parking Spot",
    type: "Parking Spot",
    status: "Unpublished",
    dimension: [10, 15],
    location: "Toronto, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.575885,
    lng: -79.676,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [],
    accessHours: "Daytime hours",
    accessFrequency: "Daily",
    appointmentRequired: "No appointment required",
    hostId: "kaiz",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 3,
    images: [adam_bed, zoya_bas, amir_park],
    rate: 21,
    name: "Adam's Bedroom",
    type: "Bedroom",
    status: "Rented",
    renter: "Adam B.",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: true,
    lat: 43.575885,
    lng: -79.644,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [],
    accessHours: "Daytime hours",
    accessFrequency: "Weekly",
    appointmentRequired: true,
    hostId: "kaiz",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 4,
    images: [],
    rate: 0,
    name: "Syed's Garage",
    status: "Draft",
    activeStep: 1,
    type: "Garage",
    dimension: [0, 0],
    location: "Mississauga, Ontario",
    superHost: false,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: -79.714999,
    lng: 43.583571,
    hostName: "Syed",
    description: undefined,
    features: [],
    accessHours: undefined,
    accessFrequency: undefined,
    appointmentRequired: undefined,
    hostId: "kaiz",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 5,
    images: [zoya_bas, ryan_loft],
    rate: 18,
    name: "Zoya's Bedroom",
    status: "Reported",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Brampton, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.579885,
    lng: -79.644523,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "kaiz",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 6,
    images: [ryan_loft, amir_park, zoya_bas],
    rate: 21,
    name: "Ryan's Loft",
    type: "Loft",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    superHost: false,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.575885,
    lng: -79.634323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 7,
    images: [adam_bed, zoya_bas, amir_park],
    rate: 22,
    name: "Adam's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: true,
    lat: 43.589885,
    lng: -79.624323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 8,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 17,
    name: "Syed's Garage",
    type: "Garage",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: false,
    lat: 43.578885,
    lng: -79.684323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 9,
    images: [zoya_bas, ryan_loft],
    rate: 20,
    name: "Zoya's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Brampton, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.584385,
    lng: -79.69323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 10,
    images: [ryan_loft, amir_park, zoya_bas],
    rate: 15,
    name: "Ryan's Loft",
    type: "Loft",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: false,
    lat: 43.577885,
    lng: -79.619323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 11,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 23,
    name: "Amir's Parking Spot",
    type: "Parking Spot",
    dimension: [10, 15],
    location: "Toronto, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.575985,
    lng: -79.650323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 12,
    images: [adam_bed, zoya_bas, amir_park],
    rate: 35,
    name: "Adam's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: true,
    lat: 43.572985,
    lng: -79.715323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 13,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 15,
    name: "Syed's Garage",
    type: "Garage",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: false,
    lat: 43.572915,
    lng: -79.824323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 14,
    images: [zoya_bas, ryan_loft],
    rate: 17,
    name: "Zoya's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Brampton, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.578285,
    lng: -79.924923,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 15,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 18,
    name: "Amir's Parking Spot",
    type: "Parking Spot",
    dimension: [10, 15],
    location: "Toronto, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.575115,
    lng: -79.324113,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 16,
    images: [adam_bed, zoya_bas, amir_park],
    rate: 21,
    name: "Adam's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: true,
    lat: 43.571185,
    lng: -79.629923,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 17,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 15,
    name: "Syed's Garage",
    type: "Garage",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: false,
    lat: 43.971185,
    lng: -79.551123,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 18,
    images: [zoya_bas, ryan_loft],
    rate: 15,
    name: "Zoya's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Brampton, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.576985,
    lng: -79.621123,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 19,
    images: [adam_bed, zoya_bas, amir_park],
    rate: 15,
    name: "Adam's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: true,
    lat: 43.570005,
    lng: -79.62,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 20,
    images: [amir_park, adam_bed, ryan_loft],
    rate: 15,
    name: "Syed's Garage",
    type: "Garage",
    dimension: [10, 15],
    location: "Mississauga, Ontario",
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    superHost: false,
    lat: 43.571185,
    lng: -79.611323,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
  {
    id: 21,
    images: [zoya_bas, ryan_loft],
    rate: 15,
    name: "Zoya's Bedroom",
    type: "Bedroom",
    dimension: [10, 15],
    location: "Brampton, Ontario",
    superHost: true,
    address: "20 Kerr Street, Mississauga, Ontario L5M 1T8, Canada",
    lat: 43.575,
    lng: -79.624542,
    hostName: "Ryan",
    description:
      "The space is on the second part of a stairwell. It's on the smaller side of the width. There's additional space when stacked on top of each other. The length of space extends up to the ceiling. To access the space, you have to go to the back of the property and open the door. As soon as one enters, there’s some space available as well. If you need additional space, you can store a few items underneath the back entrance stairwell. Please note this part is an open area.",
    features: [
      "Climate controlled",
      "Private entrance",
      "Security camera",
      "Locked area",
    ],
    accessHours: "Evening hours",
    accessFrequency: "Daily",
    appointmentRequired: true,
    hostId: "",
    serviceFee: 4.19,
    tax: 3.60,
    total: 22
  },
];

