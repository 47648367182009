import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Stack, Divider, IconButton, Avatar, Box } from "@mui/material";
import { Clear, Check, House } from "@mui/icons-material";
import { popupState } from "../../store/states";
import Popup from "../../components/Popup";
import { listings } from "../../data/fake/information";
import { CustomButton } from "../../styles/global";
import {
  RequestDrawerTitle,
  RequestDrawerSubtitle,
  RequestDrawerContent,
  RequestDrawerImage,
  RequestDrawerViewListingButton,
} from "../../styles/dashboard/requestDrawer";

const RequestDrawer = ({
  open,
  setOpen,
  listingId,
  requesterId,
  requesterName,
  moveInDate,
  message,
  approxLengthOfRent,
  itemsToStore,
  requesterNumber,
}) => {
  const [popup, setPopup] = useState(popupState);
  const [showPopup, setShowPopup] = useState(false);
  const [listing, setListing] = useState(undefined);
  const navigate = useNavigate();

  const handleApprove = () => {
    setPopup({
      confirmText: "Approve",
      confirmColour: "green",
      onConfirm: () => setOpen(false),
      title: "Here's some details before you approve",
      msg:
        "You'll receive your renter's first week deposit " +
        "and will space the items they listed in the request description. " +
        "Your renter will also recieve your phone number for communication.",
    });
    setShowPopup(true);
  };

  const handleReject = () => {
    setPopup({
      confirmText: "Reject",
      confirmColour: "red",
      onConfirm: () => setOpen(false),
      msg:
        "This action cannot be undone. Once you reject you'll lose this renter " +
        "and won't be able rent your space until someone else requests.",
    });
    setShowPopup(true);
  };

  useEffect(() => {
    const l = listings.filter((listing) => listing.id === listingId)[0];
    setListing(l);
  }, [listingId]);

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          width: "700px",
          overflow: "hidden",
          overflowY: "scroll",
        },
      }}
    >
      <Popup show={showPopup} setShow={setShowPopup} {...popup} />
      <Stack direction="row" width="100%" alignItems="center">
        <RequestDrawerTitle>{requesterName}'s Request</RequestDrawerTitle>
        <Box marginLeft="auto" paddingRight="20px">
          <IconButton onClick={() => setOpen(false)}>
            <Clear
              sx={{
                color: "black",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Box>
      </Stack>

      <Stack spacing={2}>
        {listing && listing.images && listing.images.length > 0 && (
          <RequestDrawerImage src={listing.images[0]} alt="" />
        )}

        <RequestDrawerViewListingButton
          onClick={() => navigate(`/space/${listingId}`)}
          startIcon={<House sx={{ fontSize: 20 }} />}
        >
          View Your Listing
        </RequestDrawerViewListingButton>

        <Stack
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={120}
          spacing={10}
        >
          <Stack spacing={2}>
            <RequestDrawerSubtitle>Move-In Date:</RequestDrawerSubtitle>
            <RequestDrawerContent>{moveInDate}</RequestDrawerContent>
          </Stack>
          <Divider height="100%" orientation="vertical" color="gray" />
          <Stack spacing={2}>
            <RequestDrawerSubtitle>Length of Rent:</RequestDrawerSubtitle>
            <RequestDrawerContent>{approxLengthOfRent}</RequestDrawerContent>
          </Stack>
        </Stack>
        <Divider width="100%" color="gray" />
        <RequestDrawerContent sx={{ paddingLeft: "20px", fontSize: "18px" }}>
          IMPORTANT: The length of rent is subject to change, it is just an
          approximate.
        </RequestDrawerContent>
        <RequestDrawerTitle>Request Details</RequestDrawerTitle>
        <Stack spacing={1} paddingLeft="20px">
          <RequestDrawerSubtitle>Items Stored in Unit</RequestDrawerSubtitle>
          <RequestDrawerContent>{itemsToStore}</RequestDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <RequestDrawerSubtitle>
            {requesterName}'s Message
          </RequestDrawerSubtitle>
          <RequestDrawerContent>{message}</RequestDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <RequestDrawerSubtitle>
            Who is renting your space?
          </RequestDrawerSubtitle>
          <Stack direction="row" width="100%" spacing={15}>
            <RequestDrawerContent sx={{ lineHeight: 2 }}>
              Meet {requesterName}! You can connect with them below!
              <br />
              <RequestDrawerContent
                sx={{ fontWeight: "bold" }}
                component="span"
              >
                Number:{" "}
              </RequestDrawerContent>
              {requesterNumber}
            </RequestDrawerContent>
            <IconButton onClick={() => navigate(`/profile?id=${requesterId}`)}>
              <Avatar
                sx={{
                  width: 62,
                  height: 62,
                  bgcolor: "primary.main",
                  fontSize: "30px",
                }}
              >
                {requesterName[0]}
              </Avatar>
            </IconButton>
          </Stack>
        </Stack>
        <Box position="sticky" sx={{ backgroundColor: "white", bottom: "0px" }}>
          <Divider width="100%" color="black" />
          <Stack
            direction="row"
            spacing={3}
            display="flex"
            justifyContent="center"
            padding="20px"
          >
            <CustomButton
              c="white"
              bg="red"
              sx={{ marginTop: "0px" }}
              startIcon={<Clear color="red" sx={{ fontSize: 20 }} />}
              onClick={handleReject}
            >
              Reject
            </CustomButton>
            <CustomButton
              c="white"
              bg="green"
              sx={{ marginTop: "0px" }}
              startIcon={<Check color="green" sx={{ fontSize: 20 }} />}
              onClick={handleApprove}
            >
              Approve
            </CustomButton>
          </Stack>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default RequestDrawer;
