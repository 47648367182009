export const listingState = {
  images: [],
  _id: undefined,
  rate: 0,
  listing_type: undefined,
  width: 0,
  length: 0,
  location: undefined,
  address: undefined,
  lat: undefined,
  lng: undefined,
  status: undefined,
  description: undefined,
  features: [],
  accessHours: undefined,
  accessFrequency: undefined,
  appointmentRequired: false,
  activeStep: 0
};

export const popupState = {
  confirmText: "",
  closeText: "",
  onConfirm: undefined,
  onClose: undefined,
  title: "",
  msg: "",
  confirmColour: undefined,
  closeColour: undefined,
};

export const userState = {
  auth_id: undefined,
  identity_id: undefined,
  biography: undefined,
  created: undefined,
  email: undefined,
  email_verified: undefined,
  phone_number_verified: undefined,
  phone_number: undefined,
  first_name: undefined,
  id: undefined,
  last_name: undefined,
  super_host: undefined,
};
