import { createSelector } from "@reduxjs/toolkit";

export const hoveredListing = createSelector(
  (state) => state.listing.hoveredListings,
  (listings) =>
    listings.find((listing) => {
      if (listing.hover) {
        return listing;
      }
      return null;
    })
);

export const getListing = createSelector(
  (state) => state.listing.listing,
  (listing) => listing
);
