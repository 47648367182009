import React from "react";
import {
  OptionButton,
  OptionImage,
  OptionTitle,
} from "../styles/components/listingOptionCard";

const ListingOptionCard = ({
  clicked,
  handleOptionClick,
  option,
  height,
  width = height,
  ...other
}) => {
  return (
    <OptionButton
      clicked={Boolean(clicked).toString()}
      onClick={() => option && handleOptionClick(option.title)}
      width={width}
      height={height}
      {...other}
    >
      {option.img && <OptionImage alt="" src={option.img} size={height} />}
      <OptionTitle>{option.title}</OptionTitle>
    </OptionButton>
  );
};

export default ListingOptionCard;
