import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../lib/amplify";
import { STANDARD_ERROR_MSG } from "../../lib/constants";

const initialState = {
  accessToken: getAccessToken(),
  login: { show: false, pathOnLogin: "/", dataOnLogin: null },
  submitState: { show: false, success: false, msg: "" },
  refetchUser: false
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = getAccessToken();
    },
    setLogin(state, action) {
      if (action.payload.pathOnLogin) {
        state.login.pathOnLogin = action.payload.pathOnLogin;
      }
      state.login.dataOnLogin = action.payload.dataOnLogin;
      state.login.show = action.payload.show;
    },
    hideSubmitState(state, action) {
      state.submitState.show = false;
    },
    setGlobalSuccess(state, action) {
      state.submitState.msg = action.payload;
      state.submitState.show = true;
      state.submitState.success = true;
    },
    setGlobalError(state, action) {
      if (action.payload) {
        state.submitState.msg = action.payload;
      } else {
        state.submitState.msg = STANDARD_ERROR_MSG;
      }
      
      state.submitState.show = true;
      state.submitState.success = false;
    },
    refetchUser(state, action) {
      state.refetchUser = !state.refetchUser; // this is set to re-fetch user on changes
    }
  },
});

export const {
  setAccessToken,
  setLogin,
  logout,
  setGlobalError,
  setGlobalSuccess,
  hideSubmitState,
  refetchUser,
} = appSlice.actions;
export default appSlice.reducer;
