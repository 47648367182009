import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Avatar, IconButton } from "@mui/material";
import { House, More } from "@mui/icons-material";

import RequestDrawer from "./RequestDrawer";
import NoItems from "../../components/NoItems";

import {
  RequestTitle,
  RequestButton,
  RequestSubtitle,
  RequestDescription,
} from "../../styles/dashboard/request";
import { CustomCard, YScrollBox } from "../../styles/global";
import { Colors } from "../../styles/theme";

import { requests } from "../../data/fake/information";


const Requests = () => {
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {requests == null || requests.length === 0 ? (
        <NoItems
          text="Looks like you have no requests at this time..."
          buttonText="Create a listing"
          path="/create"
        />
      ) : (
        <YScrollBox height="100%">
          <Stack spacing={4}>
            {requests.map((request, i) => {
              const {
                listingId,
                type,
                location,
                requesterId,
                requesterName,
                moveInDate,
                message,
              } = request;
              return (
                <CustomCard width={800} height={150} key={i}>
                  <Stack direction="row">
                    <Stack direction="row" spacing={3}>
                      <IconButton
                        sx={{ width: 52, height: 52 }}
                        onClick={() => {
                          navigate(`/profile?id=${requesterId}`);
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 52,
                            height: 52,
                            bgcolor: "primary.main",
                          }}
                        >
                          {requesterName[0]}
                        </Avatar>
                      </IconButton>
                      <Stack
                        spacing={1}
                        sx={{ whiteSpace: "nowrap", overflow: "auto" }}
                        width="500px"
                      >
                        <RequestTitle>{`${requesterName} wants to rent your ${type}`}</RequestTitle>
                        <RequestSubtitle>
                          Move-In Date:{" "}
                          <RequestDescription component="span">
                            {moveInDate}
                          </RequestDescription>
                        </RequestSubtitle>
                        <RequestSubtitle>
                          Location:{" "}
                          <RequestDescription component="span">
                            {location}
                          </RequestDescription>
                        </RequestSubtitle>
                        <RequestSubtitle>
                          Message:{" "}
                          <RequestDescription component="span">
                            {message}
                          </RequestDescription>
                        </RequestSubtitle>
                      </Stack>
                    </Stack>
                    <Stack spacing={3} sx={{ marginLeft: "auto" }}>
                      <RequestButton
                        c={Colors.secondary}
                        sx={{ height: "60px" }}
                        startIcon={
                          <House color="secondary" sx={{ fontSize: 20 }} />
                        }
                        onClick={() => navigate(`/space/${listingId}`)}
                      >
                        View Listing
                      </RequestButton>
                      <RequestButton
                        c="green"
                        sx={{ height: "60px" }}
                        startIcon={<More color="green" sx={{ fontSize: 20 }} />}
                        onClick={() => setDrawer(true)}
                      >
                        View Request
                      </RequestButton>
                      <RequestDrawer
                        open={drawer}
                        setOpen={setDrawer}
                        {...request}
                      />
                    </Stack>
                  </Stack>
                </CustomCard>
              );
            })}
          </Stack>
        </YScrollBox>
      )}
    </>
  );
};

export default Requests;
