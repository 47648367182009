import React, { useState } from "react";
import { Stack } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import CustomDatePicker from "../../components/DatePicker";
import Dropdown from "../../components/Dropdown";

import { BookCard, BookHelperText, BookSubtitle } from "../../styles/book";
import {
  RentalDetailsLengthOfRentButton,
  RentalDetailsLabel,
} from "../../styles/book/rentalDetails";

import { lengthOfRentDropdown } from "../../data";
import FormTextInput from '../../components/FormTextInput';

const RentalDetails = ({ details, setDetails }) => {
  const { moveInDate, approxLengthOfRent } = details;
  const [openDrop, setOpenDrop] = useState(null);

  const handleOpenDrop = (event) => {
    setOpenDrop(event.currentTarget);
  };

  const handleDateChange = (val) => {
    setDetails({ ...details, moveInDate: val });
  };

  const handleDropClick = (val) => {
    setDetails({ ...details, approxLengthOfRent: val });
  };

  return (
    <BookCard>
      <Stack width="100%" spacing={3}>
        <Stack>
          <BookSubtitle>Rental Details</BookSubtitle>
          <BookHelperText>
            Enter some quick details for your host
          </BookHelperText>
        </Stack>
        <CustomDatePicker
          width="500px"
          date={moveInDate}
          setDate={handleDateChange}
        />
        <Stack>
          <RentalDetailsLabel>Length of Stay</RentalDetailsLabel>
          <BookHelperText>
            This is just an approximate for your host, you can rent for longer
          </BookHelperText>
        </Stack>
        <RentalDetailsLengthOfRentButton
          color="secondary"
          onClick={handleOpenDrop}
          endIcon={<ArrowDropDown fontSize="large" />}
        >
          {approxLengthOfRent}
        </RentalDetailsLengthOfRentButton>

        <Dropdown
          options={lengthOfRentDropdown}
          width="550px"
          setAnchorEl={setOpenDrop}
          anchorEl={openDrop}
          handleClick={handleDropClick}
          positionContent="center"
        />
        <FormTextInput
          name="items_to_store"
          label="Items To Store"
          placeholder="Enter a list of items you will store"
        />
      </Stack>
    </BookCard>
  );
};

export default RentalDetails;
