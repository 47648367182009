import React, { useEffect, useState } from "react";

import { Check, MilitaryTech } from "@mui/icons-material";
import { Stack, Divider, Avatar, Container, Grid } from "@mui/material";

import Listing from "../../components/Listing";
import Loading from "../../components/Loading";

import useGetUser from "../../hooks/useGetUser";
import { useGetPublicUserSpacesMutation } from "../../store/api/spaces";
import { getSpacePictures } from '../../lib/amplify';

import {
  ProfileName,
  ProfileSubtitle,
  ProfileVerified,
  ProfileContent,
  ProfilePhoneSubtitle,
  ProfileTitle,
} from "../../styles/profile";
import { YScrollBox } from "../../styles/global";


const Profile = () => {
  const identityVerified = true;
  const isRenter = false;
  const [listings, setListings] = useState(undefined);
  const [user, , isLoading] = useGetUser();
  const [getPublicUserSpaces] = useGetPublicUserSpacesMutation();

  useEffect(() => {
    const fetchSpaces = async (auth_id, identity_id) => {
      let spaces = await getPublicUserSpaces(auth_id);
      spaces = JSON.parse(JSON.stringify(spaces.data));
      for (const space of spaces) {
        const images = await getSpacePictures({
          listingId: space._id,
          id: identity_id,
          firstOnly: true,
        });
        space.image = images[0].path;
        space.name = space.listing_type
      }
      setListings(spaces);
    };
    if (user.auth_id) fetchSpaces(user.auth_id, user.identity_id);
  }, [user.auth_id, user.identity_id, getPublicUserSpaces]);

  return (
    <Container maxWidth="xl" sx={{ padding: 10 }}>
      {isLoading && <Loading />}
      {user.first_name && !isLoading && (
        <Stack direction="row">
          <Stack spacing={5} width="30%">
            <Stack spacing={2}>
              <Avatar
                src={user.profile_picture}
                sx={{
                  width: 130,
                  height: 130,
                  fontSize: "30px",
                  bgcolor: "primary.main",
                }}
              >
                {user.first_name[0]}
              </Avatar>
              <ProfileName>{`${user.first_name} ${user.last_name[0]}.`}</ProfileName>
              {listings && (
                <ProfileSubtitle>
                  {listings.length === 0 && "No listings "}
                  {listings.length === 1 && "1 listing "}
                  {listings.length > 1 && `${listings.length} listings `}
                  | Joined{" "}
                  {new Date(user.created).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                  })}
                </ProfileSubtitle>
              )}

              {user.super_host && (
                <Stack direction="row" spacing={2}>
                  <ProfileSubtitle>Superhost</ProfileSubtitle>
                  <MilitaryTech fontSize="large" color="black" />
                </Stack>
              )}
            </Stack>
            {user.phone_number && (
              <Stack spacing={2}>
                <Divider color="gray" width="100%" />
                <Stack>
                  <ProfileSubtitle>Phone Number</ProfileSubtitle>
                  <ProfilePhoneSubtitle>
                    You'll see this if you're renting {user.first_name}'s
                    property
                  </ProfilePhoneSubtitle>
                </Stack>
                <ProfileContent>
                  {isRenter ? user.phone_number : "xxx-xxx-xxxx"}
                </ProfileContent>
              </Stack>
            )}
            <Divider color="gray" width="100%" />
            <Stack spacing={2}>
              <ProfileSubtitle>Bio</ProfileSubtitle>
              <ProfileContent>{user.biography}</ProfileContent>
            </Stack>
            <Divider color="gray" width="100%" />
            <Stack spacing={3}>
              <ProfileVerified>Verified information</ProfileVerified>
              {identityVerified && (
                <Stack direction="row" width="100%">
                  <ProfileSubtitle>Identity verified</ProfileSubtitle>
                  <Check
                    fontSize="large"
                    sx={{ color: "green", marginLeft: "auto" }}
                  />
                </Stack>
              )}
              <Stack direction="row" width="100%">
                <ProfileSubtitle>Email verified</ProfileSubtitle>
                <Check
                  fontSize="large"
                  sx={{ color: "green", marginLeft: "auto" }}
                />
              </Stack>
              {user.phone_number_verified && (
                <Stack direction="row" width="100%">
                  <ProfileSubtitle>Phone number verified</ProfileSubtitle>
                  <Check
                    fontSize="large"
                    sx={{ color: "green", marginLeft: "auto" }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
          <YScrollBox width="70%">
            {listings ? (
              <Stack spacing={3} width="100%">
                <ProfileTitle pl={{ xl: "12%", sm: "23%" }}>
                  {user.first_name}'s Listings
                </ProfileTitle>
                {listings.length === 0 ? (
                  <ProfileContent textAlign="center">
                    Looks like they don't have any listings currently
                  </ProfileContent>
                ) : (
                  <Grid
                    container
                    spacing={3}
                    pl={{ xl: "10%", sm: "20%" }}
                    width="100%"
                    justifyContent="center"
                  >
                    {listings.map((listing, i) => (
                      <Grid item xl={6} sm={12} key={i}>
                        <Listing
                          listing={listing}
                          key={i}
                          _width={450}
                          height={400}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Stack>
            ) : (
              <Loading />
            )}
          </YScrollBox>
        </Stack>
      )}
      {!user.first_name && !isLoading && (
        <Stack width="100%" height={1000} alignItems="center" pt={10}>
          <ProfileName>
            Sorry looks like this user doesn't exist anymore
          </ProfileName>
        </Stack>
      )}
    </Container>
  );
};

export default Profile;
