import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import AppBar from "./components/AppBar";
import SignIn from "./pages/signIn/";

const PageLayout = () => {
  return (
    <>
      <ScrollToTop />
      <AppBar />
      <SignIn />
      <Outlet />
      <Footer />
    </>
  );
};

export default PageLayout;
