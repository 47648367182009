import React from "react";
import { Stack } from "@mui/material";
import { Block } from "@mui/icons-material";

import { BookCard, BookSubtitle } from "../../styles/book";
import { PoliciesTitle, PoliciesText } from "../../styles/book/policies";

const Policies = () => {
  return (
    <BookCard>
      <BookSubtitle sx={{ mb: 3 }}>Policies</BookSubtitle>
        <Stack direction="row" spacing={2}>
          <Block fontSize="large" />
          <Stack spacing={2}>
            <PoliciesTitle>Cancellation</PoliciesTitle>
            <PoliciesText>
              You can cancel your reservation and get a refund up to 24 hours
              after you book.
            </PoliciesText>
          </Stack>
        </Stack>
    </BookCard>
  );
};

export default Policies;
