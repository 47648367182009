import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, CardMedia } from "@mui/material";
import { Colors } from '../theme';


export const HostBannerContainer = styled(Box)(() => ({
    position: "relative",
    flex: "1 0 auto",
}));

export const HostBannerImage = styled(CardMedia)(() => ({
    component: "img",
    display: "flex",
    objectFit: "contain",
    height: 700,
}));


export const HostBannerContent = styled(Box)(() => ({
    position: "absolute",
    top: "70%",
    width: '550px',
    paddingRight: '20px',
    left: "90%",
    textAlign: 'right',
    transform: "translateX(-80%)",
}));

export const HostBannerTitle = styled(Typography)(() => ({
    lineHeight: 1.15,
    fontWeight: 700,
    fontSize: "50px",
    color: Colors.secondary,
    fontFamily: "Raleway",
    marginBottom: "20px",
}));

export const HostBannerDescription = styled(Typography)(() => ({
    lineHeight: 1.15,
    letterSpacing: 1.15,
    color: "black",
    fontSize: '20px',
    fontFamily: "Raleway",
    marginBottom: "3em",
}));

export const HostSecondBanner = styled("img")(() => ({
    display: "flex",
    objectFit: "contain",
    width:"57%",
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20
}));
