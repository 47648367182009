import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLogin } from "../../store/slices/appSlice";
import { showLogin } from "../../store/selectors/appSelector";

import { Dialog, Container } from "@mui/material";
import { ArrowLeft, Close } from "@mui/icons-material";

import Register from "./Register";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import RegisterSuccess from "./RegisterSuccess";

import {
  SignInCloseButton,
  SignInStyledContainer,
  SignInLogoImage,
  SignInCopyrightText,
  SignInBackButton,
} from "../../styles/signIn";

import logo from "../../images/logo_mobile.png";

const SignIn = () => {
  const dispatch = useDispatch();
  const loginPopup = useSelector(showLogin);
  const [screen, setScreen] = useState("login");
  const [resetEmail, setResetEmail] = useState("");

  const handleClose = () => {
    dispatch(setLogin({ show: false }));
    setScreen("login");
  };

  const handleSwitchScreen = (e, page) => {
    e?.preventDefault();
    setScreen(page);
  };

  return (
    <Dialog
      open={loginPopup.show}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "25px",
          width: "100%",
        },
      }}
    >
      <Container component="main" maxWidth="lg">
        <SignInCloseButton onClick={handleClose}>
          <Close />
        </SignInCloseButton>
        {(screen === "forgot" || screen === "reset") && (
          <SignInBackButton
            onClick={(e) =>
              handleSwitchScreen(e, screen === "forgot" ? "login" : "forgot")
            }
            startIcon={<ArrowLeft sx={{ fontSize: 20 }} />}
          >
            Back
          </SignInBackButton>
        )}
        <SignInStyledContainer>
          <SignInLogoImage src={logo} alt="" />
          {screen === "register" && (
            <Register handleSwitchScreen={handleSwitchScreen} />
          )}
          {screen === "login" && (
            <Login
              handleSwitchScreen={handleSwitchScreen}
              handleClose={handleClose}
            />
          )}
          {screen === "forgot" && (
            <ForgotPassword
              handleSwitchScreen={handleSwitchScreen}
              setResetEmail={setResetEmail}
            />
          )}
          {screen === "reset" && (
            <ResetPassword
              email={resetEmail}
              handleSwitchScreen={handleSwitchScreen}
            />
          )}
          {screen === "resetSuccess" && (
            <ResetPasswordSuccess handleSwitchScreen={handleSwitchScreen} />
          )}
          {screen === "registerSuccess" && (
            <RegisterSuccess handleSwitchScreen={handleSwitchScreen} />
          )}
        </SignInStyledContainer>
        <SignInCopyrightText variant="body2">
          {"Copyright © "}
          Spaced Out {new Date().getFullYear()}
          {"."}
        </SignInCopyrightText>
      </Container>
    </Dialog>
  );
};

export default SignIn;
