import React from "react";
import { Stack, Typography } from "@mui/material";
import { CalendarMonthOutlined, BookOnlineOutlined } from "@mui/icons-material";

const Access = ({ accessHours, accessFrequency, appointmentRequired }) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <CalendarMonthOutlined fontSize="large" />
        <Typography
          sx={{ fontFamily: "Raleway", fontSize: "20px", fontWeight: 500 }}
        >
          {accessFrequency}
          <Typography
            component="span"
            sx={{
              marginLeft: "10px",
              display: "inline-block",
              fontFamily: "Raleway",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            {"|"}
          </Typography>
          <Typography
            component="span"
            sx={{
              marginLeft: "10px",
              display: "inline-block",
              fontFamily: "Raleway",
              fontSize: "20px",
              fontWeight: 500,
            }}
          >
            {accessHours}
          </Typography>
        </Typography>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <BookOnlineOutlined fontSize="large" />
        <Typography
          sx={{ fontFamily: "Raleway", fontSize: "18px", fontWeight: 500 }}
        >
          {appointmentRequired}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Access;
