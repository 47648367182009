import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import { Colors } from '../theme';

export const BankAmount = styled(Typography)(() => ({
  fontFamily: "Noto Sans",
  fontSize: "80px",
  fontWeight: "bold",
  textAlign: "center",
  paddingTop: "60px"
}));

export const BankTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "40px",
  fontWeight: "bold",
}));

export const BankSecondaryText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "18px",
  color: "grey",
}));

export const BankCardNumber = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "20px",
    textAlign: "center"
  }));

export const BankSubtitle = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "25px",
    fontWeight: 500,
  }));

export const BankButton = styled(Button)(() => ({
  fontSize: "20px",
  backgroundColor: Colors.primary,
  borderRadius: "20px",
  color: "white",
  fontWeight: 600,
  width: "80%",
  textAlign: "center",
  padding: "5px 20px 5px 20px",
  border: `1.5px solid black`,
  "&:hover": {
    backgroundColor: Colors.primary,
    filter: "drop-shadow(2px 2px 2px #222)",
  },
  textTransform: "none",
}));
