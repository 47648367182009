import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import { Grid, Alert, Stack } from "@mui/material";

import { setAccessToken } from "../../store/slices/appSlice";
import { showLogin } from "../../store/selectors/appSelector";
import { useSetIdentityIdMutation } from "../../store/api/users";

import {
  FormPasswordInput,
  FormEmailInput,
} from "../../components/CustomFormTextInput";

import { signIn } from "../../lib/amplify";
import { STANDARD_ERROR_MSG } from "../../lib/constants";

import {
  SignInUnderlinedButton,
  SignInSubmitButton,
  SignInTitle,
} from "../../styles/signIn";
import { Colors } from "../../styles/theme";

const Login = ({ handleSwitchScreen, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginPopup = useSelector(showLogin);
  const [error, setError] = useState(undefined);
  const [setIdentityId] = useSetIdentityIdMutation();
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const handleSubmit = async (data) => {
    const { email, password } = data;
    try {
      const payload = await signIn(email, password);
      await dispatch(setAccessToken());
      await setIdentityId(payload).unwrap();
      navigate(loginPopup.pathOnLogin, { state: loginPopup.dataOnLogin });
      handleClose();
    } catch (e) {
      if (e.name === "NotAuthorizedException") {
        return setError("The email or password you entered was incorrect");
      } else if (e.name === "UserNotConfirmedException") {
        return setError("Please verify your email before logging in!");
      } else {
        return setError(STANDARD_ERROR_MSG);
      }
    }
  };
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        onChange={() => setError(false)}
        style={{ width: "100%" }}
      >
        <SignInTitle>Sign in</SignInTitle>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {error}
          </Alert>
        )}

        <Stack width="100%" sx={{ mt: 1 }} spacing={2}>
          <FormEmailInput />
          <FormPasswordInput />
          <Stack>
            <SignInSubmitButton type="submit" bg={Colors.secondary}>
              Sign in
            </SignInSubmitButton>
          </Stack>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs>
              <Link to="/" style={{ fontFamily: "Raleway", fontSize: "18px" }}>
                <SignInUnderlinedButton
                  onClick={(e) => handleSwitchScreen(e, "forgot")}
                  sx={{ float: "left" }}
                >
                  Forgot password?
                </SignInUnderlinedButton>
              </Link>
            </Grid>
            <Grid item>
              <SignInUnderlinedButton
                onClick={(e) => handleSwitchScreen(e, "register")}
              >
                Don't have an account?
              </SignInUnderlinedButton>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Login;
