import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { TextField, Box, Typography } from "@mui/material";

const CustomDatePicker = ({ width, date, setDate, ...other }) => {
  const handleDateChange = (date) => {
    const formattedDate = date?.format("MM-DD-YYYY");
    setDate(formattedDate);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        InputProps={{
          sx: { marginTop: "25px" },
        }}
        value={dayjs(date)}
        minDate={dayjs()}
        onChange={handleDateChange}
        renderInput={(params) => (
          <Box sx={{ position: "relative" }}>
            <TextField
              {...params}
              {...other}
              size="medium"
              variant="standard"
              sx={{
                height: "70px",
                border: "1px solid black",
                padding: "0px 25px",
                backgroundColor: "white",
                width: width,
                borderRadius: "20px",
              }}
              InputProps={{
                ...params.InputProps,

                style: {
                  fontFamily: "Raleway",
                  fontSize: "18px",
                },
                disableUnderline: true,
              }}
            />
            <Typography
              sx={{
                position: "absolute",
                top: 10,
                left: 27,
                fontFamily: "Raleway",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              MOVE-IN DATE
            </Typography>
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
