import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";
import { Colors } from "../theme";

export const NewsHelpTitle = styled(Typography)(() => ({
  lineHeight: 1.25,
  fontSize: "30px",
  fontFamily: "Raleway",
  fontWeight: "bold",
  color: Colors.secondary,
}));

export const NewsHelpSubtitle = styled(Typography)(() => ({
  lineHeight: 1.15,
  fontSize: "18px",
  fontFamily: "Raleway",
}));

export const BoldedContactUs = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
  color: Colors.primary,
  display: "inline-block",
  fontFamily: "Raleway",
  textDecoration: "underline " + Colors.primary,
}));

export const NewsHelpImage = styled("img")(() => ({
  width: "10%",
  height: "100%",
}));

export const NotifyButton = styled(Button)(() => ({
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: "bold",
    borderRadius: "0 28px 28px 0",
    backgroundColor: Colors.secondary,
    color: "white",
    padding: "5px 0px 5px 0px",
    width: "30%",
    "&:hover": {
        backgroundColor: Colors.secondary,
        filter: "drop-shadow(2px 2px 2px #222)",
    },
    textTransform: "none",
}));
