import React, { useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";

// import { hoveredListing } from "../../store/selectors/listingSelector";
import { ConvertReactToHtml } from "../../lib/utils";
import { MAPS_API_KEY } from "../../lib/constants";

import Marker from "./Marker";
import MapPopup from "./MapPopup";

mapboxgl.accessToken = MAPS_API_KEY;

const Map = ({ center, listings }) => {
  let map = useRef();
  const [markers, setMarkers] = useState([]);
  // const selected = useSelector(hoveredListing);
  // const [selectedMarker, setSelectedMarker] = useState(undefined);

  // useEffect(() => {
  //   if (!selectedMarker && selected) {
  //     const markerElement = ConvertReactToHtml(<Marker {...selected} hover />);
  //     let marker = new mapboxgl.Marker(markerElement)
  //       .setLngLat([selected.lng, selected.lat])
  //       .addTo(map.current);
  //     setSelectedMarker(marker);
  //   } else {
  //     if (selectedMarker) {
  //       selectedMarker.remove();
  //       setSelectedMarker(undefined);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [selected]);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/spacedout/clec7k6c7000401mrj0gz9q42",
      center: [0, 0],
      zoom: 8,
    });
    map.current.scrollZoom.setZoomRate(1 / 2000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const clearOverlays = () => {
      for (var i = 0; i < markers.length; i++) {
        markers[i].remove();
      }
      setMarkers([]);
    };

    let markersArray = [];
    const makeMarkers = () => {
      clearOverlays();
      if (listings) {
        listings.map((listing) => {
          const markerElement = ConvertReactToHtml(<Marker {...listing} />);
          const popupElement = ConvertReactToHtml(
            <MapPopup listing={listing} />
          );
          let marker = new mapboxgl.Marker(markerElement)
            .setLngLat([listing.lat, listing.lng])
            .setPopup(
              new mapboxgl.Popup({
                offset: 25,
                maxWidth: "300px",
              }).setDOMContent(popupElement)
            )
            .addTo(map.current);
          markersArray.push(marker);
          return marker;
        });
        setMarkers(markersArray);
      }
    };
    makeMarkers();
    map.current.setCenter(center);
    map.current.resize();
    // eslint-disable-next-line
  }, [listings]);

  return <div id="map" style={{ height: "100%" }}></div>;
};

export default Map;
