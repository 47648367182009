import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { Stack, Divider } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import {
  updateUserAttribute,
  changePassword,
  verifyAttribute,
} from "../../lib/amplify";

import {
  FormPasswordInput,
  FormEmailInput,
  FormPhoneInput,
} from "../../components/CustomFormTextInput";
import Loading from "../../components/Loading";
import ConfirmAttrChange from "../../components/ConfirmAttrChange";

import { AccountSubtitle, AccountLabel } from "../../styles/dashboard/account";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

const PrivateInfo = ({ email, phone_number, phone_number_verified }) => {
  const [isLoadingPass, setIsLoadingPass] = useState(false);
  const [isLoadingPhone, setIsLoadingPhone] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [type, setType] = useState("email");
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const passwordMethods = useForm({
    defaultValues: {
      old_password: "",
      new_password: "",
    },
  });
  const emailMethods = useForm({
    defaultValues: {
      email: "",
    },
  });
  const phoneMethods = useForm({
    defaultValues: {
      phone_number: "",
    },
  });

  const handlePasswordSubmit = async (data) => {
    setIsLoadingPass(true);
    const { old_password, new_password } = data;
    const res = await changePassword(old_password, new_password, dispatch);
    if (res === "success") passwordMethods.reset();
    setIsLoadingPass(false);
  };

  const handlePhoneSubmit = async (data) => {
    let error;
    if (data.phone_number === phone_number) return;
    setIsLoadingPhone(true);
    setType("phone_number");

    error = await updateUserAttribute({
      phone_number: data.phone_number,
    });
    if (error) return setIsLoadingPhone(false);

    error = await verifyAttribute("phone_number", dispatch);
    if (!error) setShow(true);

    setIsLoadingPhone(false);
  };

  const handleEmailSubmit = async (data) => {
    if (data.email === email) return;
    setIsLoadingEmail(true);
    setType("email");

    const error = await updateUserAttribute({ email: data.email }, dispatch);
    if (!error) setShow(true);

    setIsLoadingEmail(false);
  };

  const handleVerifyPhone = async (e) => {
    e.preventDefault();
    setType("phone_number");
    const error = await verifyAttribute("phone_number", dispatch);
    if (!error) setShow(true);
  };

  useEffect(() => {
    emailMethods.reset({
      email: email,
    });
    phoneMethods.reset({
      phone_number: phone_number,
    });
  }, [emailMethods, phoneMethods, email, phone_number]);

  return (
    <Stack width="100%" spacing={3}>
      <AccountSubtitle>Private Information</AccountSubtitle>
      <FormProvider {...emailMethods}>
        <form
          onSubmit={emailMethods.handleSubmit(handleEmailSubmit)}
          style={{ width: "100%" }}
        >
          <FormEmailInput
            label="Email address"
            right={
              <Stack direction="row" spacing={1} alignItems="center" ml={3}>
                <CheckCircleOutline sx={{ color: "green" }} />
                <AccountLabel>Verified</AccountLabel>
              </Stack>
            }
          />
          <CustomButton bg="white" c="black" type="submit">
            {isLoadingEmail ? <Loading /> : "Update email"}
          </CustomButton>
        </form>
      </FormProvider>
      <Divider color="lightgrey" width="100%" />
      <FormProvider {...phoneMethods}>
        <form
          onSubmit={phoneMethods.handleSubmit(handlePhoneSubmit)}
          style={{ width: "100%" }}
        >
          <FormPhoneInput phone_number_verified={phone_number_verified} />
          <Stack direction="row">
            <CustomButton bg="white" c="black" type="submit">
              {isLoadingPhone ? <Loading /> : "Update phone"}
            </CustomButton>
            {!phone_number_verified && (
              <CustomButton
                bg={Colors.secondary}
                c="white"
                sx={{ ml: 5 }}
                onClick={handleVerifyPhone}
              >
                Verify number
              </CustomButton>
            )}
          </Stack>
        </form>
      </FormProvider>
      <ConfirmAttrChange
        show={show}
        setShow={setShow}
        type={type}
        emailMethods={emailMethods}
      />
      <Divider color="lightgrey" width="100%" />
      <FormProvider {...passwordMethods}>
        <form
          onSubmit={passwordMethods.handleSubmit(handlePasswordSubmit)}
          style={{ width: "100%" }}
        >
          <Stack direction="row" spacing={3}>
            <FormPasswordInput
              name="old_password"
              placeholder="Enter your old password"
              label="Old Password"
            />
            <FormPasswordInput
              name="new_password"
              placeholder="Enter a new password"
              label="New Password"
            />
          </Stack>

          <CustomButton bg="white" c="black" type="submit">
            {isLoadingPass ? <Loading /> : "Update password"}
          </CustomButton>
        </form>
      </FormProvider>
    </Stack>
  );
};

export default PrivateInfo;
