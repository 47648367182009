import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const PoliciesTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  fontWeight: "bold",
}));

export const PoliciesText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "18px",
}));
