import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Stack, Container, Box } from "@mui/material";

import { useGetFavoritesMutation } from "../../store/api/spaces";
import { useGetUserMutation } from "../../store/api/users";
import { getSpacePictures } from "../../lib/amplify";

import Banner from "./Banner";
import InfoCard from "./InfoCard";
import Listing from "../../components/Listing";
import StorageType from "./StorageType";
import Lifestyle from "./Lifestyle";
import MoneyBar from "./MoneyBar";
import Loading from "../../components/Loading";

import {
  GreenBackground,
  Title,
  Subtitle,
  XScrollBox,
  CustomButton,
} from "../../styles/global";
import { Colors } from "../../styles/theme";
import { homeCards } from "../../data/index";

const Home = () => {
  const [listings, setListings] = useState(undefined);
  const [getFavorites, { isLoading }] = useGetFavoritesMutation();
  const [getUser] = useGetUserMutation();

  useEffect(() => {
    // TODO: Change this to a different api
    const getListings = async () => {
      let fetchedSpaces = await getFavorites();
      const spaces = JSON.parse(JSON.stringify(fetchedSpaces.data));
      for (const space of spaces) {
        const user = await getUser({ id: space.auth_id }).unwrap();
        const images = await getSpacePictures({
          listingId: space._id,
          id: user.identity_id,
          firstOnly: true,
        });
        space.image = images[0].path;
        space.super_host = user.super_host;
        space.name = `${user.first_name}'s ${space.listing_type}`;
      }
      setListings(spaces);
    };
    getListings();
  }, [getUser, getFavorites]);
  return (
    <>
      <GreenBackground>
        <Banner />
        <Container maxWidth="xl">
          <Title marginBottom="30px"> How does this work? </Title>
          <Stack direction="row" spacing={{ md: 5, lg: 8, xl: 20 }}>
            {homeCards.map((card, index) => (
              <InfoCard {...card} key={index} maxWidth={350} />
            ))}
          </Stack>
          <StorageType />
        </Container>
      </GreenBackground>

      <Container maxWidth="xl">
        <Title> Storage and parking favourites </Title>
        <Subtitle> Made possible by people like you </Subtitle>

        {listings && !isLoading ? (
          <XScrollBox>
            <Stack direction="row" spacing={3}>
              {listings.map((listing, index) => (
                <Listing
                  listing={listing}
                  key={index}
                  _width={450}
                  height={400}
                />
              ))}
            </Stack>
          </XScrollBox>
        ) : (
          <Loading />
        )}
        <Box textAlign="center">
          <Link to="/search" style={{ textDecoration: "none" }}>
            <CustomButton c="white" bg={Colors.secondary} size="wide">
              View all spaces
            </CustomButton>
          </Link>
        </Box>
      </Container>

      <GreenBackground>
        <Lifestyle />
        <Container maxWidth="xl">
          <MoneyBar />
        </Container>
      </GreenBackground>
    </>
  );
};

export default Home;
