import { styled } from "@mui/material/styles";
import { Box, CardMedia, Typography } from "@mui/material";
import { Colors } from "../theme";

export const LifestyleContainer = styled(Box)(() => ({
  flex: "1 0 auto",
  backgroundColor: Colors.secondary,
  marginTop: "65px",
  justifyContent: "center",
}));

export const LifestyleImage = styled(CardMedia)(() => ({
  component: "img",
  display: "flex",
  width: "30%",
  height: 550,
  objectFit: "cover",
}));

export const LifestyleRegText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  marginLeft: "2.5rem",
  fontSize: "25px",
  color: "white",
}));

export const LifestyleMiddleText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "bold",
  marginLeft: "2.5rem",
  letterSpacing: "1px",
  fontSize: "40px",
  color: Colors.yellow,
}));
