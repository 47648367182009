import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const ListingDrawerTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: "Raleway",
  fontSize: "30px",
  padding: "20px",
}));

export const ListingDrawerSubtitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontFamily: "Raleway",
  fontSize: "25px",
}));

export const ListingDrawerContent = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const ListingDrawerImage = styled("img")(() => ({
  width: "100%",
  height: "400px",
  objectFit: "cover",
}));

export const ListingDrawerViewListingButton = styled(Button)(() => ({
  width: "100%",
  height: "60px",
  fontFamily: "Raleway",
  fontSize: "20px",
  border: "1px solid black",
  color: "black",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  textTransform: "none",
}));
