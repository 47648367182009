import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";


export const ListingTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const ListingEdit = styled(Button)(() => ({
  fontSize: "16px",
  backgroundColor: "white",
  borderRadius: "25px",
  marginLeft: "auto",
  padding: "5px 20px 5px 20px",
  border: "1px solid black",
  "&:hover": {
    backgroundColor: "white",
    filter: "drop-shadow(1px 1px 1px #222)",
  },
  textTransform: "none",
}));

export const ListingStatus = styled(Button)(({ c }) => ({
    fontSize: "12px",
    backgroundColor: c,
    borderRadius: "25px",
    fontWeight: 600,
    color: "white",
    width: "120px",
    border: `1px solid black`,
    "&:disabled": {
      color: "white",
    },
  }));

export const ListingImage = styled("img")(() => ({
  height: "130px",
  width: "100%",
  objectFit: "cover",
  cursor: "pointer",
}));

export const ListingSubtitle = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: "16px",
  }));

export const ListingDescription = styled(Typography)(() => ({
    fontFamily: "Raleway",
    display: "inline-block",
    fontSize: "16px",
    textOverflow: "ellipsis",
  }));
