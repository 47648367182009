import React from "react";
import { Link } from 'react-router-dom';
import { Typography, Stack } from "@mui/material";

import { CustomButton } from "../styles/global";
import { Colors } from '../styles/theme';

const NoItems = ({ text, buttonText, path }) => {
  return (
    <Stack spacing={3} width="100%" alignItems="center">
      <Typography
        sx={{
          fontSize: "30px",
          fontFamily: "Raleway",
          fontWeight: "bold",
        }}
      >
        {text}
      </Typography>
      <Link to={path} style={{ textDecoration: "none" }}>
        <CustomButton bg={Colors.secondary} c="white">
            {buttonText}
        </CustomButton>
      </Link>
    </Stack>
  );
};

export default NoItems;
