import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Stack, Divider, IconButton, Avatar, Box } from "@mui/material";
import { House, Check, Clear, Edit, ArrowDropDown } from "@mui/icons-material";
import Popup from "../../components/Popup";
import EditableField from "../../components/editableField";
import DatePicker from "../../components/DatePicker";
import Dropdown from "../../components/Dropdown";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";
import {
  RentalDrawerTitle,
  RentalDrawerSubtitle,
  RentalDrawerContent,
  RentalDrawerImage,
  RentalDrawerViewListingButton,
  RentalDrawerLengthOfRentButton,
} from "../../styles/dashboard/rentalDrawer";
import { popupState } from "../../store/states";
import { lengthOfRentDropdown } from "../../data";

const ReservationDrawer = ({
  open,
  setOpen,
  edit,
  setEdit,
  image,
  name,
  listingId,
  moveInDate,
  approxLengthOfRent,
  hostId,
  hostName,
  hostNumber,
  itemsToStore,
  amountPaidToDate,
  nextPayDate,
  cancellable,
  changeable,
}) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [openDrop, setOpenDrop] = useState(null);
  const [popup, setPopup] = useState(popupState);
  const [rentalDetails, setRentalDetails] = useState({
    moveInDate: moveInDate,
    approxLengthOfRent: approxLengthOfRent,
    itemsToStore: itemsToStore,
  });

  const handleChange = () => {
    setEdit(true);
  };

  const handleOpenDrop = (event) => {
    setOpenDrop(event.currentTarget);
  };

  const handleItemsChange = (val) => {
    setRentalDetails({ ...rentalDetails, itemsToStore: val });
  };

  const handleDateChange = (val) => {
    setRentalDetails({ ...rentalDetails, moveInDate: val });
  };

  const handleDropClick = (val) => {
    setRentalDetails({ ...rentalDetails, approxLengthOfRent: val });
  };

  const handleCancel = () => {
    setEdit(false);
    setPopup({
      msg:
        "Payments will stop for this rental and you'll have until " +
        "the end of your weekly payment period to move your stuff out of this space.",
      confirmColour: "red",
      confirmText: "Cancel",
    });
    setShowPopup(true);
  };

  const handleExit = () => {
    setEdit(false);
    setRentalDetails({
      moveInDate: moveInDate,
      approxLengthOfRent: approxLengthOfRent,
      itemsToStore: itemsToStore,
    });
  };

  const handleConfirm = () => {
    setPopup({
      msg:
        "Your host will be be notified of these changes. If they disagree " +
        "with your request they might ask you to revert them.",
      onConfirm: () => setEdit(false),
      confirmColour: "green",
      confirmText: "Confirm",
    });
    setShowPopup(true);
  };

  const valuesChanged = () => {
    return (
      rentalDetails.moveInDate !== moveInDate ||
      rentalDetails.approxLengthOfRent !== approxLengthOfRent ||
      rentalDetails.itemsToStore !== itemsToStore
    );
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          width: "700px",
        },
      }}
    >
      <Popup show={showPopup} setShow={setShowPopup} {...popup} />
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        position="sticky"
        sx={{ backgroundColor: "white", top: "0px" }}
      >
        <RentalDrawerTitle>{name}</RentalDrawerTitle>
        <Box marginLeft="auto" paddingRight="20px">
          <IconButton onClick={() => setOpen(false)}>
            <Clear
              sx={{
                color: "black",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Box>
      </Stack>
      <Stack spacing={3}>
        <RentalDrawerImage src={image} alt="" />

        <RentalDrawerViewListingButton
          onClick={() => navigate(`/space/${listingId}`)}
          startIcon={<House sx={{ fontSize: 20 }} />}
        >
          View Listing
        </RentalDrawerViewListingButton>

        <Stack
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={120}
          width={edit ? "640px" : "100%"}
          spacing={edit ? 6 : 10}
          pl={edit ? 3 : 0}
        >
          {edit ? (
            <DatePicker
              width="200px"
              date={rentalDetails.moveInDate}
              setDate={handleDateChange}
            />
          ) : (
            <Stack spacing={2}>
              <RentalDrawerSubtitle>Move-In Date:</RentalDrawerSubtitle>
              <RentalDrawerContent>{moveInDate}</RentalDrawerContent>
            </Stack>
          )}

          <Divider height="100%" orientation="vertical" color="gray" />
          {edit ? (
            <>
              <RentalDrawerLengthOfRentButton
                color="secondary"
                onClick={handleOpenDrop}
                endIcon={<ArrowDropDown fontSize="large" />}
              >
                {rentalDetails.approxLengthOfRent}
              </RentalDrawerLengthOfRentButton>
              <Dropdown
                options={lengthOfRentDropdown}
                setAnchorEl={setOpenDrop}
                anchorEl={openDrop}
                handleClick={handleDropClick}
              />
            </>
          ) : (
            <Stack spacing={2}>
              <RentalDrawerSubtitle>Length of Rent:</RentalDrawerSubtitle>
              <RentalDrawerContent>{approxLengthOfRent}</RentalDrawerContent>
            </Stack>
          )}
        </Stack>
        <Divider width="100%" color="gray" />
        <RentalDrawerContent sx={{ paddingLeft: "20px", fontSize: "18px" }}>
          IMPORTANT: The length of rent is subject to change, it is just an
          approximate.
        </RentalDrawerContent>
        <RentalDrawerTitle>Rental Details</RentalDrawerTitle>
        <Stack spacing={1} paddingLeft="20px">
          <RentalDrawerSubtitle>Next Pay Date</RentalDrawerSubtitle>
          <RentalDrawerContent>{nextPayDate}</RentalDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <RentalDrawerSubtitle>Amount Paid To Date</RentalDrawerSubtitle>
          <RentalDrawerContent>${amountPaidToDate}</RentalDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <RentalDrawerSubtitle>Items Stored in Unit</RentalDrawerSubtitle>
          <EditableField
            edit={edit}
            width="600px"
            setField={handleItemsChange}
            existingField={itemsToStore}
            placeholder="Enter items to store"
          />
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <RentalDrawerSubtitle>Who is your host?</RentalDrawerSubtitle>
          <Stack direction="row" width="100%" spacing={23} pb={4}>
            <RentalDrawerContent sx={{ lineHeight: 2 }}>
              You can connect with {hostName} below via text!
              <br />
              <RentalDrawerContent sx={{ fontWeight: "bold" }} component="span">
                Number:{" "}
              </RentalDrawerContent>
              {hostNumber}
            </RentalDrawerContent>
            <IconButton onClick={() => navigate(`/profile?id=${hostId}`)}>
              <Avatar
                sx={{
                  width: 62,
                  height: 62,
                  bgcolor: "primary.main",
                  fontSize: "30px",
                }}
              >
                {hostName[0]}
              </Avatar>
            </IconButton>
          </Stack>
        </Stack>
        {(changeable || cancellable) && (
          <Box
            position="sticky"
            sx={{ backgroundColor: "white", bottom: "0px" }}
          >
            <Divider width="100%" color="black" />
            <Stack
              direction="row"
              spacing={3}
              display="flex"
              justifyContent="center"
              padding="20px"
            >
              {cancellable && (
                <>
                  {edit ? (
                    <CustomButton
                      c="black"
                      bg="white"
                      sx={{ marginTop: "0px" }}
                      startIcon={<Clear sx={{ fontSize: 20 }} />}
                      onClick={handleExit}
                    >
                      Exit
                    </CustomButton>
                  ) : (
                    <CustomButton
                      c="white"
                      bg="red"
                      sx={{ marginTop: "0px" }}
                      startIcon={<Clear sx={{ fontSize: 20 }} />}
                      onClick={handleCancel}
                    >
                      Cancel
                    </CustomButton>
                  )}
                </>
              )}
              {changeable && (
                <>
                  {edit ? (
                    <>
                      {valuesChanged() && (
                        <CustomButton
                          c="white"
                          bg="green"
                          sx={{ marginTop: "0px" }}
                          startIcon={<Check sx={{ fontSize: 20 }} />}
                          onClick={handleConfirm}
                        >
                          Confirm Changes
                        </CustomButton>
                      )}
                    </>
                  ) : (
                    <CustomButton
                      c="white"
                      bg={Colors.primary}
                      sx={{ marginTop: "0px" }}
                      startIcon={<Edit sx={{ fontSize: 20 }} />}
                      onClick={handleChange}
                    >
                      Change
                    </CustomButton>
                  )}
                </>
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </Drawer>
  );
};

export default ReservationDrawer;
