import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  LifestyleContainer,
  LifestyleImage,
  LifestyleRegText,
  LifestyleMiddleText,
} from "../../styles/home/lifestyle";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";
import { Box, Stack } from "@mui/material";

import left from "../../images/senior_couple.jpg";
import right from "../../images/black_male_child.jpg";

import "./lifestyle.css";

const Lifestyle = () => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    const changeRender = () => {
      if (window.scrollY >= 1600) {
        setRender(true);
        window.removeEventListener("scroll", changeRender);
      }
    };
    window.addEventListener("scroll", changeRender);
  }, []);
  return (
    <LifestyleContainer>
      <Stack direction="row">
        <LifestyleImage image={left} />

        {render && (
          <Box width="40%">
            <Stack
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                animation: "slidein-right 2.5s ease",
              }}
            >
              <LifestyleRegText variant="h2" marginBottom="5px">
                Live the
              </LifestyleRegText>
              <LifestyleMiddleText variant="h1">LIFESTYLE</LifestyleMiddleText>
              <LifestyleRegText variant="h2" marginTop="5px">
                you want
              </LifestyleRegText>
            </Stack>
            <Box textAlign="center" marginTop="-120px">
              <Link to="/host" style={{ textDecoration: "none" }}>
                <CustomButton c="black" bg={Colors.green} size="wide">
                  Learn more
                </CustomButton>
              </Link>
            </Box>
          </Box>
        )}

        <LifestyleImage image={right} />
      </Stack>
    </LifestyleContainer>
  );
};

export default Lifestyle;
