import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../store/selectors/appSelector";
import { setLogin } from "../store/slices/appSlice";

const publicRoutes = ["/", "/host", "/search", "/space", "/contact"]

const useCustomRoute = ({ path, data }) => {
  const [clicked, setClicked] = useState(false);
  const authenticated = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPublic = publicRoutes.find((route) => path.includes(route.path));
  useEffect(() => {
    if (clicked) {
      if (authenticated) {
        navigate(path, { state: data });
      } else {
        if (!isPublic) {
          dispatch(setLogin({ show: true, pathOnLogin: path, dataOnLogin: data }));
        }
      }
      setClicked(false);
    }
    // eslint-disable-next-line
  }, [clicked, authenticated]);

  return setClicked;
};

export default useCustomRoute;
