import React from "react";
import { hostPoints } from "../../data/index";
import { Grid } from "@mui/material";
import { PointsContainer, PointsImage } from "../../styles/host/point";

const Points = () => {
  return (
    <>
      <Grid
        container
        spacing={{ lg: 2, xl: 3 }}
        columns={{ lg: 8, xl: 12 }}
        justifyContent="center"
        sx={{ marginBottom: "75px" }}
      >
        {hostPoints.map((point, index) => (
          <Grid item lg={4} xl={3} key={index} justifyContent="center">
            <PointsContainer>
              <PointsImage src={point.image} />
              {point.description}
            </PointsContainer>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Points;
