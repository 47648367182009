import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Box, Container, Stack } from "@mui/system";

import AccountDetails from "./AccountDetails";
import RentalDetails from "./RentalDetails";
import PaymentDetails from "./PaymentDetails";
import HostDetails from "./HostDetails";
import Overview from "./Overview";
import Protection from "./Protection";
import Policies from "./Policies";

import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";

import {
  BookHeader,
  BookTitle,
  BookAgreementText,
  BookAgreementLinkText,
} from "../../styles/book";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

import { listings } from "../../data/fake/information";
import logo from "../../images/logo.png";

const Book = () => {
  const [user] = useGetAuthenticatedUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [listing, setListing] = useState(null);
  const [details, setDetails] = useState({
    approxLengthOfRent: "One week",
    moveInDate: location.state,
  });
  const methods = useForm({
    defaultValues: {
      first_name: "",
      phone_number: "",
      message: "",
      items_to_store: "",
    },
  });

  const handleSubmit = (data) => {
    console.log(data)
    console.log(details)
  };

  useEffect(() => {
    if (listing == null) {
      let id = searchParams.get("listingId");
      const obj = listings.find((listing) => listing.id.toString() === id);
      setListing(obj);
    }

    methods.reset({
      first_name: user.first_name,
      phone_number: user.phone_number,
    });
  }, [searchParams, listing, user, methods]);

  return (
    <Box height={1300}>
      <BookHeader>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box href="/" component="img" alt="logo" src={logo} />
        </Link>
      </BookHeader>
      {listing == null ? (
        <Stack width="100%" height={1000} alignItems="center" pt={10}>
          <BookTitle>Sorry this page doesn't exist</BookTitle>
        </Stack>
      ) : (
        <Container maxWidth="lg" sx={{ marginTop: "3rem" }}>
          <BookTitle sx={{ mb: "3rem" }}>Book this space</BookTitle>
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              style={{ width: "100%" }}
            >
              <Stack direction="row" spacing={3}>
                <Stack spacing={3} width="60%">
                  <AccountDetails {...user} methods={methods} />
                  <RentalDetails details={details} setDetails={setDetails} />
                  <HostDetails {...listing} />
                  <PaymentDetails />

                  <Stack
                    width="100%"
                    display="flex"
                    alignItems="center"
                    pb={5}
                    spacing={3}
                  >
                    <BookAgreementText>
                      By clicking the button below I agree to the{" "}
                      <Link to="/terms" style={{ color: "black" }}>
                        <BookAgreementLinkText component="span">
                          Terms and Conditions
                        </BookAgreementLinkText>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacy" style={{ color: "black" }}>
                        <BookAgreementLinkText component="span">
                          Privacy Policy
                        </BookAgreementLinkText>
                      </Link>
                    </BookAgreementText>
                    <CustomButton c="black" bg={Colors.yellow} type="submit" size="wide">
                      Pay for Rental
                    </CustomButton>
                  </Stack>
                </Stack>
                <Stack spacing={3} width="50%">
                  <Overview {...listing} />
                  <Protection />
                  <Policies />
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </Container>
      )}
    </Box>
  );
};

export default Book;
