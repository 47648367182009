import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const RequestTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: "Raleway",
  fontSize: "25px",
}));

export const RequestButton = styled(Button)(({ c }) => ({
  fontSize: "20px",
  backgroundColor: "white",
  borderRadius: "25px",
  color: c,
  fontWeight: 600,
  padding: "5px 20px 5px 20px",
  border: `1.5px solid ${c}`,
  "&:hover": {
    backgroundColor: "white",
    filter: "drop-shadow(2px 2px 2px #222)",
  },
  textTransform: "none",
}));

export const RequestSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "18px",
}));

export const RequestDescription = styled(Typography)(() => ({
  fontFamily: "Raleway",
  display: "inline-block",
  fontSize: "18px",
}));
