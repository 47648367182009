import React from "react";
import { Fade, Popper, Paper, Typography } from "@mui/material";

const Tooltip = ({ open, anchorEl, placement, info, ...other }) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ borderRadius: "5px", ...other }}>
            <Typography sx={{ p: 2, fontFamily: "Raleway"  }}>
              {info}
            </Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default Tooltip;
