import React, { useState } from "react";
import { Stack, Box, Button, Grid } from "@mui/material";
import { Edit, Source } from "@mui/icons-material";

import ReservationDrawer from "./ReservationDrawer";
import NoItems from "../../components/NoItems";

import {
  RentalButton,
  RentalTitle,
  RentalImage,
  RentalStatus,
  RentalDate,
  RentalSubtitle,
} from "../../styles/dashboard/rental";

import { Colors } from "../../styles/theme";
import { CustomCard, YScrollBox } from "../../styles/global";

import { reservations } from "../../data/fake/information";

const Reservations = () => {
  const [edit, setEdit] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const getColorOfStatus = (status) => {
    switch (status) {
      case "Renting":
        return Colors.secondary;
      case "Cancelled":
        return "red";
      case "Past Rental":
        return "gray";
      case "Pending Approval":
        return Colors.primary;
      default:
        return "black";
    }
  };

  const handleChange = () => {
    setEdit(true);
    setDrawer(true);
  };

  const handleDetails = () => {
    setEdit(false);
    setDrawer(true);
  };

  return (
    <>
      {reservations == null || reservations.length === 0 ? (
        <NoItems
          text="Looks like you have no reservations..."
          buttonText="Start renting"
          path="/search"
        />
      ) : (
        <YScrollBox height="100%">
          <Grid container spacing={{ xl: 5, sm: 2 }}>
            {reservations.map((reservation, i) => {
              const {
                name,
                image,
                status,
                moveInDate,
                approxLengthOfRent,
              } = reservation;
              return (
                <Grid item xl={4} sm={6} key={i}>
                  <CustomCard width={400} height={420} sx={{ padding: "0px" }}>
                    <ReservationDrawer
                      open={drawer}
                      setOpen={setDrawer}
                      edit={edit}
                      setEdit={setEdit}
                      {...reservation}
                      cancellable={[
                        "Pending Approval",
                        "Upcoming",
                        "Renting",
                      ].includes(status)}
                      changeable={["Pending Approval", "Upcoming"].includes(
                        status
                      )}
                    />
                    <Stack>
                      <Stack
                        direction="row"
                        sx={{ padding: "10px" }}
                        alignItems="center"
                      >
                        <Stack
                          sx={{
                            padding: "5px",
                            whiteSpace: "nowrap",
                            overflow: "auto",
                            width: "240px",
                          }}
                          spacing={0.5}
                        >
                          <RentalTitle>{name}</RentalTitle>
                          <RentalStatus c={getColorOfStatus()} disabled>
                            {status}
                          </RentalStatus>
                        </Stack>
                        <Box sx={{ marginLeft: "auto" }}>
                          {status !== "Past Rental" ? (
                            <Stack>
                              <RentalSubtitle>Move-In Date:</RentalSubtitle>
                              <RentalDate>{moveInDate}</RentalDate>
                            </Stack>
                          ) : (
                            <RentalButton
                              c={Colors.secondary}
                              sx={{ float: "right" }}
                              startIcon={<Source fontSize="large" />}
                              onClick={handleDetails}
                            >
                              Details
                            </RentalButton>
                          )}
                        </Box>
                      </Stack>
                      <Button onClick={handleDetails} sx={{ padding: "0px" }}>
                        <RentalImage src={image} />
                      </Button>

                      {status !== "Cancelled" && status !== "Past Rental" && (
                        <Box sx={{ pl: 2, pt: 1 }}>
                          {status !== "Renting" && (
                            <RentalButton
                              c={Colors.primary}
                              startIcon={<Edit fontSize="large" />}
                              onClick={handleChange}
                            >
                              Change
                            </RentalButton>
                          )}
                          <RentalButton
                            c={Colors.secondary}
                            sx={{ float: "right", mr: 2 }}
                            startIcon={<Source fontSize="large" />}
                            onClick={handleDetails}
                          >
                            Details
                          </RentalButton>
                        </Box>
                      )}

                      {status === "Past Rental" && (
                        <Stack direction="row">
                          <Stack pl={2} pt={1.5}>
                            <RentalSubtitle>Move-In Date:</RentalSubtitle>
                            <RentalDate>{moveInDate}</RentalDate>
                          </Stack>
                          <Stack sx={{ marginLeft: "auto" }} pr={2} pt={1.5}>
                            <RentalSubtitle>Length of Rent:</RentalSubtitle>
                            <RentalDate>{approxLengthOfRent}</RentalDate>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </CustomCard>
                </Grid>
              );
            })}
          </Grid>
        </YScrollBox>
      )}
    </>
  );
};

export default Reservations;
