import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { Box, Alert } from "@mui/material";

import { FormEmailInput } from "../../components/CustomFormTextInput";

import { forgotPassword } from "../../lib/amplify";

import {
  SignInSubmitButton,
  SignInTitle,
  SignInSubtitle,
} from "../../styles/signIn";
import { Colors } from "../../styles/theme";

const ForgotPassword = ({ handleSwitchScreen, setResetEmail }) => {
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
      code: "",
    },
  });

  const handleSubmit = async (data) => {
    const submitError = await forgotPassword(data.email);
    if (submitError) {
      return setError(submitError);
    }
    handleSwitchScreen(undefined, "reset");
    setResetEmail(data.email);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        onChange={() => setError(false) && setSuccess(false)}
        style={{ width: "100%" }}
      >
        <SignInTitle>Forgot password</SignInTitle>
        <SignInSubtitle>
          A verification code will be sent to your email to reset your password
        </SignInSubtitle>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            variant="outlined"
            severity="success"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {success}
          </Alert>
        )}

        <Box sx={{ mt: 1 }}>
          <FormEmailInput />

          <SignInSubmitButton type="submit" bg={Colors.secondary}>
            Send email
          </SignInSubmitButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ForgotPassword;
