import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";

import { setHoveredListings } from "../../store/slices/listingSlice";
import { getAddress } from "../../store/selectors/searchSelector";
import { useSearchSpacesMutation } from "../../store/api/spaces";
import { useGetUserMutation } from "../../store/api/users";

import useCurrentLocation from "../../hooks/useCurrentLocation";
import { getSpacePictures } from "../../lib/amplify";

import NewsHelp from "./NewsHelp";
import Map from "./Map";
import Filters from "./Filters";
import Listing from "../../components/Listing";
import Loading from "../../components/Loading";

import { ListingsTitle, NoResults } from "../../styles/search";
import { YScrollBox, CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

import no_results from "../../images/no-results.png";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [limitListings, setLimitListings] = useState(10);
  const [listings, setListings] = useState(undefined);
  const address = useSelector(getAddress);
  const loc = useCurrentLocation();
  const [searchSpaces, { isLoading }] = useSearchSpacesMutation();
  const [getUser] = useGetUserMutation();

  // TODO: Add error handling to this and optimize(only fetch new images for new limit)
  useEffect(() => {
    const getListings = async () => {
      let fetchedSpaces = await searchSpaces(
        location.search + `&limit=${limitListings}`
      );
      if (fetchedSpaces.data) {
        const spaces = JSON.parse(JSON.stringify(fetchedSpaces.data));
        for (const space of spaces) {
          const user = await getUser({ id: space.auth_id }).unwrap();
          const images = await getSpacePictures({
            listingId: space._id,
            id: user.identity_id,
            firstOnly: true,
          });
          space.image = images[0].path;
          space.super_host = user.super_host;
          space.name = `${user.first_name}'s ${space.listing_type}`;
        }
        setListings(spaces);
        dispatch(setHoveredListings(spaces));
      }
    };
    if (searchParams.get("lng") && searchParams.get("lat")) {
      getListings();
    } else {
      let existingSearch = location.search.replace("?", "&");
      if (loc)
        navigate(`/search?lng=${loc.lng}&lat=${loc.lat}${existingSearch}`);
    }
    // eslint-disable-next-line
  }, [dispatch, loc, searchSpaces, getUser, limitListings, location.search]);

  return (
    <>
      <Filters />
      <Box display="flex">
        {listings && !isLoading ? (
          <Box width={{ sm: "40%", xl: "30%" }} pl={3}>
            <ListingsTitle>Spaces near {address}</ListingsTitle>
            <YScrollBox height={600}>
              <Stack>
                {listings.slice(0, limitListings).map((listing, index) => (
                  <Listing
                    listing={listing}
                    key={index}
                    _width="100%"
                    height={350}
                    hover
                  />
                ))}
                {listings.length === 0 && (
                  <Stack
                    width="100%"
                    display="flex"
                    alignItems="center"
                    direction="row"
                    pt={5}
                    spacing={3}
                  >
                    <img
                      src={no_results}
                      width="60px"
                      alt=""
                      style={{ objectFit: "contain" }}
                    />
                    <NoResults>
                      Sorry, there are no listings in this area yet
                    </NoResults>
                  </Stack>
                )}
                <Box
                  paddingBottom="20px"
                  display="flex"
                  justifyContent="center"
                >
                  {limitListings < listings.length && (
                    <CustomButton
                      c="white"
                      bg={Colors.secondary}
                      sx={{ marginTop: "10px" }}
                      onClick={() => setLimitListings(limitListings + 10)}
                    >
                      View more spaces
                    </CustomButton>
                  )}
                </Box>
              </Stack>
            </YScrollBox>
            <NewsHelp />
          </Box>
        ) : (
          <Loading height="500px" />
        )}
        {loc ? (
          <Box pl={3} flex={1}>
            <Map center={[loc.lng, loc.lat]} listings={listings} />
          </Box>
        ) : (
          <Loading height="500px" />
        )}
      </Box>
    </>
  );
};

export default Search;
