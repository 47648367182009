import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Typography, CardMedia } from "@mui/material";

export const HomeBannerContainer = styled(Box)(() => ({
    position: "relative",
    flex: "1 0 auto",
}));

export const HomeBannerImage = styled(CardMedia)(() => ({
    component: "img",
    display: "flex",
    objectFit: "contain",
    height: 900,
}));

export const HomeBannerContent = styled(Box)(() => ({
    position: "absolute",
    color: "black",
    top: "20%",
    left: "80%",
    transform: "translateX(-80%)",
}));

export const HomeBannerTitle = styled(Typography)(() => ({
    lineHeight: 1.35,
    fontWeight: 700,
    fontSize: "55px",
    fontFamily: "Raleway",
    marginBottom: "20px",
}));

export const HomeBannerDescription = styled(Typography)(() => ({
    lineHeight: 1.15,
    letterSpacing: 1.15,
    fontFamily: "Raleway",
    marginBottom: "2em",
    fontSize: "20px"
}));