import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box } from "@mui/system";

import PrivateRoutes from "./PrivateRoutes";
import PageLayout from "./PageLayout";
import TabsLayout from "./TabsLayout";
import SubmitSlideIn from "./components/SubmitSlideIn";

import Home from "./pages/home";
import Host from "./pages/host";
import Search from "./pages/search";
import Space from "./pages/space";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import CreateListing from "./pages/createListing";
import Book from "./pages/book";
import Listings from "./pages/listings";
import Requests from "./pages/requests";
import Reservations from "./pages/reservations";
import Account from "./pages/account";
import Bank from "./pages/bank";
import Contact from './pages/contact';


// On creation of new public routes please update hooks/useCustomRoute.js

const App = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  useEffect(() => {
    // Reset current location every day
    if (
      localStorage.getItem("loc") &&
      localStorage.getItem("loc").exp > dayjs()
    ) {
      localStorage.removeItem("loc");
    }
  }, []);

  return (
    <Box>
      {isDesktop ? (
        <>
          <SubmitSlideIn />
          <Routes>
            <Route element={<PageLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route element={<TabsLayout />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/listings" element={<Listings />} />
                  <Route path="/requests" element={<Requests />} />
                  <Route path="/reservations" element={<Reservations />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/bank" element={<Bank />} />
                </Route>
              </Route>
              <Route index element={<Home />} />
              <Route path="/host" element={<Host />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/search" element={<Search />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/space/:id" element={<Space />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="/create" element={<CreateListing />} />
              <Route path="/book" element={<Book />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </>
      ) : (
        <Box>
          Sorry unfortunately this website is not mobile friendly yet. Please
          use a desktop to access it.
        </Box>
      )}
    </Box>
  );
};

export default App;
