import { v4 as uuidv4 } from "uuid";
import { Auth, Storage } from "aws-amplify";
import awsconfig from "../aws-exports";
import { STANDARD_ERROR_MSG, PAGE_SIZE, } from "../lib/constants";
import { setGlobalError, setGlobalSuccess } from "../store/slices/appSlice";

export const createAuthUser = async (username, password) => {
  let user = await Auth.signUp({
    username,
    password,
  });
  return user.userSub;
};

export const handleCreateAuthUserError = (e) => {
  if (e.name === "InvalidParameterException") {
    if (e.message.indexOf("password") >= 0) {
      return (
        "Password is not strong enough. It needs to have " +
        "at least 1 number, 1 special, 1 uppercase, and 1 lowercase character."
      );
    } else if (e.message.indexOf("email") >= 0) {
      return "Please enter a valid email address";
    } else {
      return STANDARD_ERROR_MSG;
    }
  } else if (e.name === "UsernameExistsException") {
    return "Email already exists. Try logging in instead.";
  } else if (e.name === "InvalidPasswordException") {
    return e.message;
  } else {
    return STANDARD_ERROR_MSG;
  }
};

export const signIn = async (username, password) => {
  let res = await Auth.signIn(username, password);
  const credentials = await getUserCredentials();
  if (
    credentials?.identityId == null ||
    res == null ||
    res.attributes?.sub == null
  ) {
    await signOut();
    throw STANDARD_ERROR_MSG;
  }
  return { auth_id: res.attributes.sub, identity_id: credentials.identityId };
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (e) {
    console.log("error signing out");
  }
};

export const forgotPassword = async (username) => {
  try {
    await Auth.forgotPassword(username);
  } catch (e) {
    return STANDARD_ERROR_MSG;
  }
};

export const resetPassword = async (username, code, new_password) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
  } catch (e) {
    if (e.name === "CodeMismatchException") {
      return "The code you entered was incorrect.";
    } else if (e.name === "InvalidPasswordException") {
      return e.message;
    } else {
      return STANDARD_ERROR_MSG;
    }
  }
};

export const changePassword = async (old_password, new_password, dispatch) => {
  if (old_password === new_password)
    return dispatch(setGlobalError("Password's cannot be the same"));

  const user = await Auth.currentAuthenticatedUser();

  try {
    await Auth.changePassword(user, old_password, new_password);
    dispatch(setGlobalSuccess("Successfully changed password!"));
    return "success";
  } catch (e) {
    let error;
    if (e.name === "InvalidPasswordException") {
      error = "New " + e.message;
    } else if (e.name === "InvalidParameterException") {
      if (e.message.indexOf("proposedPassword") >= 0) {
        error =
          "New password is not strong enough. It needs to have " +
          "at least 1 number, 1 special, 1 uppercase, and 1 lowercase character.";
      } else if (e.message.indexOf("previousPassword") >= 0) {
        error = "Your password is incorrect.";
      } else {
        error = STANDARD_ERROR_MSG;
      }
    } else if (e.name === "NotAuthorizedException") {
      error = "Your password is incorrect.";
    } else if (e.name === "LimitExceededException") {
      error = "Attempt limit exceeded, please try after some time.";
    } else {
      error = STANDARD_ERROR_MSG;
    }
    dispatch(setGlobalError(error));
  }
};

export const updateUserAttribute = async (attr, dispatch) => {
  const user = await Auth.currentAuthenticatedUser();

  try {
    await Auth.updateUserAttributes(user, attr);
  } catch (e) {
    return dispatch(setGlobalError());
  }
};

export const verifyAttribute = async (attr, dispatch) => {
  try {
    await Auth.verifyCurrentUserAttribute(attr);
  } catch (e) {
    return dispatch(setGlobalError());
  }
};

export const verifyCode = async (attr, code) => {
  try {
    await Auth.verifyCurrentUserAttributeSubmit(attr, code);
  } catch (e) {
    if (e.name === "CodeMismatchException") {
      return "The code you provided was incorrect.";
    }
    return STANDARD_ERROR_MSG;
  }
};

export const getAuthenticatedUser = async () => {
  try {
    // TODO: Change cache option to only happen if user changes email/phone
    const { attributes } = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    return attributes;
  } catch (error) {
    return null;
  }
};

export const getUserCredentials = async () => {
  try {
    return await Auth.currentUserCredentials();
  } catch (error) {
    return null;
  }
};

export const getAccessToken = () => {
  const clientId = awsconfig["aws_user_pools_web_client_id"];
  const id = localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
  );
  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.${id}.idToken`
  );
};

export const changeProfilePicture = async (pathToImageFile) => {
  try {
    const response = await fetch(pathToImageFile);
    const blob = await response.blob();
    await Storage.put("profile.jpeg", blob, {
      contentType: "image/jpeg",
      level: "protected",
    });
  } catch (err) {
    console.log("Error uploading file:", err);
  }
};

export const getProfilePicture = async (id) => {
  const signedURL = await Storage.get("profile.jpeg", {
    level: "protected",
    identityId: id,
  });
  return signedURL;
};

export const setSpacePictures = (id, images) => {
  let uuidImageList = [];
  images.forEach(async (image, i) => {
    try {
      const response = await fetch(image.path);
      const blob = await response.blob();
      const uuid = uuidv4();
      await Storage.put(`${id}/${uuid}.jpeg`, blob, {
        contentType: "image/jpeg",
        level: "protected",
      });
      uuidImageList.push(uuid);
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  });
  return uuidImageList;
};

export const getSpacePictures = async ({ listingId, id, firstOnly }) => {
  let signedURLs = [];
  if (id) {
    const images = await Storage.list(`${listingId}/`, {
      level: "protected",
      identityId: id,
      pageSize: PAGE_SIZE,
    });
    for (const image of images.results) {
      const signedUrl = await getSingularSpacePicture(image.key, id);
      signedURLs.push({ key: image.key, path: signedUrl });
      if (firstOnly) return signedURLs;
    }
  } else {
    const images = await Storage.list(`${listingId}/`, {
      level: "protected",
      pageSize: PAGE_SIZE,
    });
    for (const image of images.results) {
      const signedUrl = await getSingularSpacePicture(image.key);
      signedURLs.push({ key: image.key, path: signedUrl });
      if (firstOnly) return signedURLs;
    }
  }

  return signedURLs;
};

const getSingularSpacePicture = async (imageKey, id) => {
  if (id) {
    const signedURL = await Storage.get(imageKey, {
      level: "protected",
      identityId: id,
    });
    return signedURL;
  } else {
    const signedURL = await Storage.get(imageKey, {
      level: "protected",
    });
    return signedURL;
  }
};

export const removeSpacePictures = async (id) => {
  const images = await Storage.list(`${id}/`, {
    level: "protected",
    pageSize: PAGE_SIZE,
  });
  for (const image of images.results) {
    await removeIndividualSpacePicture(image.key);
  }
}

export const removeIndividualSpacePicture = async (imageKey) => {
  const removedKey = await Storage.remove(imageKey, { level: "protected" });
  return removedKey;
};
