import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../styles/theme";
import {
  Facebook,
  Twitter,
  Instagram,
  Send,
  LinkedIn,
} from "@mui/icons-material";
import {
  SubscribeTf,
  FooterTitle,
  FooterLogo,
} from "../styles/components/footer";
import { footerMenu } from "../data/index";
import logo from "../images/logo_reversed.png";

const Footer = () => {
  const navigate = useNavigate();
  const handleSubMenuClick = async (item) => {
    const route = item.split(" ")[0].toLowerCase();
    if (route === "faq") {
      await navigate("/host");
      window.scrollTo(0, 1225);
    } else {
      navigate(route);
    }
  };
  return (
    <Box
      sx={{
        background: Colors.black,
        color: "white",
        p: { xs: 4, md: 6 },
        pt: 10,
        pb: 12,
        fontSize: { xs: "12px", md: "15px" },
        fontFamily: "Raleway",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={6} lg={4}>
          <FooterLogo src={logo} alt="footer-logo" />
          <FooterTitle variant="body1">About us</FooterTitle>
          <Box width="90%">
            <Typography variant="caption2">
              Spaced Out is a Canadian based company that provides affordable and
              convienent storage and parking options. We want to provide
              Canadian users with a secure community and comfortable process.
            </Typography>
          </Box>
        </Grid>
        {footerMenu.map(({ title, submenu }) => (
          <Grid item md={6} lg={2} key={title}>
            <FooterTitle variant="body1">{title}</FooterTitle>
            <List>
              {submenu.map((item) => (
                <ListItemText key={item}>
                  <Typography
                    onClick={() => handleSubMenuClick(item)}
                    lineHeight={2}
                    variant="caption2"
                    color="white"
                    fontFamily="Raleway"
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline"
                      },
                    }}
                  >
                    {item}
                  </Typography>
                </ListItemText>
              ))}
            </List>
          </Grid>
        ))}
        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">newsletter</FooterTitle>
          <Stack>
            <SubscribeTf
              color="primary"
              placeholder="Email address"
              variant="outlined"
              InputProps={{
                style: {
                  fontFamily: "Raleway",
                }
              }}
            />
            <Button
              startIcon={<Send sx={{ color: "white" }} />}
              sx={{
                mt: 2,
                mb: 2,
                fontFamily: "Raleway",
                fontWeight: "bold",
                color: "white",
                fontSize: "16px",
              }}
              variant="contained"
            >
              Subscribe
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          mb: 1,
          float: "right",
          color: Colors.secondary,
        }}
      >
        <Facebook sx={{ mr: 1, color: Colors.primary }} />
        <Twitter sx={{ mr: 1, color: Colors.primary }} />
        <LinkedIn sx={{ mr: 1, color: Colors.primary }} />
        <Instagram sx={{ color: Colors.primary }} />
      </Box>
      <Divider
        sx={{ backgroundColor: "white", marginTop: "30px", width: "100%" }}
      />
      <Box
        sx={{
          pt: 1,
          float: "left",
          color: "white",
        }}
      >
        <Typography variant="caption2" float="left">
          © {new Date().getFullYear()} Spaced Out, Inc.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
