import React from "react";
import { Dialog, Container, Divider, Stack, Box } from "@mui/material";
import { CustomButton } from "../styles/global";
import { PopupTitle, PopupText } from "../styles/components/popup";
import { Colors } from "../styles/theme";

const Popup = ({
  show,
  setShow,
  confirmText,
  msg,
  onClose,
  onConfirm,
  title = `Are you sure you want to ${confirmText.toLowerCase()}?`,
  confirmColour = Colors.secondary,
  closeText = "Exit",
  closeColour = "white",
}) => {
  const handleClose = () => {
    onClose && onClose();
    setShow(false);
  }

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setShow(false);
  }
  return (
    <Dialog
      open={show}
      onClose={() => setShow(false)}
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
        elevation: 3,
      }}
      maxWidth="md"
    >
      <Container component="main">
        <Stack display="flex" width="100%" alignItems="center">
          <PopupTitle sx={{ padding: "20px" }}>{title}</PopupTitle>
          <Divider width="100%" color="black" />
        </Stack>
        <Stack
          height="200px"
          width="100%"
          justifyContent="center"
          display="flex"
          textAlign="center"
        >
          <PopupText>{msg}</PopupText>
        </Stack>
        {closeText && confirmText && (
          <Box p={2}>
            <CustomButton
              bg={closeColour}
              c="black"
              width="170px"
              sx={{ marginTop: "0px" }}
              onClick={handleClose}
            >
              {closeText}
            </CustomButton>
            <CustomButton
              bg={confirmColour}
              c="white"
              width="170px"
              sx={{ marginTop: "0px", float: "right" }}
              onClick={handleConfirm}
            >
              {confirmText}
            </CustomButton>
          </Box>
        )}
      </Container>
    </Dialog>
  );
};

export default Popup;
