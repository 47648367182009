import { createTheme } from "@mui/material/styles";

export const Colors = {
  primary: "#58afe2",
  secondary: "#0b3c5d",
  yellow: "#f0c849",
  white: "#fbfaf5",
  black: "#1d2731",
  green: "#cae4d8",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    info: {
      main: Colors.yellow,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
  },
});

export default theme;
