import React from "react";
import { Card, Stack } from "@mui/material";
import { hostCards } from "../../data";
import {
  CardImage,
  CardHeader,
  CardDescription,
} from "../../styles/home/infoCard";

const Cards = () => {
  return (
    <Card sx={{ borderRadius: "20px", padding: "25px" }}>
      <Stack spacing={6}>
        {hostCards.map(({ icon, title, description }, index) => (
          <Stack spacing={1} key={index}>
            <Stack direction="row" spacing={2} alignItems="center">
              <CardImage src={icon} alt="" />
              <CardHeader>{title}</CardHeader>
            </Stack>
            <CardDescription>{description}</CardDescription>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default Cards;
