import React from "react";
import { Link } from "react-router-dom";
import { Stack, TextField } from "@mui/material";
import {
  NewsHelpTitle,
  NewsHelpImage,
  NewsHelpSubtitle,
  BoldedContactUs,
  NotifyButton,
} from "../../styles/search/newsHelp";

import email from "../../images/email.png";
import customer_support from "../../images/customer_support.png";

const NewsHelp = () => {
  return (
    <Stack spacing={3} pt={5} pb={5}>
      <Stack direction="row" spacing={2}>
        <NewsHelpImage sx={{ paddingTop: "10px" }} src={email} />
        <Stack direction="column" spacing={1.5}>
          <NewsHelpTitle>Register for interest</NewsHelpTitle>
          <NewsHelpSubtitle>
            We will notify you whenever new Spaced Out listings are added to
            this area.
          </NewsHelpSubtitle>
          <Stack direction="row" pt={2}>
            <TextField
              placeholder="Email"
              variant="standard"
              size="medium"
              sx={{
                border: 2,
                borderRadius: "28px 0 0 28px",
                borderRight: "2px solid transparent",
                padding: "0px 20px",
                width: "30%",
              }}
              InputProps={{
                style: {
                  fontFamily: "Raleway",
                  fontSize: "16px",
                },
                disableUnderline: true,
              }}
            ></TextField>

            <TextField
              placeholder="Phone (optional)"
              variant="standard"
              sx={{
                border: 2,
                padding: "0px 20px",
                width: "30%",
              }}
              InputProps={{
                style: {
                  fontFamily: "Raleway",
                  fontSize: "16px",
                },
                disableUnderline: true,
              }}
            ></TextField>
            <NotifyButton>Notify Me</NotifyButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2} >
        <NewsHelpImage src={customer_support} />
        <Stack direction="column" spacing={1}>
          <NewsHelpTitle>Need some help?</NewsHelpTitle>
          <NewsHelpSubtitle>
            <Link to="/contact">
              <BoldedContactUs component="span">Contact us</BoldedContactUs>
            </Link>{" "}
            to chat with one of our team members about your requirements. We are
            more than happy to help!
          </NewsHelpSubtitle>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NewsHelp;