import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";


export const RentalDetailsLabel = styled(Typography)(() => ({
    fontSize: "20px",
    fontFamily: "Raleway",
    fontWeight: 600,
}));

export const RentalDetailsLengthOfRentButton = styled(Button)(() => ({
  width: "100%",
  height: "65px",
  fontFamily: "Raleway",
  fontSize: "18px",
  borderRadius: "20px",
  border: "1px solid black",
  padding: "10px",
  textAlign: "start",
  color: "black",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  textTransform: "none",
}));
