import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";

import { updateUserAttribute, verifyAttribute } from "../../lib/amplify";

import FormTextInput from "../../components/FormTextInput";
import { FormPhoneInput } from "../../components/CustomFormTextInput";
import ConfirmAttrChange from "../../components/ConfirmAttrChange";

import { BookCard, BookHelperText, BookSubtitle } from "../../styles/book";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

const AccountDetails = ({ phone_number, phone_number_verified, methods }) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const handleChangePhone = async (e) => {
    e.preventDefault();
    if (methods.getValues("phone_number") === phone_number) return;
  
    await updateUserAttribute({
      phone_number: methods.getValues("phone_number"),
    });

    const error = await verifyAttribute("phone_number", dispatch);
    if (!error) setShow(true);
  };
  return (
    <BookCard>
      <Stack spacing={3}>
        <Stack>
          <BookSubtitle>Account Details</BookSubtitle>
          <BookHelperText>Confirm some details before booking</BookHelperText>
        </Stack>
        <FormTextInput
          name="first_name"
          label="First Name"
          placeholder="Enter your first name"
        />
        <FormPhoneInput phone_number_verified={phone_number_verified} />
        <CustomButton
          bg={Colors.secondary}
          c="white"
          onClick={handleChangePhone}
        >
          Update number
        </CustomButton>
        <ConfirmAttrChange show={show} setShow={setShow} type="phone_number" />
      </Stack>
    </BookCard>
  );
};

export default AccountDetails;
