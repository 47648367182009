import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, IconButton, Avatar } from "@mui/material";

import FormTextInput from '../../components/FormTextInput';

import { BookCard, BookHelperText, BookSubtitle } from "../../styles/book";


const HostDetails = ({ hostName, hostId }) => {
  const navigate = useNavigate();

  return (
    <BookCard>
      <Stack width="100%" spacing={3}>
        <Stack direction="row">
          <Stack width="70%">
            <BookSubtitle>Meet your host, {hostName}</BookSubtitle>
            <BookHelperText>
              Describe a bit about your stay to your host
            </BookHelperText>
          </Stack>
          <IconButton
            sx={{ width: 72, height: 72, marginLeft: "auto" }}
            onClick={() => navigate(`/profile?id=${hostId}`)}
          >
            <Avatar
              sx={{
                width: 62,
                height: 62,
                bgcolor: "primary.main",
                fontSize: "30px",
              }}
            >
              {hostName[0]}
            </Avatar>
          </IconButton>
        </Stack>

        <FormTextInput
          name="message"
          placeholder="Send a friendly message to your host"
        />
      </Stack>
    </BookCard>
  );
};

export default HostDetails;
