import { styled } from "@mui/material/styles";
import { Box, Typography, Button, Stack } from "@mui/material";
import { Colors } from "../theme";

export const SpacesContainer = styled(Box)(() => ({
  width: "150px",
}));

export const SpacesImage = styled("img")(() => ({
  width: "100%",
  "&:hover": {
    filter: "drop-shadow(3px 3px 3px #222)",
  },
}));

export const SpacesTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  marginTop: "3px",
  fontWeight: 600,
  fontSize: "20px",
}));

export const MoneyBarContainer = styled(Stack)(() => ({
  width: "40%",
  height: "100%",
}));

export const MoneyBarTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "20px",
  paddingLeft: "15rem",
}));

export const MoneySliderText = styled(Typography)(() => ({
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "3rem",
  color: Colors.secondary,
  marginLeft: "auto",
}));

export const MoneySliderButton = styled(Button)(() => ({
  backgroundColor: "white",
  position: "absolute",
  paddingLeft: "35rem",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "white",
  },
}));

export const MoneySliderBackground = styled(Button)(() => ({
  backgroundColor: Colors.primary,
  position: "absolute",
  paddingLeft: "35rem",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: Colors.primary,
  },
}));

export const MoneyBarBannerContent = styled(Box)(() => ({
  alignItems: "center",
  width: '50%',
  paddingRight: '20px',
  textAlign: 'left',
}));

export const MoneyBarBannerTitle = styled(Typography)(() => ({
  lineHeight: 1.15,
  display: "inline-block",
  fontWeight: 700,
  fontSize: "50px",
  color: Colors.secondary,
  fontFamily: "Raleway",
  marginBottom: "20px",
}));

export const MoneyBarBannerSubtitle = styled(Typography)(() => ({
  lineHeight: 1.15,
  letterSpacing: 1.15,
  color: "black",
  fontSize: "20px",
  fontFamily: "Raleway",
  marginBottom: "3em",
}));
