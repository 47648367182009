import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { CustomCard } from "../global";

export const BookHeader = styled(Box)(() => ({
  padding: "35px",
  alignItems: "center",
  display: "flex",
}));

export const BookTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "35px",
  fontWeight: "bold",
}));

export const BookSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "25px",
  fontWeight: 600,
}));

export const BookHelperText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  color: "gray",
  fontSize: "16px",
}));

export const BookAgreementText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
}));

export const BookAgreementLinkText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  display: "inline-block",
  fontWeight: "bold",
  textDecoration: "underline",
  fontSize: "16px",
}));

export const BookCard = styled(CustomCard)(() => ({
  padding: "30px",
}));
