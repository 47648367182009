import React from "react";
import {
  HomeBannerContainer,
  HomeBannerImage,
  HomeBannerContent,
  HomeBannerTitle,
  HomeBannerDescription,
} from "../../styles/home/banner";
import banner from "../../images/homeBanner.png";
import SearchBar from '../../components/SearchBar';

const Banner = () => {
  return (
    <HomeBannerContainer>
      <HomeBannerImage image={banner} />
      <HomeBannerContent>
        <HomeBannerTitle variant="h3">
          Affordable storage in your neighbourhood
        </HomeBannerTitle>
        <HomeBannerDescription variant="subtitle">
          Discover convienent storage and parking spots nearby
        </HomeBannerDescription>
        <SearchBar width="100%" variant="home" />
      </HomeBannerContent>
    </HomeBannerContainer>
  );
};

export default Banner;
