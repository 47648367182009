import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";

import { faq } from "../../data";
import { FaqQuestion, FaqAnswer } from "../../styles/host/faq";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";
import useCustomRoute from '../../hooks/useCustomRoute';

const FAQ = () => {
  const reroute = useCustomRoute({ path: "/create" });
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Stack width="100%" alignItems="center" pt="50px" spacing={5}>
      <Card sx={{ borderRadius: "20px", padding: "25px", width: "75%" }}>
        {faq.map(({ question, answer }, i) => (
          <Stack spacing={2} pb={faq.length - 1 !== i && 2} key={i}>
            <Accordion
              expanded={expanded === i}
              onChange={handleChange(i)}
              style={{ boxShadow: "none" }}
            >
              <AccordionSummary
                expandIcon={expanded === i ? <Close /> : <Add />}
              >
                <FaqQuestion sx={{ flexShrink: 0 }}>{question}</FaqQuestion>
              </AccordionSummary>
              <AccordionDetails>
                <FaqAnswer>{answer}</FaqAnswer>
              </AccordionDetails>
            </Accordion>
            {faq.length - 1 !== i && <Divider width="100%" color="gray" />}
          </Stack>
        ))}
        <FaqAnswer sx={{ pt: 3, pl: 2, fontSize: "16px" }}>
          Have more questions? Contact us <a href="/contact">here</a>
        </FaqAnswer>
      </Card>
      <Box alignItems="center">
        <CustomButton
          c="black"
          bg={Colors.green}
          size="wide"
          onClick={() => reroute("/create")}
        >
          Start Listing
        </CustomButton>
      </Box>
    </Stack>
  );
};

export default FAQ;
