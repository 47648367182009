import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const DashboardPanelTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "40px",
  fontWeight: 800,
}));

export const DashboardPanelCardTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "30px",
  fontWeight: 600,
}));

export const DashboardPanelCardSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  fontWeight: 500,
}));

export const DashboardPanelCardStats = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "45px",
  fontWeight: "bold",
}));
