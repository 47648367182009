import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const SpaceName = styled(Typography)(() => ({
  fontSize: "35px",
  fontFamily: "Raleway",
  fontWeight: 600,
}));

export const SpaceDimension = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "Noto Sans",
  fontWeight: "bold",
}));

export const SpaceLocation = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "Raleway",
  display: "inline-block",
  paddingLeft: "20px",
}));

export const SpaceSection = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "Raleway",
  fontWeight: 600,
}));

export const SpaceDescription = styled(Typography)(() => ({
  fontSize: "20px",
  fontFamily: "Raleway",
}));
