import React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontFamily: "Raleway",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const CustomTable = ({ rows, history }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 400,
      }}
    >
      <Table
        sx={{ minWidth: "100%", height: "max-content" }}
        aria-label="customized table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Listing</StyledTableCell>
            <StyledTableCell align="right">{history ? "Date" : "Renter"}</StyledTableCell>
            <StyledTableCell align="right">
              Amount&nbsp;($)
            </StyledTableCell>
          </TableRow>
        </TableHead>
        {rows && rows.length !== 0 ? (
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell component="th" scope="row">
                {history ? row.name : row.type}
              </StyledTableCell>
              <StyledTableCell align="right">{history ? row.date : row.renter}</StyledTableCell>
              <StyledTableCell align="right">{history ? row.amount : row.rate}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>)
        : (
          <Box width="100%" sx={{paddingLeft: "50%", paddingTop: "30px"}}>
              <Typography sx={{fontFamily: "Raleway", color: "grey", fontSize: "18px"}}>
                Looks like there's no data right now
              </Typography>
          </Box>
        )}
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
