import React from "react";
import { Card, Stack } from "@mui/material";
import { CardHeader, CardDescription, CardImage } from "../../styles/home/infoCard";

const InfoCard = ({ title, description, icon, ...other }) => {
  return (
    <Card sx={{ borderRadius: "20px", padding: "25px", ...other }}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <CardImage src={icon} alt="" />
          <CardHeader>{title}</CardHeader>
        </Stack>
        <CardDescription>{description}</CardDescription>
      </Stack>
    </Card>
  );
};

export default InfoCard;
