import React from "react";

import { BookCard } from "../../styles/book";
import Secure from '../space/Secure';

const Protection = () => {
  return (
    <BookCard>
      <Secure fontSize="18px" iconSize={30}/>
    </BookCard>
  );
};

export default Protection;
