import React from "react";
import { Container } from "@mui/material";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <>
      {value === index && (
        <Container
          sx={{ p: 5 }}
          display="flex"
          width="100%"
          height="100%"
          maxWidth="xl"
          {...other}
        >
          {children}
        </Container>
      )}
    </>
  );
};

export default TabPanel;
