import { backendApi } from "../backendApi";

const usersApi = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation({
      query({ id, pub }) {
        return {
          url: pub ? `/users/${id}?public=false` : `/users/${id}?public=false`,
          method: "GET",
        };
      },
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query(body) {
        return {
          url: `/users/create`,
          method: "POST",
          body: body,
        };
      },
    }),
    updateUser: builder.mutation({
      query(body) {
        return {
          url: `/users/update`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["User"],
    }),
    setIdentityId: builder.mutation({
      query(body) {
        return {
          url: `/users/setIdentityId`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useSetIdentityIdMutation,
} = usersApi;
