import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";


export const FaqQuestion = styled(Typography)(() => ({
    lineHeight: 1.15,
    fontWeight: 600,
    fontSize: "25px",
    fontFamily: "Raleway",
}));

export const FaqAnswer = styled(Typography)(() => ({
    lineHeight: 1.15,
    letterSpacing: 1.15,
    color: "black",
    fontSize: '20px',
    fontFamily: "Raleway",
}));