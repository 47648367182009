import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";
import { MAPS_API_KEY } from "../../lib/constants";
import { setListingLocation } from "../../store/slices/listingSlice";
import { getListing } from "../../store/selectors/listingSelector";
import "./listingLocation.css";


const ListingLocation = () => {
  let map = useRef();
  const dispatch = useDispatch();
  const listing = useSelector(getListing);

  useEffect(() => {
    mapboxgl.accessToken = MAPS_API_KEY;
    map.current = new mapboxgl.Map({
      container: "minimap",
      style: "mapbox://styles/spacedout/clec7k6c7000401mrj0gz9q42",
      center: listing.lat && listing.lng ? [listing.lat, listing.lng] : [-79.4512, 43.6568],
      zoom: 10,
    });
    map.current.scrollZoom.setZoomRate(1 / 2000);

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      minLength: 3,
      countries: "ca,us",
      placeholder: "Enter an address",
      
    });

    geocoder.on("result", (e) => {
      let city;
      let province;
      const coord = e.result?.center;
      const address = e.result?.place_name;
      const context = e.result?.context;
      for (const attr of context) {
        if (attr.id.includes("place")) {
          city = attr.text;
        }
        if (attr.id.includes("region")) {
          province = attr.text;
        }
      }
      const location = `${city}, ${province}`;
      if (coord && address && location) {
        dispatch(
          setListingLocation({
            lat: coord[0],
            lng: coord[1],
            location: location,
            address: address,
          })
        );
      }
    });

    geocoder.on("clear", () => {
      dispatch(
        setListingLocation({
          lat: undefined,
          lng: undefined,
          location: undefined,
          address: undefined,
        })
      );
    });

    const geocoderElement = document.getElementById("geocoder");
    geocoderElement.appendChild(geocoder.onAdd(map.current));
      
    if (listing.address) {
      geocoder.query(listing.address);
    }
  // eslint-disable-next-line
  }, []);
  return (
    <Stack spacing={4} width="100%" alignItems="center">
      <div id="geocoder" />
      <div id="minimap" style={{ height: "400px", width: "1000px" }} />
    </Stack>
  );
};

export default ListingLocation;
