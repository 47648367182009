import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import { Box, Alert } from "@mui/material";
import { Code } from "@mui/icons-material";

import FormTextInput from "../../components/FormTextInput";
import { FormPasswordInput } from "../../components/CustomFormTextInput";

import { resetPassword, forgotPassword } from "../../lib/amplify";

import {
  SignInSubmitButton,
  SignInTitle,
  SignInUnderlinedButton,
  SignInSubtitle,
} from "../../styles/signIn";
import { Colors } from "../../styles/theme";


const ResetPassword = ({ email, handleSwitchScreen }) => {
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const methods = useForm({
    defaultValues: {
      email: email,
      password: "",
      code: "",
    },
  });

  const handleSubmit = async (data) => {
    const { email, password, code } = data;
    const submitError = await resetPassword(email, code, password);
    if (submitError) {
      return setError(submitError);
    }
    handleSwitchScreen(undefined, "resetSuccess")
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    await forgotPassword(email);
    setSuccess("Resent verification code. Please check your inbox.");
    setError(false);
  };

  const handleReset = () => {
    setError(false);
    setSuccess(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        onChange={handleReset}
        style={{ width: "550px" }}
      >
        <SignInTitle>Reset password</SignInTitle>
        <SignInSubtitle>
          Check your inbox for your verification code. If you don't see your
          code in your inbox you can try resending it below
        </SignInSubtitle>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {error}
          </Alert>
        )}
        {success && (
          <Alert
            variant="outlined"
            severity="success"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {success}
          </Alert>
        )}

        <Box sx={{ mt: 1 }}>
          <FormTextInput
            name="code"
            placeholder="Enter your verfication code"
            startInput={<Code />}
          />
          <FormPasswordInput />

          <SignInSubmitButton type="submit" bg={Colors.secondary}>
            Reset password
          </SignInSubmitButton>
          <SignInUnderlinedButton
            onClick={handleResendCode}
            sx={{ float: "left", mt: 3 }}
          >
            Resend verification code
          </SignInUnderlinedButton>
        </Box>
      </form>
    </FormProvider>
  );
};

export default ResetPassword;
