import { styled } from "@mui/material/styles";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { CustomButton } from "../global";

export const SignInCloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 15,
  top: 15,
}));

export const SignInBackButton = styled(Button)(() => ({
  position: "absolute",
  fontFamily: "Raleway",
  fontSize: "20px",
  textTransform: "none",
  color: "black",
  left: 15,
  top: 15,
}));

export const SignInStyledContainer = styled(Box)(() => ({
  marginTop: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const SignInLogoImage = styled("img")(() => ({
  marginBottom: 10,
  marginTop: 10,
  width: "70px",
}));

export const SignInTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "bold",
  fontSize: "35px",
  textAlign: "center",
}));

export const SignInSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
  color: "gray",
  textAlign: "center",
  marginBottom: "10px"
}));

export const SignInUnderlinedButton = styled(Button)(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
  color: "black",
  textDecoration: "underline",
  textTransform: "none",
  float: "right",
}));

export const SignInSubmitButton = styled(CustomButton)(() => ({
  width: "100%",
  color: "white",
  mb: 2,
  border: 0,
}));

export const SignInCopyrightText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  color: "text.secondary",
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",
}));
