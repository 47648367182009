import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const backendApi = createApi({
  reducerPath: "backendApi",
  tagTypes: ["User", "Spaces"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      if (state.app.accessToken) {
        headers.set("Authorization", state.app.accessToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({})
});
