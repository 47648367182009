import { styled } from "@mui/material/styles";
import { Box, Button, Input, Typography, Paper } from "@mui/material";
import { Colors } from "../theme";

export const ListingImagesContainer = styled(Button)(() => ({
  width: "700px",
  height: "400px",
  border: `2px dashed ${Colors.primary}`,
  borderRadius: "20px",
  display: "flex",
  padding: "30px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f5f8ff",
  "&:hover": {
    backgroundColor: "#f5f8ff",
  },
}));

export const ListingImagesInput = styled(Input)(() => ({
  display: "none",
}));

export const ListingImagesInputLabel = styled(Box)(() => ({
  textAlign: "center",
}));

export const ListingImagesUploadImage = styled("img")(() => ({
  width: "100%",
}));

export const ListingImagesInputText = styled(Typography)(() => ({
  color: Colors.secondary,
  fontWeight: 600,
  padding: "10px",
  fontSize: "30px",
  fontFamily: "Raleway",
  textTransform: "none",
}));

export const ListingImagesPaper = styled(Paper)(() => ({
  p: 2,
  height: "150px",
  width: "300px",
  borderRadius: "20px",
  border: "2px solid lightgrey",
  backgroundColor: "white",
  position: "relative",
}));

export const ListingImagesButton = styled(Button)(() => ({
  p: 2,
  height: "150px",
  width: "300px",
  borderRadius: "20px",
  border: `2px dashed ${Colors.primary}`,
  backgroundColor: "white",
  position: "relative",
}));

export const ListingImagesUserImage = styled("img")(() => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "inherit",
}));

export const ListingImagesAddMoreText = styled(Typography)(() => ({
  fontWeight: 600,
  padding: "10px",
  fontSize: "20px",
  fontFamily: "Raleway",
  textTransform: "none",
}));
