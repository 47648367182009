import React from "react";

import white_marker from "../../images/white_marker.png";
import blue_marker from "../../images/blue_marker.png";
import yellow_marker from "../../images/yellow_marker.png";
import { Typography, Box } from "@mui/material";

const Marker = ({ super_host, rate, width, length, hover }) => {
  return (
    <Box
      position="relative"
      width="90px"
      sx={{
        "&:hover": {
          filter: "drop-shadow(2px 2px 2px #222)",
        },
      }}
    >
      <img
        src={hover ? blue_marker : super_host ? yellow_marker : white_marker}
        width="90px"
        alt=""
      />
      <Typography
        position="absolute"
        textAlign="center"
        top="8%"
        left="17%"
        fontFamily="Raleway"
        fontSize="15px"
        fontWeight={600}
      >
        ${rate}/wk
        <br />
        {width} x {length}
      </Typography>
    </Box>
  );
};

export default Marker;
