import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const RentalTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: "Raleway",
  fontSize: "25px",
}));

export const RentalButton = styled(Button)(({c}) => ({
  fontSize: "20px",
  backgroundColor: "white",
  borderRadius: "25px",
  color: c,
  padding: "10px 20px 10px 20px",
  border: `1.5px solid ${c}`,
  "&:hover": {
    backgroundColor: "white",
    filter: "drop-shadow(2px 2px 2px #222)",
  },
  textTransform: "none",
}));

export const RentalStatus = styled(Button)(({ c }) => ({
  fontSize: "14px",
  backgroundColor: "white",
  borderRadius: "25px",
  fontWeight: 600,
  width: "175px",
  color: c,
  border: `1.5px solid ${c}`,
  "&:disabled": {
    color: c,
  },
}));

export const RentalImage = styled("img")(() => ({
  height: "230px",
  width: "100%",
  objectFit: "cover",
}));

export const RentalSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "18px",
}));

export const RentalDate = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "18px",
}));
