import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";

import { useUpdateUserMutation } from "../../store/api/users";
import { setGlobalError, setGlobalSuccess } from "../../store/slices/appSlice";

import FormTextInput from "../../components/FormTextInput";
import Loading from "../../components/Loading";

import { AccountSubtitle } from "../../styles/dashboard/account";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";

const PublicInfo = ({ first_name, last_name, biography, auth_id }) => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      biography: "",
    },
  });

  const handleSubmit = async (data) => {
    if (
      data.first_name === first_name &&
      data.last_name === last_name &&
      data.biography === biography
    )
      return;

    try {
      await updateUser({ auth_id: auth_id, ...data }).unwrap();
      dispatch(setGlobalSuccess("Your user details were updated!"));
    } catch (error) {
      dispatch(
        setGlobalError(
          "There was an issue with updating your user. Please try again later."
        )
      );
    }
  };

  useEffect(() => {
    methods.reset({
      first_name: first_name,
      last_name: last_name,
      biography: biography,
    });
  }, [methods, first_name, last_name, biography]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        style={{ width: "100%" }}
      >
        <Stack width="100%" spacing={3}>
          <AccountSubtitle>Public Information</AccountSubtitle>
          <Stack direction="row" spacing={3}>
            <FormTextInput
              name="first_name"
              placeholder="Enter your first name"
              label="First Name"
            />
            <FormTextInput
              name="last_name"
              placeholder="Enter your last name"
              label="Last Name"
            />
          </Stack>
          <FormTextInput
            name="biography"
            placeholder="Enter a little bit about yourself"
            label="About yourself"
            rules={{
              maxLength: {
                value: 200,
                message: "Biography can only be a maximum of 200 characters",
              },
            }}
            required={false}
          />

          <CustomButton bg={Colors.secondary} c="white" type="submit">
            {isLoading ? <Loading /> : "Save Changes"}
          </CustomButton>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default PublicInfo;
