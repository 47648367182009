import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { changeHoveredListing } from "../store/slices/listingSlice";
import {
  ListingContainer,
  ListingImage,
  ListingPriceBox,
  ListingRate,
  ListingLocation,
  ListingName,
  ListingDimension,
  SuperhostButton,
} from "../styles/components/listing";

const Listing = ({ listing, hover, _width, ...other }) => {
  const {
    _id,
    name,
    rate,
    width,
    length,
    location,
    image,
    super_host,
  } = listing;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/space/" + _id);
  };
  return (
    <ListingContainer width={_width} {...other}>
      {hover ? (
        <ListingImage
          src={image}
          alt=""
          key={_id}
          width={_width}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
          onMouseEnter={(e) => dispatch(changeHoveredListing(_id))}
          onMouseLeave={(e) => dispatch(changeHoveredListing(_id))}
        />
      ) : (
        <ListingImage
          src={image}
          key={_id}
          alt=""
          width={_width}
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        />
      )}

      <Box paddingLeft="5px">
        <ListingPriceBox
          sh={super_host ? super_host.toString() : "false"}
          width={_width}
        >
          <ListingRate
            variant="h3"
            sh={super_host ? super_host.toString() : "false"}
          >
            ${rate}/wk
          </ListingRate>
        </ListingPriceBox>
        {super_host && <SuperhostButton>SUPERHOST</SuperhostButton>}
        <Stack direction="row" spacing={1} alignItems="center">
          <ListingDimension variant="h2">
            {width} x {length}
          </ListingDimension>
          <ListingDimension variant="h2">•</ListingDimension>
          <ListingLocation>{location}</ListingLocation>
        </Stack>

        <ListingName>{name}</ListingName>
      </Box>
    </ListingContainer>
  );
};

export default Listing;
