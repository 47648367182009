import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { Colors } from "../theme";

export const OptionButton = styled(Button)(({ clicked, width, height }) => ({
  color: Colors.black,
  border:
    clicked === "true" ? `3px solid ${Colors.primary}` : "0.5px solid grey",
  display: "inline-block",
  justifyContent: "center",
  alignItems: "center",
  width: `${width}px`,
  height: `${height}px`,
  borderRadius: "25px",
  boxShadow: clicked !== "true" && "5px 5px 5px lightgrey",
  padding: "10px",
  textTransform: "none",
  "&:hover": {
    border:
      clicked === "true"
        ? `3px solid ${Colors.primary}`
        : `3px solid ${Colors.black}`,
  },
  "&:disabled": {
    color: Colors.black,
  },
}));

export const OptionImage = styled("img")(({ size }) => ({
  width: `${size / 2}px`,
}));

export const OptionTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "16px",
}));
