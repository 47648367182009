import React, { useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { Stack, Avatar } from "@mui/material";
import {
  AccountCircle,
  MilitaryTech,
  ErrorOutlined,
} from "@mui/icons-material";

import Loading from '../../components/Loading';

import { changeProfilePicture } from "../../lib/amplify";
import { refetchUser } from "../../store/slices/appSlice";

import {
  ChangePictureButton,
  AccountSubtitle,
  AccountLabel,
  AccountText,
  AccountHelper,
} from "../../styles/dashboard/account";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";


const ProfileInfo = ({ first_name, super_host, profile_picture, auth_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const handleClick = () => {
    wrapperRef.current.click();
  };

  const handleViewProfile = (e) => {
    e.preventDefault();
    navigate(`/profile?id=${auth_id}`)
  }

  const handleChangePicture = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      setIsLoading(false);
      return;
    }
    const file = URL.createObjectURL(fileObj);
    await changeProfilePicture(file)
    dispatch(refetchUser()); // notify to re-fetch picture
    setIsLoading(false);
  };
  
  return (
    <Stack spacing={3}>
      <AccountSubtitle>Profile Information</AccountSubtitle>
      <Stack direction="row" width="100%">
        <Stack spacing={3}>
          <Stack spacing={1} width="55%">
            <AccountLabel>Superhost Status</AccountLabel>
            <AccountHelper>
              We don't have clear requirements for a superhost but this is coming soon!
              For now a team member will determine your status.
            </AccountHelper>
            {super_host ? (
              <Stack direction="row" spacing={2}>
                <MilitaryTech fontSize="large" sx={{ color: "green" }} />
                <AccountText>
                  Congrats! Looks like you are a super host!
                </AccountText>
              </Stack>
            ) : (
              <Stack direction="row" spacing={2}>
                <ErrorOutlined fontSize="large" sx={{ color: "red" }} />
                <AccountText>
                  Unfortunately you are not a super host.
                </AccountText>
              </Stack>
            )}
          </Stack>
          <CustomButton
            c="white"
            bg={Colors.secondary}
            startIcon={<AccountCircle />}
            onClick={handleViewProfile}
          >
            View profile
          </CustomButton>
        </Stack>
        <Stack
          width="25%"
          alignItems="center"
          spacing={3}
          sx={{ marginLeft: "auto" }}
        >
          <Avatar
            sx={{
              width: 150,
              height: 150,
              fontSize: "30px",
              bgcolor: "primary.main",
            }}
            src={profile_picture}
          >
            {first_name[0]}
          </Avatar>
          <ChangePictureButton onClick={handleClick} disabled={isLoading}>
            {isLoading ? <Loading /> : "Change picture"}
          </ChangePictureButton>
          <input
            type="file"
            accept="image/*"
            ref={wrapperRef}
            style={{ display: "none" }}
            onChange={handleChangePicture}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileInfo;
