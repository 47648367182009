import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack } from "@mui/material";

import FormTextInput from "../../components/FormTextInput";
import ListingOptionCard from "../../components/ListingOptionCard";
import { getListing } from "../../store/selectors/listingSelector";
import {
  setListingAccessFrequency,
  setListingAccessHours,
  setListingAppointment,
  setListingDescription
} from "../../store/slices/listingSlice";
import { ListingDetailsSubtitle } from "../../styles/createListing/listingDetails";
import {
  accessAppointmentList,
  accessFrequencyList,
  accessHoursList,
} from "../../data/index";

const ListingDetails2 = () => {
  const dispatch = useDispatch();
  const savedListing = useSelector(getListing);

  const handleAccessHours = (title) => {
    dispatch(setListingAccessHours(title));
  };

  const handleAccessFrequency = (title) => {
    dispatch(setListingAccessFrequency(title));
  };

  const handleAppointment = (title) => {
    dispatch(setListingAppointment(title));
  };

  const handleDescriptionChange = (e) => {
    dispatch(setListingDescription(e.target.value));
  }

  const accessLists = [
    {
      title: "Access Frequency",
      list: accessFrequencyList,
      value: savedListing.accessFrequency,
      func: handleAccessFrequency,
    },
    {
      title: "Access Hours",
      list: accessHoursList,
      value: savedListing.accessHours,
      func: handleAccessHours,
    },
    {
      title: "Appointment",
      list: accessAppointmentList,
      value: savedListing.appointmentRequired,
      func: handleAppointment,
    },
  ];
  return (
    <Stack spacing={5}>
      <Stack spacing={2}>
        <ListingDetailsSubtitle sx={{ pt: 2 }}>
          Description
        </ListingDetailsSubtitle>
        <FormTextInput
          name="description"
          height={90}
          multiline
          maxRows={3}
          value={savedListing.description}
          onChange={handleDescriptionChange}
          placeholder="Enter a description about your space"
        />
      </Stack>
      <Stack spacing={2}>
        {accessLists.map((access, i) => (
          <Stack spacing={3} key={i}>
            <ListingDetailsSubtitle>{access.title}</ListingDetailsSubtitle>
            <Stack direction="row" spacing={3} justifyContent="center">
              {access.list.map((option) => (
                <ListingOptionCard
                  key={option}
                  option={{ title: option }}
                  handleOptionClick={access.func}
                  clicked={access.value === option}
                  width={250}
                  height={70}
                />
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default ListingDetails2;
