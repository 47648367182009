import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ListingOptionCard from "../../components/ListingOptionCard";
import { allOptions } from "../../data/index";
import { setListingType } from "../../store/slices/listingSlice";
import { getListing } from "../../store/selectors/listingSelector";

const ListingType = () => {
  const dispatch = useDispatch();
  const savedType = useSelector(getListing).listing_type;
  const handleOptionClick = (type) => {
    dispatch(setListingType(type));
  };
  return (
    <Grid container rowSpacing={3} columnSpacing={1}>
      {allOptions.map((option) => (
        <Grid item xs={3} key={option.title}>
          <ListingOptionCard
            key={option.title}
            option={option}
            handleOptionClick={handleOptionClick}
            clicked={savedType === option.title}
            width={250}
            height={125}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ListingType;
