import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from 'react-router-dom';

import { getProfilePicture } from "../lib/amplify";

import { useGetUserMutation } from "../store/api/users";
import { userState } from "../store/states";
import { getRefetchUser } from "../store/selectors/appSelector";


const useGetUser = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [isLoading, setIsLoading] = useState(true);
  const refetchUser = useSelector(getRefetchUser)
  const [user, setUser] = useState(userState);
  const [getUser] = useGetUserMutation();

  useEffect(() => {
    const callback = async () => {
      let userData = {};
      try {
        userData = await getUser({ id: id, pub: true }).unwrap();
      } catch (error) {
        setIsLoading(false);
        return error;
      }
      const signedUrl = await getProfilePicture(userData?.identity_id);
      setUser(Object.assign({}, userData, { profile_picture: signedUrl }));
      setIsLoading(false);
    };
    callback();
  }, [getUser, refetchUser, id]);

  return [user, setUser, isLoading];
};

export default useGetUser;
