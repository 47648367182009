import { createSlice } from "@reduxjs/toolkit";
import { removeFromList } from "../../lib/utils";
import { listingState } from "../states";
import { statusMappedValues } from '../../data/index';


const initialState = {
  hoveredListings: [],
  listing: listingState,
};

const listingSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    setHoveredListings(state, action) {
      action.payload.map((listing) => {
        state.hoveredListings.push({
          ...listing,
          hover: false,
        });
        return 0;
      });
    },
    changeHoveredListing(state, action) {
      const matchingListing = state.hoveredListings.find(
        (listing) => listing.id === action.payload
      );

      if (matchingListing) {
        matchingListing.hover = !matchingListing.hover;
      }
    },
    setListingType(state, action) {
      state.listing.listing_type = action.payload;
    },
    setListingLocation(state, action) {
      state.listing.lng = action.payload.lng;
      state.listing.lat = action.payload.lat;
      if (action.payload.location && action.payload.address) {
        state.listing.location = action.payload.location;
        state.listing.address = action.payload.address;
      }
    },
    setListingDimension(state, action) {
      if (action.payload.width) {
        state.listing.width = action.payload.width;
      }
      if (action.payload.length) {
        state.listing.length = action.payload.length;
      }
    },
    setListingFeature(state, action) {
      if (state.listing.features.includes(action.payload)) {
        removeFromList(action.payload, state.listing.features);
      } else {
        state.listing.features.push(action.payload);
      }
    },
    setListingDescription(state, action) {
      state.listing.description = action.payload;
    },
    setListingAccessHours(state, action) {
      state.listing.accessHours = action.payload;
    },
    setListingAccessFrequency(state, action) {
      state.listing.accessFrequency = action.payload;
    },
    setListingAppointment(state, action) {
      state.listing.appointmentRequired = action.payload;
    },
    setListingImage(state, action) {
      if (state.listing.images.find(image => image.path === action.payload.path)) {
        removeFromList(action.payload, state.listing.images);
      } else {
        state.listing.images.push(action.payload);
      }
    },
    setListingRate(state, action) {
      state.listing.rate = action.payload;
    },
    setNewListing(state, action) {
      state.listing = listingState;
    },
    setActiveListing(state, action) {
      state.listing = action.payload;
      state.listing.status = statusMappedValues[state.listing.status];
    },
    setActiveStep(state, action) {
      state.listing.activeStep = action.payload;
    },
    setStatus(state, action) {
      state.listing.status = action.payload;
    },
    submitListing(state, action) {},
  },
});

export const {
  setHoveredListings,
  changeHoveredListing,
  setListingType,
  setListingLocation,
  setListingDimension,
  setListingFeature,
  setListingDescription,
  setListingAccessHours,
  setListingAccessFrequency,
  setListingAppointment,
  setListingImage,
  setListingRate,
  setNewListing,
  setActiveListing,
  setActiveStep,
  setStatus
} = listingSlice.actions;
export default listingSlice.reducer;
