import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Stack, Divider, IconButton, Avatar, Box } from "@mui/material";
import { House, Clear } from "@mui/icons-material";
import Popup from "../../components/Popup";
import { spaceRentedDetails } from "../../data/fake/information";
import { CustomButton } from "../../styles/global";
import {
  ListingDrawerTitle,
  ListingDrawerSubtitle,
  ListingDrawerContent,
  ListingDrawerImage,
  ListingDrawerViewListingButton,
} from "../../styles/dashboard/listingDrawer";

const ListingDrawer = ({ open, setOpen, id, images }) => {
  const {
    renterName,
    renterNumber,
    renterId,
    moveInDate,
    approxLengthOfRent,
    nextPayDate,
    itemsToStore,
    amountReceivedToDate,
  } = spaceRentedDetails;
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleRemoveRenter = () => {
    setShowPopup(true);
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        style: {
          width: "700px",
        },
      }}
    >
      <Popup
        show={showPopup}
        setShow={setShowPopup}
        confirmText="Remove Renter"
        confirmColour="red"
        onConfirm={() => setOpen(false)}
        msg={
          `This action cannot be undone. Once you remove ${renterName} as a renter you will no longer ` +
          "receive rental payments. They will have a 7 day grace period to remove their items from your space."
        }
      />
      <Stack direction="row" width="100%" alignItems="center">
        <ListingDrawerTitle>{renterName}'s Rental Details </ListingDrawerTitle>
        <Box marginLeft="auto" paddingRight="20px">
          <IconButton onClick={() => setOpen(false)}>
            <Clear
              sx={{
                color: "black",
                fontSize: "30px",
              }}
            />
          </IconButton>
        </Box>
      </Stack>
      <Stack spacing={3}>
        {images && images.length > 0 && (
          <ListingDrawerImage src={images[0]} alt="" />
        )}

        <ListingDrawerViewListingButton
          onClick={() => navigate(`/space/${id}`)}
          startIcon={<House sx={{ fontSize: 20 }} />}
        >
          View Your Listing
        </ListingDrawerViewListingButton>

        <Stack
          direction="row"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={120}
          spacing={10}
        >
          <Stack spacing={2}>
            <ListingDrawerSubtitle>Move-In Date:</ListingDrawerSubtitle>
            <ListingDrawerContent>{moveInDate}</ListingDrawerContent>
          </Stack>
          <Divider height="100%" orientation="vertical" color="gray" />
          <Stack spacing={2}>
            <ListingDrawerSubtitle>Length of Rent:</ListingDrawerSubtitle>
            <ListingDrawerContent>{approxLengthOfRent}</ListingDrawerContent>
          </Stack>
        </Stack>
        <Divider width="100%" color="gray" />
        <ListingDrawerContent sx={{ paddingLeft: "20px", fontSize: "18px" }}>
          IMPORTANT: The length of rent is subject to change, it is just an
          approximate.
        </ListingDrawerContent>
        <ListingDrawerTitle>Additional Details</ListingDrawerTitle>
        <Stack spacing={1} paddingLeft="20px">
          <ListingDrawerSubtitle>Next Pay Date</ListingDrawerSubtitle>
          <ListingDrawerContent>{nextPayDate}</ListingDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <ListingDrawerSubtitle>Amount Recieved To Date</ListingDrawerSubtitle>
          <ListingDrawerContent>${amountReceivedToDate}</ListingDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <ListingDrawerSubtitle>Items Stored in Unit</ListingDrawerSubtitle>
          <ListingDrawerContent>{itemsToStore}</ListingDrawerContent>
        </Stack>
        <Divider width="100%" color="gray" />
        <Stack spacing={1} paddingLeft="20px">
          <ListingDrawerSubtitle>
            Who is renting your space?
          </ListingDrawerSubtitle>
          <Stack direction="row" width="100%" spacing={23} pb={4}>
            <ListingDrawerContent sx={{ lineHeight: 2 }}>
              You can connect with {renterName} below via text!
              <br />
              <ListingDrawerContent
                sx={{ fontWeight: "bold" }}
                component="span"
              >
                Number:{" "}
              </ListingDrawerContent>
              {renterNumber}
            </ListingDrawerContent>
            <IconButton onClick={() => navigate(`/profile?id=${renterId}`)}>
              <Avatar
                sx={{
                  width: 62,
                  height: 62,
                  bgcolor: "primary.main",
                  fontSize: "30px",
                }}
              >
                {renterName[0]}
              </Avatar>
            </IconButton>
          </Stack>
        </Stack>
        <Box position="sticky" sx={{ backgroundColor: "white", bottom: "0px" }}>
        <Divider width="100%" color="black" />
          <Box
            display="flex"
            justifyContent="center"
            padding="20px"
          >
            
            <CustomButton
              c="white"
              bg="red"
              sx={{
                mt: "0px",
              }}
              onClick={handleRemoveRenter}
            >
              Remove Renter
            </CustomButton>
          </Box>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default ListingDrawer;
