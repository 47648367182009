import React from "react";
import { useNavigate } from "react-router-dom";
import { ImageListItem, ImageList } from "@mui/material";

import { StorageContainer, StorageImageTitle } from "../../styles/home/storageType";

import { storageTypesItemData } from "../../data/index";

const srcset = (image, size, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
};

const StorageType = () => {
  const navigate = useNavigate();

  const handleClick = (queryPath) => {
    if (queryPath) {
      navigate(`/search${queryPath}`);
    } else {
      navigate("/search");
    }
  };

  return (
    <StorageContainer>
      <ImageList
        sx={{ width: "100%", height: 540, pb: 8 }}
        variant="quilted"
        cols={7}
        gap={13}
        rowHeight={125}
      >
        {storageTypesItemData.map((item) => (
          <ImageListItem
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img
              {...srcset(item.img, 130, item.rows, item.cols)}
              style={{ cursor: "pointer" }}
              alt={item.title}
              onClick={() => handleClick(item.path)}
              loading="lazy"
            />
            <StorageImageTitle
              title={item.title}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </StorageContainer>
  );
};

export default StorageType;
