import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { Colors } from "../theme";

export const MapPopupImage = styled("img")(() => ({
  height: "180px",
  width: "300px",
  objectFit: "cover",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  paddingBottom: "5px",
  marginTop: "-10px",
}));

export const MapPopupLocation = styled(Typography)(() => ({
  display: "inline",
  fontFamily: "Raleway",
  fontSize: "16px",
}));

export const MapPopupName = styled(Typography)(() => ({
  fontSize: "25px",
  fontFamily: "Raleway",
  fontWeight: 500
}));

export const MapPopupRate = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "bold",
  color: "white",
  fontSize: "18px",
}));

export const MapRateBackground = styled(Box)(() => ({
  backgroundColor: Colors.secondary,
  width: "300px",
  paddingTop: "5px",
  paddingBottom: "5px",
  bottom: "0px",
  position: "absolute",
  borderRadius: "0 0 25px 25px",
}));
