import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, Stack, IconButton } from "@mui/material";
import { Add, Cancel, ArrowDownward } from "@mui/icons-material";

import { getListing } from "../../store/selectors/listingSelector";
import { setListingImage } from "../../store/slices/listingSlice";
import { setGlobalError } from "../../store/slices/appSlice";
import { removeIndividualSpacePicture } from "../../lib/amplify";

import {
  ListingImagesContainer,
  ListingImagesInputText,
  ListingImagesInputLabel,
  ListingImagesUploadImage,
  ListingImagesPaper,
  ListingImagesButton,
  ListingImagesUserImage,
  ListingImagesAddMoreText,
} from "../../styles/createListing/listingImages";
import uploadFile from "../../images/uploadFile.png";

const ListingImages = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const images = useSelector(getListing).images;

  const handleClick = () => {
    wrapperRef.current.click();
  };

  const handleRemove = async (removedImage) => {
    dispatch(setListingImage(removedImage));

    // If image is in s3 remove it
    if (removedImage.key) {
      await removeIndividualSpacePicture(removedImage.key);
    }
  };

  const handleFileInput = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }

    if (images.length === 10) {
      return dispatch(
        setGlobalError("Sorry we only allow 10 images per space")
      );
    }
    dispatch(
      setListingImage({ key: null, path: URL.createObjectURL(fileObj) })
    );
  };

  return (
    <Box width="100%" display="flex" justifyContent="center">
      {images.length !== 0 ? (
        <Box width="100%">
          <Box
            width="300px"
            display="flex"
            justifyContent="center"
            pb={4}
            pt={2}
          >
            <ArrowDownward sx={{ fontSize: 30 }} />
          </Box>
          <Grid container rowSpacing={3} columnSpacing={1}>
            {images.map((image, i) => (
              <Grid item xl={3} lg={4} key={i}>
                <ListingImagesPaper>
                  <IconButton
                    sx={{ top: -25, right: -25, position: "absolute" }}
                    onClick={() => handleRemove(image)}
                  >
                    <Cancel
                      sx={{
                        fontSize: 35,
                      }}
                    />
                  </IconButton>
                  <ListingImagesUserImage src={image.path} alt="" />
                </ListingImagesPaper>
              </Grid>
            ))}
            <Grid item xs={3}>
              <ListingImagesButton onClick={handleClick}>
                <Stack
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  height="100%"
                >
                  <Add color="primary" sx={{ fontSize: 50 }} />
                  <ListingImagesAddMoreText>
                    Add images
                  </ListingImagesAddMoreText>
                </Stack>
              </ListingImagesButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <ListingImagesContainer onClick={handleClick}>
          <ListingImagesInputLabel>
            <ListingImagesUploadImage src={uploadFile} alt="" />
            <ListingImagesInputText>Upload files here</ListingImagesInputText>
          </ListingImagesInputLabel>
        </ListingImagesContainer>
      )}
      <input
        type="file"
        accept="image/*"
        id="file"
        ref={wrapperRef}
        style={{ display: "none" }}
        onChange={handleFileInput}
      />
    </Box>
  );
};

export default ListingImages;
