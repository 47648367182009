import { styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

export const SpaceContainer = styled(Card)(() => ({
  height: "450px",
  marginBottom: 30,
  marginLeft: 30,
  borderRadius: "25px",
  border: "0.5px solid grey",
  boxShadow: "5px 5px 5px lightgrey",
  padding: "35px"
}));

export const SpaceRate = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: "bold",
  fontSize: "40px",
}));

export const WeekText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  paddingLeft: "10px",
  display: "inline-block",
}));

export const AdditonalCosts = styled(Typography)(() => ({
  fontFamily: "Raleway",
  textDecorationLine: "underline",
  fontSize: "20px"
}));

export const Prices = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const Total = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "20px",
    width: "100%"
  }));
