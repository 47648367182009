import { createRoot } from "react-dom/client";

export const ConvertReactToHtml = (reactComponent) => {
  const div = document.createElement("div");
  const root = createRoot(div);
  root.render(reactComponent);
  return div;
};

export const isPositiveInt = (value) => {
  const isInt = value >>> 0 === parseFloat(value);
  return isInt && parseInt(value) > 0;
};

export const getWordCount = (str) => {
  return str.split(" ").filter(function (n) {
    return n !== "";
  }).length;
};

export const removeFromList = (item, array) => {
  for (let i = 0; i < array.length; i++) {
    console.log(array[i], item)
    if (typeof item === 'object') {
      
      if (JSON.stringify(item) === JSON.stringify(array[i])) {
        array.splice(i, 1);
      }
    } else {
      if (array[i] === item) {
        array.splice(i, 1);
      }
    }
  }
}


