import { styled } from "@mui/material/styles";
import { TextField, Typography } from "@mui/material";
import { Colors } from "../theme";

export const FooterTitle = styled(Typography)(() => ({
  textTransform: "uppercase",
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "20px",
  marginBottom: "1em",
}));

export const FooterLogo = styled('img')(() => ({
  width: "70%",
  height: "50%",
  marginTop: -30,
  paddingBottom: 10,
  objectFit: "cover"
}));

export const SubscribeTf = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    color: Colors.secondary
  },

  ".MuiInput-root::before": {
    borderBottom: `1px solid ${Colors.secondary}`,
  },
  backgroundColor: "white",
}));
