import dayjs from "dayjs";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getLocation } from "../store/selectors/searchSelector";
import { setLocation, setAddress } from "../store/slices/searchSlice";

const useCurrentLocation = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const loc = useSelector(getLocation);

  useEffect(() => {
    if (searchParams.get("lng") && searchParams.get("lat")) {
      dispatch(
        setLocation({
          lng: searchParams.get("lng"),
          lat: searchParams.get("lat"),
        })
      );
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const location = {
          lng: pos.coords.longitude,
          lat: pos.coords.latitude,
        };
        dispatch(setLocation(location));
        dispatch(setAddress("you"));
        location["exp"] = dayjs().add(1, "day");
        localStorage.setItem("loc", JSON.stringify(location));
      });
    }
    // eslint-disable-next-line
  }, [searchParams.get("lng")]);
  return loc;
};

export default useCurrentLocation;
