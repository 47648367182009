import React from "react";
import { Box } from "@mui/system";
import {
  MapPopupImage,
  MapPopupLocation,
  MapPopupName,
  MapPopupRate,
  MapRateBackground,
} from "../../styles/search/mapPopup";

import "./mapPopup.css";


const MapPopup = ({ listing }) => {
  const { _id, name, image, rate, location } = listing;
  return (
    <Box>
      <a
        href={`/space/${_id}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        <MapPopupImage src={image} alt="" key={_id} />
        <Box textAlign="center" border="0">
          <Box paddingLeft="10px" paddingBottom="30px" textAlign="center">
            <MapPopupLocation>{location}</MapPopupLocation>
            <MapPopupName variant="h4">{name}</MapPopupName>
          </Box>
          <MapRateBackground>
            <MapPopupRate component="span"> ${rate}/wk</MapPopupRate>
          </MapRateBackground>
        </Box>
      </a>
    </Box>
  );
};

export default MapPopup;
