import React from "react";
import { Stack, Container } from "@mui/material";

import Points from "./Point";
import MoneyBar from "./MoneyBar";
import FAQ from './FAQ';
import Cards from "./Cards";

import { Title, Subtitle, GreenBackground, BlueBackground } from "../../styles/global";
import { HostSecondBanner } from "../../styles/host";
import shaking_hands from "../../images/shaking_hands.jpg";


const Host = () => {
  return (
    <>
      <Container maxWidth="xl">
        <MoneyBar />
      </Container>
      <GreenBackground>
        <Stack direction="row" spacing={10} alignItems="center" pt="65px" pb="65px">
          <HostSecondBanner src={shaking_hands} />
          <Stack width="35%">
            <Cards />
          </Stack>
        </Stack>
        </GreenBackground>
        <BlueBackground>
        <Container maxWidth="xl" sx={{ pb: "65px" }}>
          <Title sx={{ color: "white" }}> You have questions, we have answers </Title>
          <FAQ />
        </Container>
        </BlueBackground>

      <Container maxWidth="xl">
        <Title> Have peace of mind knowing your protected </Title>
        <Subtitle>
          We do everything we can to build a community of mutual trust
        </Subtitle>
        <Points />
      </Container>
    </>
  );
};

export default Host;
