import { styled } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";

export const CreateListingHeader = styled(Stack)(() => ({
  padding: "20px",
  alignItems: "center",
  display: "flex",
}));

export const CreateListingTitle = styled(Typography)(() => ({
  fontSize: "30px",
  fontFamily: "Raleway",
  fontWeight: "bold",
  paddingBottom: "30px"
}));

export const CreateListingSubtitle = styled(Typography)(() => ({
  fontSize: "22px",
  fontFamily: "Raleway",
  fontWeight: 500,
  paddingBottom: "15px"
}));

export const CreateListingFooter = styled(AppBar)(() => ({
  marginTop: 2,
  marginBottom: 2,
  alignItems: "center",
  padding: "30px 30px 30px",
  position: "fixed",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridAutoRows: "auto",
  backgroundColor: "white",
  flexDirection: "row",
  top: "auto",
  bottom: 0,
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  position: "fixed",
  bottom: "115px",
  left: 0,
  right: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
