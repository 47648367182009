import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Colors } from "../theme";

export const ListingsTitle = styled(Typography)(() => ({
  lineHeight: 1.15,
  fontSize: "35px",
  marginTop: "20px",
  marginBottom: "20px",
  fontFamily: "Raleway",
  fontWeight: "bold",
  color: Colors.secondary,
}));

export const NoResults = styled(Typography)(() => ({
    lineHeight: 1.15,
    fontSize: "30px",
    marginTop: "30px",
    fontFamily: "Raleway",
    fontWeight: "bold",
  }));
