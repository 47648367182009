import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const AccountTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "40px",
  fontWeight: "bold",
}));

export const AccountSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "25px",
  fontWeight: 500,
}));

export const AccountLabel = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  fontWeight: 500,
}));

export const AccountText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const AccountHelper = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "16px",
  color: "gray"
}));

export const ChangePictureButton = styled(Button)(() => ({
    fontSize: "18px",
    color: "black",
    backgroundColor: "white",
    fontWeight: 500,
    textTransform: "none",
  }));
