import { backendApi } from "../backendApi";


const spacesApi = backendApi.injectEndpoints({
    endpoints: (builder) => ({
      getSpace: builder.mutation({
        query(id) {
          return {
            url: `/spaces/?id=${id}`,
            method: "GET",
          };
        },
      }),
      getPublicUserSpaces: builder.mutation({
        query(id) {
          return {
            url: `/spaces/publicUserSpaces?id=${id}`,
            method: "GET",
          };
        },
      }),
      getUserSpaces: builder.query({
        query() {
          return {
            url: "/spaces/userSpaces",
            method: "GET",
          };
        },
        providesTags: ["Space"],
      }),
      searchSpaces: builder.mutation({
        query(queryUrl) {
          return {
            url: "/spaces/search" + queryUrl,
            method: "GET",
          };
        },
      }),
      getFavorites: builder.mutation({
        query() {
          return {
            url: "/spaces/favorites",
            method: "GET",
          };
        },
      }),
      createSpace: builder.mutation({
        query(body) {
          return {
            url: `/spaces/create`,
            method: "POST",
            body: body,
          };
        },
        invalidatesTags: ["Space"]
      }),
      updateSpace: builder.mutation({
        query({ id, body }) {
          return {
            url: `/spaces/update?id=${id}`,
            method: "POST",
            body: body,
          };
        },
        invalidatesTags: ["Space"],
      }),
      publishSpace: builder.mutation({
        query(id) {
          return {
            url: `/spaces/publish?id=${id}`,
            method: "POST"
          };
        },
        invalidatesTags: ["Space"],
      }),
      unPublishSpace: builder.mutation({
        query(id) {
          return {
          url: `/spaces/unpublish?id=${id}`,
            method: "POST"
          };
        },
        invalidatesTags: ["Space"],
      }),
      deleteSpace: builder.mutation({
        query(id) {
          return {
          url: `/spaces/delete?id=${id}`,
            method: "DELETE"
          };
        },
        invalidatesTags: ["Space"],
      })
    }),
  });
  
  export const {
    useGetSpaceMutation,
    useGetPublicUserSpacesMutation,
    useGetUserSpacesQuery,
    useGetFavoritesMutation,
    useSearchSpacesMutation,
    useCreateSpaceMutation,
    useUpdateSpaceMutation,
    usePublishSpaceMutation,
    useUnPublishSpaceMutation,
    useDeleteSpaceMutation
  } = spacesApi;
  