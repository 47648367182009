import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Colors } from '../theme';


export const ContactTitle = styled(Typography)(() => ({
    lineHeight: 1.25,
    fontWeight: "bold",
    paddingTop: "50px",
    paddingBottom: "50px",
    fontSize: "40px",
    color: Colors.secondary,
    fontFamily: "Raleway",
}));

export const ContactSubtitle = styled(Typography)(() => ({
    lineHeight: 1.15,
    letterSpacing: 1.15,
    color: "black",
    fontSize: '20px',
    fontFamily: "Raleway",
}));