import { styled } from "@mui/material/styles";
import { Tab } from "@mui/material";

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  () => ({
    textTransform: "none",
    fontFamily: "Raleway",
    color: "black",
    fontSize: "22px",
    float: "right",
    height: "115px",
    paddingRight: 20,
    paddingLeft: 20
  })
);
