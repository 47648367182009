import React from "react";
import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  Stack,
} from "@mui/material";

const EditableField = ({
  edit,
  setField,
  existingField,
  placeholder,
  endText,
  height,
  width = "330px",
  password,
  label,
  children,
  maxRows = 1,
  ...other
}) => {
  return (
    <Box {...other} width={width}>
      {edit && (
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: "20px",
            pb: 1,
            pl: 1,
            color: "black",
          }}
        >
          {label}
        </Typography>
      )}
      {edit ? (
        <Stack direction="row" width="100%" display="flex" spacing={1}>
          <TextField
            multiline
            maxRows={maxRows}
            onInput={(e) => {
              setField(e.target.value);
            }}
            defaultValue={password ? "" : existingField}
            placeholder={placeholder}
            size="medium"
            variant="standard"
            sx={{
              border: "1px solid grey",
              borderRadius: 5,
              padding: "10px",
              width: "100%",
              height: height,
              backgroundColor: "white",
            }}
            InputProps={{
              style: {
                paddingLeft: "15px",
                fontFamily: "Raleway",
                fontSize: "25px",
              },
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      fontFamily: "Raleway",
                      fontSize: "20px",
                      paddingRight: "10px",
                      color: "black",
                    }}
                  >
                    {endText}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          {children}
        </Stack>
      ) : (
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: "25px",
          }}
        >
          {existingField}
        </Typography>
      )}
    </Box>
  );
};

export default EditableField;
