import React from "react";

import { Stack } from "@mui/material";
// import { Check } from "@mui/icons-material";

import { FormPhoneInput } from "../../components/CustomFormTextInput";

// import { ListingVerifyText } from "../../styles/createListing/listingVerify";
// import { CustomButton } from "../../styles/global";
// import { Colors } from "../../styles/theme";

const ListingVerify = ({ phone_number_verified }) => {
  return (
    <Stack spacing={5}>
      <Stack spacing={3} pt={8}>
        <FormPhoneInput phone_number_verified={phone_number_verified} disabled />
      </Stack>
      <Stack spacing={3}>
        {/* <ListingVerifyText>Identity Verification</ListingVerifyText> */}
        <Stack
          alignItems="center"
          direction="row"
          spacing={3}
          mt={5}
        >
          {/* {true ? (
            <Stack direction="row" alignItems="center" spacing={3}>
              <Check sx={{ fontSize: 40, color: "green" }} />
              <ListingVerifyText>
                Your identity has been verified!
              </ListingVerifyText>
            </Stack>
          ) : (
            <CustomButton
              c="white"
              bg={Colors.secondary}
              sx={{ borderRadius: "20px", height: "70px" }}
              onClick={() => null}
            >
              Verify identity
            </CustomButton>
          )} */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ListingVerify;
