import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AccountCircle, SegmentRounded } from "@mui/icons-material";
import { Box, IconButton, Avatar, Stack } from "@mui/material";

import SearchBar from "./SearchBar";
import Dropdown from "./Dropdown";
import Loading from "./Loading";

import useCustomRoute from "../hooks/useCustomRoute";
import useGetAuthenticatedUser from "../hooks/useGetAuthenticatedUser";

import { signOut } from "../lib/amplify";

import {
  AppBarContainer,
  AppBarHeader,
  AppBarButton,
  StartListingButton,
} from "../styles/components/appBar";
import { Colors } from "../styles/theme";

import { setLogin, setAccessToken } from "../store/slices/appSlice";
import { userState } from "../store/states";

import { menuDropdown, accountDropdown } from "../data/index";
import logo from "../images/logo.png";

const AppBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pastSearch, setPastSearch] = useState(location?.pathname !== "/");
  const [openMenu, setOpenMenu] = useState(null);
  const [openAccount, setOpenAccount] = useState(null);
  const setClicked = useCustomRoute({ path: "/create" });
  const [user, setUser, isLoading] = useGetAuthenticatedUser();

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleMenuRoute = async (route) => {
    await navigate(route.path);
    if (route.position) {
      window.scrollTo(0, route.position);
    }
  };

  const handleOpenAccount = (event) => {
    setOpenAccount(event.currentTarget);
  };

  const handleAccountRoute = async (route) => {
    if (route.path == null) {
      await signOut();
      setUser(userState);
      dispatch(setAccessToken());
    } else {
      navigate(route.path);
    }
  };

  useEffect(() => {
    const changeRender = () => {
      if (window.scrollY >= 390 || location?.pathname !== "/") {
        setPastSearch(true);
      } else {
        setPastSearch(false);
      }
    };
    changeRender();
    window.addEventListener("scroll", changeRender);
  }, [location.pathname]);

  return (
    <AppBarContainer
      sx={{
        backgroundColor: "white",
        position: location?.pathname === "/" ? "fixed" : "sticky",
      }}
    >
      <AppBarHeader>
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            href="/"
            component="img"
            alt="logo"
            src={logo}
            style={{ width: "225px" }}
          />
        </Link>
      </AppBarHeader>
      <Box width="70%" gridColumn="2">
        {!["/search", "/dashboard"].includes(location?.pathname) &&
          pastSearch && <SearchBar width="100%" />}
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        justifySelf={{ sm: "end" }}
      >
        {isLoading && <Loading />}
        {user.first_name && !isLoading && (
          <>
            <StartListingButton onClick={setClicked}>
              Start listing
            </StartListingButton>
            <IconButton onClick={handleOpenAccount} size="small" sx={{ ml: 2 }}>
              <Avatar
                sx={{ width: 52, height: 52, bgcolor: "primary.main" }}
                src={user.profile_picture}
              >
                {user.first_name[0]}
              </Avatar>
            </IconButton>
            <Dropdown
              options={accountDropdown}
              setAnchorEl={setOpenAccount}
              anchorEl={openAccount}
              handleClick={handleAccountRoute}
            />
          </>
        )}
        {!user.first_name && !isLoading && (
          <>
            {location?.pathname !== "/host" && (
              <Link to="/host" style={{ textDecoration: "none" }}>
                <AppBarButton backcolor={Colors.yellow}>
                  Become a host
                </AppBarButton>
              </Link>
            )}
            <AppBarButton
              backcolor="white"
              fontSize="large"
              endIcon={<AccountCircle color="primary" />}
              onClick={() => dispatch(setLogin({ show: true }))}
            >
              Sign in
            </AppBarButton>
          </>
        )}

        <IconButton color="secondary" onClick={handleOpenMenu}>
          <SegmentRounded
            fontSize="large"
            
          />
        </IconButton>
        <Dropdown
          options={menuDropdown}
          setAnchorEl={setOpenMenu}
          anchorEl={openMenu}
          handleClick={handleMenuRoute}
          positionContent="flex-end"
          pointed
        />
      </Stack>
    </AppBarContainer>
  );
};

export default AppBar;
