import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import { Colors } from "../theme";

export const ListingContainer = styled(Box)(() => ({
  position: "relative",
}));

export const ListingImage = styled("img")(({ width }) => ({
  height: "70%",
  width: width,
  objectFit: "cover",
  display: "inline-flex",
  borderRadius: '25px',
  paddingBottom: '5px'
}));

export const ListingPriceBox = styled(Box)(({ sh, width }) => ({
  width: "30%",
  position: "absolute",
  justifyContent: "center",
  backgroundColor: sh === "true" ? Colors.yellow : Colors.secondary,
  borderBottomLeftRadius: "25px",
  borderTopRightRadius: "25px",
  top: "0px",
  right: "0px",
}));

export const ListingRate = styled(Typography)(({ sh }) => ({
  fontFamily: "Raleway",
  fontWeight: 600,
  fontSize: "25px",
  padding: "20px",
  textAlign: "center",
  color: sh === "true" ? "black" : "white",
}));

export const ListingDiscount = styled(Typography)(({ sh }) => ({
  lineHeight: 1.25,
  fontFamily: "Raleway",
  fontSize: "16px",
  color: sh === "true" ? Colors.secondary : "white",
  marginLeft: "30px",
  marginTop: "5px",
  marginBottom: "5px",
}));

export const ListingLocation = styled(Typography)(() => ({
  display: "inline",
  fontFamily: "Raleway",
  fontSize: "18px",
}));

export const ListingName = styled(Typography)(() => ({
  display: "block",
  fontFamily: "Raleway",
  fontSize: "25px",
  marginTop: "3px",
  fontWeight: 500
}));

export const ListingDimension = styled(Typography)(() => ({
  fontFamily: "Noto Sans",
  fontWeight: "bold",
  fontSize: "22px",
}));

export const SuperhostButton = styled(Button)(() => ({
  backgroundColor: Colors.yellow,
  color: "black",
  borderRadius: "28px",
  fontFamily: "Raleway",
  textAlign: "center",
  position: "absolute",
  top: 75,
  right: 10,
  fontSize: "14px",
  fontWeight: 500,
  "&:hover": {
    backgroundColor: Colors.yellow,
  },
}));
