import React from "react";
import { BookCard, BookSubtitle } from "../../styles/book";

const PaymentDetails = () => {
  return (
    <BookCard>
      <BookSubtitle>Payment</BookSubtitle>
    </BookCard>
  );
};

export default PaymentDetails;
