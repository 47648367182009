import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";

import FormTextInput from "../../components/FormTextInput";
import { FormPasswordInput, FormEmailInput } from "../../components/CustomFormTextInput";

import {
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Alert,
  Stack
} from "@mui/material";

import { createAuthUser, handleCreateAuthUserError } from "../../lib/amplify";
import { STANDARD_ERROR_MSG } from "../../lib/constants";
import { useCreateUserMutation } from "../../store/api/users";

import {
  SignInUnderlinedButton,
  SignInSubmitButton,
  SignInTitle,
} from "../../styles/signIn";
import { Colors } from "../../styles/theme";


const Register = ({ handleSwitchScreen }) => {
  const [createUser] = useCreateUserMutation();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(undefined);
  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
      firstname: "",
      lastname: "",
    },
  });

  const handleSubmit = async (data) => {
    const { email, password, firstname, lastname } = data;

    if (!checked) {
      return setError(
        "You are required to agree to the terms and service before signing up"
      );
    }
    createAuthUser(email, password)
      .then(async (id) => {
        try {
          await createUser({
            first_name: firstname,
            last_name: lastname,
            auth_id: id,
          }).unwrap();
        } catch (error) {
          // TODO: Implement rollback for auth user
          setError(STANDARD_ERROR_MSG);
        }
        setError(false);
        handleSwitchScreen(undefined, "registerSuccess");
      })
      .catch((e) => {
        setError(handleCreateAuthUserError(e));
      });
  };

  const handleReset = () => {
    setError(false);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        onChange={handleReset}
        style={{ width: "100%" }}
      >
        <SignInTitle>Create an account</SignInTitle>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{ fontFamily: "Raleway", fontSize: "18px" }}
          >
            {error}
          </Alert>
        )}
        <Stack sx={{ mt: 1 }} spacing={2}>
          <FormTextInput name="firstname" placeholder="First name" />
          <FormTextInput name="lastname" placeholder="Last name" />
          <FormEmailInput />
          <FormPasswordInput />
          <FormControlLabel
            sx={{ paddingTop: "15px" }}
            checked={checked}
            onChange={() => setChecked(!checked)}
            control={
              <Checkbox
                color="primary"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
              />
            }
            label={
              <Typography fontSize="20px" fontFamily="Raleway">
                I agree to the terms and conditions
              </Typography>
            }
          />
          <SignInSubmitButton type="submit" bg={Colors.secondary}>
            Create an account
          </SignInSubmitButton>
          <Box sx={{ mt: 2 }}>
            <SignInUnderlinedButton
              onClick={(e) => handleSwitchScreen(e, "login")}
            >
              Already have an account?
            </SignInUnderlinedButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Register;
