import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const ProfileName = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "30px",
  fontWeight: "bold",
}));

export const ProfileVerified = styled(Typography)(() => ({
  fontFamily: "Raleway",
  color: "gray",
  fontSize: "20px",
  fontWeight: "bold",
}));

export const ProfileSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "22px",
  fontWeight: 500,
}));

export const ProfileContent = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const ProfilePhoneSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  color: "gray",
  fontSize: "16px",
}));

export const ProfileTitle = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "30px",
    fontWeight: "bold",
  }));
