import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import useCustomRoute from "../../hooks/useCustomRoute";

import {
  SpacesContainer,
  SpacesImage,
  SpacesTitle,
  MoneyBarContainer,
  MoneyBarTitle,
  MoneySliderText,
  MoneySliderButton,
  MoneySliderBackground,
} from "../../styles/home/moneyBar";
import { Colors } from "../../styles/theme";
import { CustomButton, Title, Subtitle } from "../../styles/global";
import { spaces } from "../../data/index";

const MoneyBar = () => {
  const firstItem = spaces[0];
  const [displayMoney, setDisplayMoney] = useState({
    prev: firstItem.price,
    curr: firstItem.price,
  });
  const [spaceType, setSpaceType] = useState(firstItem.title);
  const reroute = useCustomRoute({ path: "/create" });

  useEffect(() => {
    const updateDisplayMoney = () => {
      if (displayMoney.prev < displayMoney.curr) {
        setTimeout(() => {
          setDisplayMoney({ ...displayMoney, prev: displayMoney.prev + 1 });
        }, 0.75);
      } else if (displayMoney.prev > displayMoney.curr) {
        setTimeout(() => {
          setDisplayMoney({ ...displayMoney, prev: displayMoney.prev - 1 });
        }, 0.75);
      }
    };

    if (displayMoney.prev > 0 || displayMoney.prev !== displayMoney.curr)
      updateDisplayMoney();
  }, [displayMoney]);

  return (
    <Box width="100%" pb="65px">
      <Title> Make money from your space </Title>
      <Subtitle>
        Live the life you want by earning passive income from space you already
        own. Sign up free today!
      </Subtitle>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={5}
        direction={{ lg: "column", xl: "row" }}
      >
        <Box display="inline-flex">
          {spaces.map(({ img, title, price }) => (
            <SpacesContainer key={title}>
              <SpacesImage
                src={img}
                alt={title}
                onMouseEnter={() => {
                  setDisplayMoney({ ...displayMoney, curr: price });
                  setSpaceType(title);
                }}
              />
              <SpacesTitle sx={{ fontWeight: 500 }} textAlign="center"> {title} </SpacesTitle>
            </SpacesContainer>
          ))}
        </Box>

        <MoneyBarContainer spacing={1}>
          <MoneyBarTitle> {spaceType} </MoneyBarTitle>
          <Box display="inline-flex"  width="100%">
            <MoneySliderButton disabled />
            <MoneySliderBackground
              disabled
              style={{
                padding: "7px " + (displayMoney.prev / 2.1).toString() + "px",
              }}
            />
            <Stack direction="row" spacing={2} alignItems="center" paddingTop="20px" marginLeft="auto" width="100%">
              <MoneySliderText>${displayMoney.prev.toString()}</MoneySliderText>
              <SpacesTitle variant="h2"> /month </SpacesTitle>
            </Stack>
          </Box>
          <Box textAlign="center">
            <CustomButton
              c="black"
              bg={Colors.yellow}
              size="wide"
              onClick={reroute}
            >
              List your space
            </CustomButton>
          </Box>
        </MoneyBarContainer>
      </Stack>
    </Box>
  );
};

export default MoneyBar;
