import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "./store/selectors/appSelector";

const PrivateRoutes = () => {
  const authenticated = useSelector(getToken);
  if (authenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoutes;
