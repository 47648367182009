import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material";

export const RentalDrawerTitle = styled(Typography)(() => ({
  fontWeight: "bold",
  fontFamily: "Raleway",
  fontSize: "30px",
  padding: "20px",
}));

export const RentalDrawerSubtitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontFamily: "Raleway",
  fontSize: "25px",
}));

export const RentalDrawerContent = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const RentalDrawerImage = styled("img")(() => ({
  width: "100%",
  height: "400px",
  objectFit: "cover",
}));

export const RentalDrawerViewListingButton = styled(Button)(() => ({
  width: "100%",
  height: "60px",
  fontFamily: "Raleway",
  fontSize: "20px",
  border: "1px solid black",
  color: "black",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  textTransform: "none",
}));

export const RentalDrawerLengthOfRentButton = styled(Button)(() => ({
  width: "240px",
  height: "80px",
  fontFamily: "Raleway",
  fontSize: "20px",
  borderRadius: "20px",
  border: "1px solid black",
  padding: "10px",
  color: "black",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  textTransform: "none",
}));
