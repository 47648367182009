import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Colors } from "../theme";

export const CardHeader = styled(Typography)(() => ({
  lineHeight: 1.15,
  fontSize: "25px",
  fontFamily: "Raleway",
  fontWeight: "bold",
  color: Colors.secondary,
  display: "inline-block",
  alignItems: "end",
}));

export const CardDescription = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  paddingLeft: "48px"
}));

export const CardImage = styled("img")(() => ({
  width: "30px",
  height: "30px",
  objectFit: "contain",
}));
