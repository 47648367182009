import { styled } from "@mui/material/styles";
import { Box, Typography, Button, Card } from "@mui/material";
import { Colors } from "./theme";

export const GreenBackground = styled(Box)(() => ({
  backgroundColor: Colors.green,
}));

export const BlueBackground = styled(Box)(() => ({
  backgroundColor: Colors.secondary,
}));

export const Title = styled(Typography)(() => ({
  lineHeight: 1.25,
  fontSize: "35px",
  paddingTop: "65px",
  fontFamily: "Raleway",
  fontWeight: "bold",
  color: Colors.secondary,
}));

export const Subtitle = styled(Typography)(() => ({
  lineHeight: 1.15,
  fontSize: "20px",
  marginTop: "8px",
  marginBottom: "50px",
  fontFamily: "Raleway",
}));

export const XScrollBox = styled(Box)(() => ({
  overflow: "auto",
}));

export const YScrollBox = styled(Box)(({ height }) => ({
  overflow: "hidden",
  overflowY: "scroll",
  height: height,
}));

export const CustomButton = styled(Button)(({ bg, c, size = "small" }) => ({
  backgroundColor: bg,
  color: c,
  borderRadius: "28px",
  fontFamily: "Raleway",
  marginTop: "25px",
  fontSize: "16px",
  padding: "10px 0px 10px 0px",
  border: "2px solid black",
  width: size === "small" ? "200px" : "300px",
  "&:hover": {
    backgroundColor: bg,
    filter: "drop-shadow(2px 2px 2px #222)",
  },
  "&:disabled": {
    color: "black"
  },
  textTransform: "none",
}));

export const CustomCard = styled(Card)(({ width, height }) => ({
  height: height,
  width: width,
  borderRadius: "20px",
  border: "0.5px solid grey",
  padding: "15px",
  boxShadow: "5px 5px 5px lightgrey",
}));
