import React, { useState, useEffect } from "react";
import { Box, Stack, Grid } from "@mui/material";
import useCustomRoute from "../../hooks/useCustomRoute";

import {
  SpacesContainer,
  SpacesImage,
  SpacesTitle,
  MoneyBarContainer,
  MoneyBarTitle,
  MoneySliderText,
  MoneySliderButton,
  MoneySliderBackground,
  MoneyBarBannerContent,
  MoneyBarBannerTitle,
  MoneyBarBannerSubtitle,
} from "../../styles/home/moneyBar";
import { Colors } from "../../styles/theme";
import { CustomButton } from "../../styles/global";
import { spaces } from "../../data/index";

const MoneyBar = () => {
  const firstItem = spaces[4];
  const [displayMoney, setDisplayMoney] = useState({
    prev: firstItem.price,
    curr: firstItem.price,
  });
  const [spaceType, setSpaceType] = useState(firstItem.title);
  const reroute = useCustomRoute({ path: "/create" });

  useEffect(() => {
    const updateDisplayMoney = () => {
      if (displayMoney.prev < displayMoney.curr) {
        setTimeout(() => {
          setDisplayMoney({ ...displayMoney, prev: displayMoney.prev + 1 });
        }, 0.5);
      } else if (displayMoney.prev > displayMoney.curr) {
        setTimeout(() => {
          setDisplayMoney({ ...displayMoney, prev: displayMoney.prev - 1 });
        }, 0.5);
      }
    };

    if (displayMoney.prev > 0 || displayMoney.prev !== displayMoney.curr)
      updateDisplayMoney();
  }, [displayMoney]);

  return (
    <Box width="100%">
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={5}
        height="650px"
        direction="row"
      >
        <MoneyBarBannerContent>
          <Box width="90%">
            <MoneyBarBannerTitle variant="h3">
              Here's how much{" "}
              <MoneyBarBannerTitle
                variant="h3"
                component="span"
                sx={{
                  fontStyle: "italic",
                  color: Colors.yellow,
                  marginBottom: "0px",
                }}
              >
                you
              </MoneyBarBannerTitle>{" "}
              could make
            </MoneyBarBannerTitle>
            <MoneyBarBannerSubtitle variant="subtitle">
              Start making passive income with your space without the worry of
              somebody living there.
            </MoneyBarBannerSubtitle>
          </Box>
          <MoneyBarContainer spacing={1}>
            <MoneyBarTitle sx={{ pl: "0px", pt: 4 }}>
              {" "}
              {spaceType}{" "}
            </MoneyBarTitle>
            <Box display="inline-flex" width="100%" pt={1}>
              <MoneySliderButton
                sx={{ border: `2px solid ${Colors.primary}`, height: "20px" }}
                disabled
              />
              <MoneySliderBackground
                disabled
                style={{
                  padding: "7px " + (displayMoney.prev / 2.1).toString() + "px",
                  height: "20px",
                }}
              />
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                paddingTop="35px"
                marginLeft="100%"
              >
                <MoneySliderText>
                  ${displayMoney.prev.toString()}
                </MoneySliderText>
                <SpacesTitle variant="h2"> / </SpacesTitle>
                <SpacesTitle variant="h2"> month </SpacesTitle>
              </Stack>
            </Box>
            <Box textAlign="center">
              <CustomButton
                c="black"
                bg={Colors.yellow}
                size="wide"
                onClick={reroute}
              >
                List your space
              </CustomButton>
            </Box>
          </MoneyBarContainer>
        </MoneyBarBannerContent>
        <Grid
          container
          spacing={1}
          width="50%"
          alignItems="center"
          justifyContent="center"
        >
          {spaces.map(({ img, title, price }) => (
            <Grid item key={title} sm={4}>
              <SpacesContainer sx={{ width: "175px" }}>
                <SpacesImage
                  src={img}
                  alt={title}
                  onMouseEnter={() => {
                    setDisplayMoney({ ...displayMoney, curr: price });
                    setSpaceType(title);
                  }}
                />
                <SpacesTitle textAlign="center" sx={{ marginTop: "0px" }}> {title} </SpacesTitle>
              </SpacesContainer>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};

export default MoneyBar;
