import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider, Stack } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import DatePicker from "../../components/DatePicker";
import useCustomRoute from "../../hooks/useCustomRoute";
import {
  SpaceContainer,
  SpaceRate,
  WeekText,
  AdditonalCosts,
  Prices,
  Total,
} from "../../styles/space/priceCard";
import { CustomButton } from "../../styles/global";
import { Colors } from "../../styles/theme";
import { setGlobalError } from "../../store/slices/appSlice";
import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";
import Tooltip from "../../components/Tooltip";

const PriceCard = ({ rate, auth_id, _id }) => {
  const serviceFee = 0.08 * rate;
  const tax = 0.13 * rate;
  const total = rate + tax + serviceFee;

  const dispatch = useDispatch();
  const [info, setInfo] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [user] = useGetAuthenticatedUser();
  const reroute = useCustomRoute({
    path: `/book?listingId=${_id}`,
    data: date,
  });

  const handleHover = (newInfo) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setInfo(newInfo);
  };

  const handleBook = () => {
    if (date == null || date === "Invalid Date") {
      return dispatch(setGlobalError("Please choose a move in date before booking"));
    }
    reroute(true);
  };

  return (
    <SpaceContainer>
      <Stack spacing={3}>
        <SpaceRate>
          ${rate}
          <WeekText component="span">week</WeekText>
        </SpaceRate>
        <DatePicker width="85%" date={date} setDate={setDate} />
        <Stack direction="row" spacing={5}>
          <Stack direction="row" width="100%">
            <AdditonalCosts>Service Fee</AdditonalCosts>
            <InfoOutlined
              sx={{ fontSize: 20, marginTop: 1, marginLeft: 1 }}
              onMouseOver={handleHover(
                "This helps us run our platform and offer support services."
              )}
              onMouseOut={() => setOpen(false)}
            />
          </Stack>
          <Prices>${serviceFee.toFixed(2)}</Prices>
        </Stack>
        <Stack direction="row" spacing={5}>
          <Stack direction="row" width="100%">
            <AdditonalCosts>Taxes</AdditonalCosts>
            <InfoOutlined
              sx={{ fontSize: 20, marginTop: 1, marginLeft: 1 }}
              onMouseOver={handleHover(
                "This includes Occupancy taxes and GST. For Ontario residents this is 13%."
              )}
              onMouseOut={() => setOpen(false)}
            />
          </Stack>
          <Prices>${tax.toFixed(2)}</Prices>
        </Stack>
        <Divider width="100%" color="grey" />
        <Stack direction="row" spacing={5}>
          <Total>Total</Total>
          <Prices>${total.toFixed(2)}</Prices>
        </Stack>
      </Stack>
      {auth_id !== user.auth_id && (
        <CustomButton
          c="black"
          bg={Colors.yellow}
          sx={{
            display: "block",
            margin: "0 auto",
            marginTop: "50px",
            width: "100%",
            marginBottom: "25px",
          }}
          onClick={handleBook}
        >
          Continue reservation
        </CustomButton>
      )}
      <Tooltip
        open={open}
        anchorEl={anchorEl}
        info={info}
        placement="bottom-start"
        width="200px"
      />
    </SpaceContainer>
  );
};

export default PriceCard;
