import React from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  Area,
} from "recharts";
import { Stack, Divider } from "@mui/material";

import CustomTable from "../../components/CustomTable";
import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";

import {
  DashboardPanelTitle,
  DashboardPanelCardStats,
  DashboardPanelCardSubtitle,
  DashboardPanelCardTitle,
} from "../../styles/dashboard/dashboardPanel";
import { YScrollBox, CustomCard } from "../../styles/global";
import { Colors } from "../../styles/theme";
import { metricData } from "../../data/fake/information";

const DashboardPanel = () => {
  const { incomeData, clicks, revenue, averageRent } = metricData;
  const [user] = useGetAuthenticatedUser();
  let data = [];
  return (
    <YScrollBox height="100%" width="100%">
      <Stack width="100%">
        <DashboardPanelTitle paddingBottom={10}>
          Welcome back {user.first_name}, see how your account is doing!
        </DashboardPanelTitle>
        <Stack
          direction={{ xl: "row", lg: "column" }}
          spacing={{ xl: 10, lg: 5 }}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Stack width={{ xl: "35%", lg: "55%" }} spacing={5}>
            <CustomCard width="100%" height="280px">
              <DashboardPanelCardTitle>Account Growth</DashboardPanelCardTitle>
              <Stack
                direction="row"
                spacing={4}
                width="100%"
                height="60%"
                justifyContent="center"
                alignItems="end"
              >
                <Stack spacing={1} textAlign="center">
                  <DashboardPanelCardStats>${revenue}</DashboardPanelCardStats>
                  <DashboardPanelCardSubtitle>
                    Total Revenue
                  </DashboardPanelCardSubtitle>
                </Stack>
                <Stack spacing={1} textAlign="center">
                  <DashboardPanelCardStats>{clicks}</DashboardPanelCardStats>
                  <DashboardPanelCardSubtitle>
                    Listing Views
                  </DashboardPanelCardSubtitle>
                </Stack>
                <Stack spacing={1} textAlign="center">
                  <DashboardPanelCardStats>
                    {averageRent}
                  </DashboardPanelCardStats>
                  <DashboardPanelCardSubtitle>
                    Average Rent Time
                  </DashboardPanelCardSubtitle>
                </Stack>
              </Stack>
            </CustomCard>
            <CustomCard width="105%" height="330px" sx={{ padding: "0px" }}>
              <DashboardPanelCardTitle sx={{ padding: "15px" }}>
                Units Being Rented
              </DashboardPanelCardTitle>
              <Divider width="100%" color="black" />
              <CustomTable rows={data} history={false} />
            </CustomCard>
          </Stack>
          <CustomCard width="55%" height="650px">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={incomeData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={Colors.secondary}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={Colors.secondary}
                      stopOpacity={0}
                    />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="Income"
                  stroke={Colors.secondary}
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </CustomCard>
        </Stack>
      </Stack>
    </YScrollBox>
  );
};

export default DashboardPanel;
