import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  InputAdornment,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { RemoveCircle, ControlPoint } from "@mui/icons-material";
import { getListing } from "../../store/selectors/listingSelector";
import { setListingRate } from "../../store/slices/listingSlice";
import { CustomCard } from "../../styles/global";

const ListingRate = () => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const rate = useSelector(getListing).rate;
  const lowerBound = 25;
  const upperBound = 55;
  const service_fee = 8.29;

  const handleInput = (e) => {
    if (e.target.value > upperBound) {
      setError(true);
    } else {
      setError(false);
    }
    if (e.target.value === "" || e.target.value == null) {
      dispatch(setListingRate(0));
      return;
    }
    try {
      const val = parseInt(e.target.value, 10);
      dispatch(setListingRate(val));
    } catch (error) {
      return;
    }
  };

  const handleMinus = () => {
    if (rate - 5 < upperBound) {
      setError(false);
    }
    if (rate - 5 >= 0) {
      dispatch(setListingRate(rate - 5));
    }
  };

  const handlePlus = () => {
    if (rate + 5 > upperBound) {
      setError(true);
    }
    dispatch(setListingRate(rate + 5));
  };

  return (
    <Stack width="100%" display="flex" alignItems="center" mt={3} spacing={2}>
      <CustomCard
        width="700px"
        height="375px"
        sx={{ backgroundColor: "rgb(247, 247, 247)" }}
      >
        <Stack spacing={3} width="100%" display="flex" alignItems="center">
          <Stack direction="row" spacing={2} mt={5} alignItems="center">
            <IconButton sx={{ width: 50, height: 50 }} onClick={handleMinus}>
              <RemoveCircle
                sx={{
                  fontSize: 35,
                }}
              />
            </IconButton>
            <TextField
              multiline
              maxRows={3}
              value={rate.toString()}
              onInput={handleInput}
              variant="standard"
              sx={{
                border: error ? "3px solid red" : "3px solid black",
                borderRadius: 5,
                padding: "10px",
                width: "400px",
                height: "90px",
                backgroundColor: "white",
              }}
              InputProps={{
                style: {
                  paddingLeft: "15px",
                  fontFamily: "Noto Sans",
                  fontWeight: "bold",
                  fontSize: "55px",
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      sx={{
                        fontFamily: "Noto Sans",
                        fontWeight: "bold",
                        fontSize: "55px",
                        paddingLeft: "8rem",
                        color: "black",
                      }}
                    >
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton sx={{ width: 50, height: 50 }} onClick={handlePlus}>
              <ControlPoint
                sx={{
                  fontSize: 35,
                }}
              />
            </IconButton>
          </Stack>

          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: "25px",
              fontWeight: 600,
              color: "black",
            }}
          >
            per week
          </Typography>
          {error && (
            <Typography
              sx={{
                fontFamily: "Raleway",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
                color: "red",
                width: "70%",
              }}
            >
              Are you sure you want to list at that price? It's outside our
              recommended range and will be less likely to sell.
            </Typography>
          )}
          <Typography
            sx={{
              fontFamily: "Raleway",
              fontSize: "20px",
              color: "black",
              textAlign: "center",
              paddingTop: !error && "5%",
              width: "75%",
            }}
          >
            From what you've described we estimate your space would sell best
            between ${lowerBound} and ${upperBound}.
          </Typography>
        </Stack>
      </CustomCard>
      <CustomCard
        width="700px"
        height="100px"
        sx={{ backgroundColor: "rgb(247, 247, 247)" }}
      >
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: "25px",
            fontWeight: 600,
            color: "black",
            pt: "30px",
            pl: "10px",
            display: "inline-block",
          }}
        >
          Renter pays
        </Typography>
        <Typography
          sx={{
            fontFamily: "Raleway",
            fontSize: "30px",
            fontWeight: 600,
            color: "black",
            float: "right",
            pt: "25px",
            pr: "10px",
            display: "inline-block",
          }}
        >
          ${rate === 0 ? "0" : (rate + service_fee).toFixed(2)}
        </Typography>
      </CustomCard>
    </Stack>
  );
};

export default ListingRate;
