import { createSelector } from "@reduxjs/toolkit";

export const getToken = createSelector(
    (state) => state.app.accessToken,
    (accessToken) => accessToken
)

export const showLogin = createSelector(
    (state) => state.app.login,
    (login) => login
)

export const getSubmitState = createSelector(
    (state) => state.app.submitState,
    (submitState) => submitState
)

export const getRefetchUser = createSelector(
    (state) => state.app.refetchUser,
    (refetchUser) => refetchUser
)

