import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

import { Dialog, Container, Stack, Alert } from "@mui/material";
import { Close } from "@mui/icons-material";

import { verifyCode } from "../lib/amplify";

import FormTextInput from "./FormTextInput";

import { refetchUser, setGlobalSuccess } from "../store/slices/appSlice";

import {
  SignInCloseButton,
  SignInStyledContainer,
  SignInLogoImage,
  SignInCopyrightText,
} from "../styles/signIn";
import { CustomButton } from "../styles/global";
import { Colors } from "../styles/theme";

import logo from "../images/logo_mobile.png";

const ConfirmAttrChange = ({ show, setShow, type, emailMethods }) => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues: {
      code: "",
    },
  });

  const handleClose = (e) => {
    e.preventDefault();
    if (emailMethods) emailMethods.reset();
    setShow(false);
    dispatch(refetchUser())
  };

  const handleSubmit = async (data) => {
    const error = await verifyCode(type, data.code);
    if (error) {
      return setError(error);
    }

    setShow(false);
    dispatch(
      setGlobalSuccess(`Successfully changed ${type.replace("_", " ")}!`)
    );
    dispatch(refetchUser());
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: "25px",
          width: "100%",
        },
      }}
    >
      <Container component="main" maxWidth="lg">
        <SignInCloseButton onClick={handleClose}>
          <Close />
        </SignInCloseButton>
        <SignInStyledContainer>
          <SignInLogoImage src={logo} alt="" />
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(handleSubmit)}
              style={{ width: "100%" }}
            >
              <Stack mt={3}>
                {error && (
                  <Alert
                    variant="outlined"
                    severity="error"
                    sx={{ fontFamily: "Raleway", fontSize: "18px", mb: 3 }}
                  >
                    {error}
                  </Alert>
                )}
                <FormTextInput
                  name="code"
                  placeholder="Enter your verification code"
                  helperLabel={`A code was sent to your ${type.replace(
                    "_",
                    " "
                  )}`}
                  label="Verification Code"
                />
                <Stack direction="row">
                  <CustomButton bg="white" c="black" onClick={handleClose}>
                    Exit
                  </CustomButton>
                  <CustomButton
                    bg={Colors.secondary}
                    c="white"
                    type="submit"
                    sx={{ ml: "auto" }}
                  >
                    Enter code
                  </CustomButton>
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        </SignInStyledContainer>
        <SignInCopyrightText variant="body2">
          {"Copyright © "}
          Spaced Out {new Date().getFullYear()}
          {"."}
        </SignInCopyrightText>
      </Container>
    </Dialog>
  );
};

export default ConfirmAttrChange;
