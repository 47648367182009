import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  IconButton,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";

import Tooltip from './Tooltip';

import { setAddress } from "../store/slices/searchSlice";
import { useGeocodeAddressMutation } from "../store/api/externalApi";

import search from "../images/search.png";
import cities from "../data/cities.json";
import { Colors } from "../styles/theme";


const SearchBar = ({ width, variant = "nav" }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [geocodeAddress] = useGeocodeAddressMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const geocode = async (address) => {
    if (address == null || address === "") return;
    try {
      let response = await geocodeAddress(address).unwrap();
      setSearchQuery(address);
      const coord = response.features[0].center;
      dispatch(setAddress(address));
      navigate(`/search?lng=${coord[0]}&lat=${coord[1]}`);
    } catch (error) {
      return null;
    }
  };

  const getOptionLabel = (option) => {
    if (option && option.city && option.province) {
      return `${option.city} ${option.province}`;
    }
    return "";
  };

  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCurrentLocation = () => {
    setSearchQuery("");
    navigate("/search");
  };

  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      inputValue={searchQuery}
      onInputChange={(e) => e?.target?.value && setSearchQuery(e.target.value)}
      options={cities}
      sx={{ width: width }}
      getOptionLabel={getOptionLabel}
      open={searchQuery.length >= 1}
      onChange={(e, value) =>
        value && value.city && value.province
          ? geocode(`${value.city}, ${value.province} Canada`)
          : geocode(searchQuery)
      }
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.city + option.province}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 40 }}>
                <LocationOn sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                  fontFamily: "Raleway",
                }}
              >
                <Box
                  component="span"
                  sx={{ fontWeight: 500, fontSize: "16px" }}
                >
                  {option.city}
                </Box>

                <Typography variant="body2" color="text.secondary">
                  {option.province + ", Canada"}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onInput={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder="Enter your location"
          variant="standard"
          sx={{
            border: 2,
            borderColor: "secondary.main",
            borderRadius: 28,
            padding: "0px 25px",
            backgroundColor: "white",
            marginTop: variant === "nav" ? "0px" : "30px",
            height: variant === "nav" ? null : "55px",
            paddingTop: variant === "nav" ? "0px" : "5px",
          }}
          InputProps={{
            ...params.InputProps,
            style: {
              fontFamily: "Raleway",
              fontSize: "18px",
            },
            disableUnderline: true,
            endAdornment: (
              <>
                <IconButton
                  color="info"
                  aria-label="location"
                  onClick={handleCurrentLocation}
                  onMouseOver={handleHover}
                  onMouseLeave={() => setOpen(false)}
                >
                  <LocationOn sx={{ fontSize: variant === "nav" ? 30 : 35 }} />
                  <Tooltip
                    open={open}
                    anchorEl={anchorEl}
                    marginTop="25px"
                    borderRadius="20px"
                    info="Get your current location"
                    width="225px"
                  />
                </IconButton>
                {variant === "nav" ? (
                  <IconButton
                    color="secondary"
                    aria-label="search"
                    onClick={() => geocode(searchQuery)}
                  >
                    <img src={search} alt="img" width="30px" />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => geocode(searchQuery)}
                    sx={{
                      fontFamily: "Raleway",
                      color: "white",
                      backgroundColor: Colors.primary,
                      borderRadius: "20px",
                      width: 90,
                      "&:hover": {
                        backgroundColor: Colors.primary,
                      },
                    }}
                  >
                    Search
                  </Button>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchBar;
