import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { Container, Stack, Box } from "@mui/material";

import FormTextInput from "../../components/FormTextInput";
import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";

import { CustomButton } from "../../styles/global";
import { ContactTitle } from "../../styles/contact/index";
import { Colors } from "../../styles/theme";
import { setGlobalSuccess } from "../../store/slices/appSlice";

const Contact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user] = useGetAuthenticatedUser();
  const methods = useForm({
    defaultValues: {
      name: undefined,
      email: undefined,
      message: undefined,
    },
  });

  const handleSubmit = (data) => {
    navigate("/");
    dispatch(
      setGlobalSuccess(
        "Message was sent! Our support team will get back to you in under 24 hours. " +
        "Please check your inbox for a response."
      )
    );
  };

  useEffect(() => {
    methods.reset({
      name: user.first_name,
      email: user.email,
    });
  }, [user, methods]);

  return (
    <Container maxWidth="lg">
      <ContactTitle>Contact our support team</ContactTitle>
      <FormProvider {...methods}>
        <form
          style={{ width: "100%" }}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Stack width="100%" alignItems="center" spacing={4}>
            <FormTextInput
              name="name"
              label="First Name"
              placeholder="Enter your first name"
            />
            <FormTextInput
              name="email"
              label="Email"
              placeholder="Enter your email"
            />
            <FormTextInput
              name="message"
              label="Message"
              height={150}
              multiline
              maxRows={5}
              placeholder="How can we help you?"
            />
            <Box
              display="flex"
              width="100%"
              sx={{ float: "right", pb: "65px" }}
            >
              <CustomButton
                c="white"
                bg={Colors.secondary}
                type="submit"
                size="wide"
              >
                Send message
              </CustomButton>
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
};

export default Contact;
