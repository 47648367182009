import { styled } from "@mui/material/styles";
import { Typography, Button, AppBar } from "@mui/material";
import { Colors } from "../theme";

export const AppBarContainer = styled(AppBar)(() => ({
  alignItems: "center",
  padding: "4px 20px",
  position: "fixed",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridAutoRows: "auto",
  rowGap: 2,
  marginY: 3,
}));

export const AppBarHeader = styled(Typography)(() => ({
  padding: "4px",
  paddingTop: "10px",
  flexGrow: 1,
}));

export const AppBarButton = styled(Button)(({ backcolor }) => ({
  backgroundColor: backcolor,
  color: Colors.black,
  fontFamily: "Raleway",
  fontSize: "18px",
  "&:hover": {
    backgroundColor: backcolor,
  },
  border: "2px solid black",
  borderRadius: 28,
  textTransform: "none",
}));

export const StartListingButton = styled(Button)(() => ({
  color: Colors.black,
  fontFamily: "Raleway",
  fontWeight: "bold",
  fontSize: "18px",
  width: "150px",
  padding: "10px",
  "&:hover": {
    backgroundColor: "#ebebeb",
  },
  borderRadius: 28,
  textTransform: "none",
}));
