import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getAuthenticatedUser, getProfilePicture } from "../lib/amplify";

import { useGetUserMutation } from "../store/api/users";
import { userState } from "../store/states";
import { getToken, getRefetchUser } from "../store/selectors/appSelector";

const useGetAuthenticatedUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refetchUser = useSelector(getRefetchUser);
  const token = useSelector(getToken);
  const [user, setUser] = useState(userState);
  const [getUser] = useGetUserMutation();

  useEffect(() => {
    const callback = async () => {
      if (token) {
        const authUser = await getAuthenticatedUser();
        let userData = {};
        try {
          userData = await getUser({ id: authUser?.sub, pub: false }).unwrap();
        } catch (error) {
            return error;
        }
        const signedUrl = await getProfilePicture(userData?.identity_id);
        setUser(
          Object.assign({}, userData, authUser, { profile_picture: signedUrl })
        );
      }
      setIsLoading(false);
    };
    callback();
  }, [getUser, token, refetchUser]);

  return [user, setUser, isLoading];
};

export default useGetAuthenticatedUser;
