import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "you",
  loc: { lng: localStorage.getItem("loc")?.lng, lat: localStorage.getItem("loc")?.lat }
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setLocation(state, action) {
      const { lng, lat } = action.payload;
      state.loc.lng = lng;
      state.loc.lat = lat;
    },
    setAddress(state, action) {
        const address = action.payload;
        if (address > 30) state.address = address.slice(0, 30) + "...";
        else state.address = address;
    },
  },
});

export const { setAddress, setLocation } = searchSlice.actions;
export default searchSlice.reducer;
