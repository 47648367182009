import React from "react";

import useGetAuthenticatedUser from "../../hooks/useGetAuthenticatedUser";

import { Box, Stack, Divider } from "@mui/material";

import ProfileInfo from "./ProfileInfo";
import PublicInfo from "./PublicInfo";
import PrivateInfo from "./PrivateInfo";
import Loading from "../../components/Loading";

import { AccountTitle } from "../../styles/dashboard/account";

const Account = () => {
  const [user] = useGetAuthenticatedUser();
  return (
    <Box>
      <Stack direction="row">
        <Stack width="100%" spacing={4}>
          <AccountTitle>Account</AccountTitle>
          {user.first_name ? (
            <Stack spacing={3}>
              <PublicInfo {...user} />
              <Divider color="lightgrey" width="100%" />
              <ProfileInfo {...user} />
              <Divider color="lightgrey" width="100%" />
              <PrivateInfo {...user} />
            </Stack>
          ) : (
            <Loading />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Account;
