import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const PointsContainer = styled(Box)(() => ({
  textAlign: 'left',
  width: '350px',
}));

export const PointsImage = styled('img')(() => ({
    textAlign: 'left',
    width: '80px',
    marginBottom: '10px'
  }));

export const PointsText = styled(Typography)(() => ({
    fontFamily: "Raleway",
    fontSize: "20px",  
}));

export const BoldedPointsText = styled(Typography)(() => ({
  fontSize: "20px",  
  fontFamily: "Raleway",
  fontWeight: "bold",
  display: "inline-block"
}));