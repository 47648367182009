import { createSelector } from "@reduxjs/toolkit";

export const getLocation = createSelector(
  (state) => state.search.loc,
  (location) => {
    if (location.lng && location.lat) {
      return location;
    }
    return null;
  }
);

export const getAddress = createSelector(
    (state) => state.search.address,
    (address) => address
  );