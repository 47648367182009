import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const OverviewListingImage = styled("img")(() => ({
  height: "120px",
  width: "120px",
  objectFit: "cover",
  borderRadius: "20px",
}));

export const OverviewListingTitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "30px",
  fontWeight: "bold",
}));

export const OverviewListingDetails = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
}));

export const OverviewPricingSubtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  textDecoration: "underline",
  fontSize: "20px",
}));

export const OverviewPricingContent = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontSize: "20px",
  marginLeft: "auto"
}));
