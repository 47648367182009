import React from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import theme from "./styles/theme";

import { ThemeProvider } from "@mui/system";
import { Provider } from "react-redux";

import store from "./store/createStore";
import awsconfig from "./aws-exports";

import "./index.css";

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Router>
    </Provider>
);
