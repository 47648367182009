import React, { useState } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { TuneRounded } from "@mui/icons-material";
import { Grid, Stack, Popover, Box, Divider, Radio } from "@mui/material";

import ListingOptionCard from "../../components/ListingOptionCard";
import SearchBar from "../../components/SearchBar";

import {
  BlueBackground,
  SearchAppBarButton,
  FiltersButton,
  FiltersTitle,
  FiltersResultButton,
  CustomSlider,
  SizeTitle,
} from "../../styles/search/filters";

import { Colors } from "../../styles/theme";

import { storageOptions, parkingOptions, allOptions } from "../../data";

const Filters = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    price: [
      parseInt(searchParams.get("min_price"), 10) || 0,
      parseInt(searchParams.get("max_price"), 10) || 100,
    ],
    min_size: searchParams.get("min_size"),
    max_size: searchParams.get("max_size"),
    listing_type: searchParams.get("listing_type"),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const minDistance = 10;

  const getOptions = () => {
    const is_parking_type = searchParams.get("is_parking_type");
    if (is_parking_type == null) {
      return allOptions;
    } else if (is_parking_type === "true") {
      return parkingOptions;
    } else {
      return storageOptions;
    }
  };

  const handleOptionClick = (title) => {
    if (title === filters.listing_type) {
      setFilters({ ...filters, listing_type: null });
    } else {
      setFilters({ ...filters, listing_type: title });
    }
  };

  const handleTypeChange = (type) => {
    const is_parking_type = searchParams.get("is_parking_type");
    if (is_parking_type === type) {
      navigate(
        location.pathname +
          location.search.replace(`&is_parking_type=${is_parking_type}`, "")
      );
    } else if (is_parking_type == null) {
      navigate(
        location.pathname + location.search + `&is_parking_type=${type}`
      );
    } else {
      navigate(
        location.pathname +
          location.search.replace(
            `&is_parking_type=${is_parking_type}`,
            `&is_parking_type=${is_parking_type === "false"}`
          )
      );
    }
  };

  const checkTypeClicked = (type) => {
    const is_parking_type = searchParams.get("is_parking_type");
    if (is_parking_type === type || is_parking_type == null) {
      return "true";
    }
    return "false";
  };

  const handleShow = () => {
    let path = `${location.pathname}?lng=${searchParams.get(
      "lng"
    )}&lat=${searchParams.get("lat")}`;
    if (filters.min_size) {
      path += `&min_size=${filters.min_size}`;
    }
    if (filters.max_size) {
      path += `&max_size=${filters.max_size}`;
    }
    if (filters.listing_type) {
      path += `&listing_type=${filters.listing_type}`;
    }
    if (filters.price[0] !== 0) {
      path += `&min_price=${filters.price[0]}`;
    }
    if (filters.price[1] !== 100) {
      path += `&max_price=${filters.price[1]}`;
    }

    navigate(path);
    setAnchorEl(null);
  };

  const handleReset = () => {
    setFilters({
      price: [0, 100],
      min_size: null,
      max_size: null,
      listing_type: null,
    });
    navigate(
      location.pathname +
        "?lng=" +
        searchParams.get("lng") +
        "&lat=" +
        searchParams.get("lat")
    );
    setAnchorEl(null);
  };

  const handleSlider = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setFilters({
        ...filters,
        price: [
          Math.min(newValue[0], filters.price[1] - minDistance),
          filters.price[1],
        ],
      });
    } else {
      setFilters({
        ...filters,
        price: [
          filters.price[0],
          Math.max(newValue[1], filters.price[0] + minDistance),
        ],
      });
    }
  };

  const controlProps = (min_size, max_size) => ({
    checked: filters.min_size === min_size && filters.max_size === max_size,
    onChange: (e) =>
      setFilters({ ...filters, min_size: min_size, max_size: max_size }),
    name: "color-radio-button-demo",
    color: "primary",
    sx: { "& .MuiSvgIcon-root": { fontSize: 28 } },
  });

  return (
    <BlueBackground>
      <Stack direction="row" spacing={3} p={2}>
        <SearchAppBarButton
          clicked={checkTypeClicked("false")}
          onClick={() => handleTypeChange("true")}
        >
          Storage
        </SearchAppBarButton>
        <SearchAppBarButton
          clicked={checkTypeClicked("true")}
          onClick={() => handleTypeChange("false")}
        >
          Parking
        </SearchAppBarButton>
        <SearchBar width="650px" />
        <Box width="25px"></Box>
        <FiltersButton
          endIcon={<TuneRounded />}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          Filters
        </FiltersButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ justifyContent: "center" }}
        >
          <Stack
            spacing={2}
            sx={{
              width: "600px",
              borderRadius: "25px",
              p: 3,
            }}
          >
            <FiltersTitle>Storage Type</FiltersTitle>
            <Grid container rowSpacing={3} columnSpacing={1}>
              {getOptions().map((option) => (
                <Grid item xs={3} key={option.title}>
                  <ListingOptionCard
                    option={option}
                    handleOptionClick={handleOptionClick}
                    clicked={filters.listing_type === option.title}
                    height={100}
                    width={135}
                  />
                </Grid>
              ))}
            </Grid>
            <FiltersTitle>Price ($)</FiltersTitle>
            <Box sx={{ width: 500, justifyContent: "center", pl: 6, pt: 8 }}>
              <CustomSlider
                size="large"
                value={filters.price}
                onChange={handleSlider}
                valueLabelDisplay="on"
                disableSwap
              />
            </Box>

            <FiltersTitle>Size (ft)</FiltersTitle>
            <Stack direction="row" pb={2} justifyContent="center" spacing={10}>
              <Box textAlign="center" width="200px">
                <SizeTitle>Less than 10x10</SizeTitle>
                <Radio {...controlProps(null, "10")} />
              </Box>
              <Box textAlign="center" width="200px">
                <SizeTitle>10x10 to 15x15</SizeTitle>
                <Radio {...controlProps("10", "15")} />
              </Box>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={10}
              pb="80px"
            >
              <Box textAlign="center" width="200px">
                <SizeTitle>Greater than 15x15</SizeTitle>
                <Radio {...controlProps("15", null)} />
              </Box>
              <Box textAlign="center" width="200px">
                <SizeTitle>All storage sizes</SizeTitle>
                <Radio {...controlProps(null, null)} />
              </Box>
            </Stack>

            <Box
              height="80px"
              width="600px"
              position="fixed"
              sx={{ bottom: "17px", backgroundColor: "white" }}
            >
              <Divider
                width="100%"
                color="grey"
                sx={{ marginBottom: "20px" }}
              />
              <FiltersResultButton
                c={Colors.black}
                bc={"white"}
                f="left"
                onClick={handleReset}
              >
                Reset
              </FiltersResultButton>
              <FiltersResultButton
                c={"white"}
                bc={Colors.secondary}
                f="right"
                onClick={handleShow}
              >
                Show listings
              </FiltersResultButton>
            </Box>
          </Stack>
        </Popover>
      </Stack>
    </BlueBackground>
  );
};

export default Filters;
